import { createContext, useState, useContext, useEffect } from 'react';
import { getDataFromLS } from 'utils/helpers';

const MaterialsListViewContext = createContext();

export const useMaterialsViewContext = () => {
  return useContext(MaterialsListViewContext);
};

export const MaterialsListViewContextProvider = (props) => {
  const view = getDataFromLS('materialsListView');
  const [materialsView, setMaterialsView] = useState(view || 'row');

  const toggleView = () => {
    materialsView === 'column' ? setMaterialsView('row') : setMaterialsView('column');
  };

  useEffect(() => {
    localStorage.setItem('materialsListView', JSON.stringify(materialsView));
  }, [materialsView]);

  return (
    <MaterialsListViewContext.Provider value={{ materialsView, toggleView }}>
      {props.children}
    </MaterialsListViewContext.Provider>
  );
};
