import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import ProgramCard from 'views/pages/programs/components/programs/ProgramCard';

const GroupsList = ({ groups, handleCardClick }) => {
  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <Grid container spacing={2}>
        {groups?.map((group) => {
          return (
            <ProgramCard
              key={group?.id}
              group={group}
              program={group.program}
              onClick={() => handleCardClick(group.id, group.program.id)}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default memo(GroupsList);
