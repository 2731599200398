import { memo, useEffect, useState } from 'react';
import { Stack, useMediaQuery } from '@mui/material';

import { useParams } from 'react-router-dom';
import store from 'store';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  MATERIALS_PRIORITY,
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { makeShortString } from 'utils/helpers';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import MaterialView from './components/materials/MaterialView';

const MaterialPage = () => {
  const { id, groupId, priorityName, lessonId, moduleId, materialId } = useParams();
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const { t } = useTranslation();

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]);
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const selectedPriority = MATERIALS_PRIORITY.find((priority) => priority.urlParam === priorityName);

  const [materialsList, setFilteredMaterialsList] = useState([]);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const selectedMaterial = materialsList?.find((material) => material.id === +materialId);

  useEffect(() => {
    const selectedLessonMaterials = selectedLesson?.materials.filter(
      (material) => material.priority.name === selectedPriority?.name
    );
    setFilteredMaterialsList(selectedLessonMaterials);
  }, [selectedLesson, setFilteredMaterialsList, selectedPriority]);

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](groupId ? groupId : id));
  }, [program, groupId, id, currentRole]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.material.name')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={makeShortString(selectedMaterial?.name, 30)}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          width="100%"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            padding={isDesktop ? 2 : 1}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar
                program={program}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                selectedPriority={selectedPriority}
                selectedMaterial={selectedMaterial}
                allMaterials={materialsList}
                setFilteredMaterialsList={setFilteredMaterialsList}
                hideProgramViewTopBar={isDesktop ? false : true}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <MaterialView materialsList={materialsList} selectedMaterial={selectedMaterial} />
            </ErrorBoundary>
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(MaterialPage);
