import {
  Avatar,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Fragment, memo, useEffect } from 'react';
import { ReactComponent as MobileMenuOpenIcon } from '../../../theme/icons/icon-menu-mobile-open.svg';
import { ReactComponent as MobileMenuClosedIcon } from '../../../theme/icons/icon-menu-mobile-close.svg';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';
import { userSelectors, userThunks } from 'store/ducks/user';
import RoleSelector from './RoleSelector';
import { SIDEBAR_MENU } from 'utils/constants/sidebar';
import { hasPermission } from 'utils/helpers';
import { Link, useLocation } from 'react-router-dom';
import store from 'store';
import { useTranslation } from 'react-i18next';

const MobileMenu = ({ open, setOpen, setSelected }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const theme = useTheme();

  const location = useLocation();
  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const { t } = useTranslation();

  const handleMenuItemClick = (title) => {
    setSelected(title);
    setOpen(false);
  };

  useEffect(() => {
    const selectedTitleArr = path[1].split('');
    const selectedTitle = selectedTitleArr?.at(0)?.toUpperCase() + selectedTitleArr?.slice(1).join('');
    setSelected(selectedTitle);
  }, [path, setSelected]);

  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>{open ? <MobileMenuOpenIcon /> : <MobileMenuClosedIcon />}</IconButton>
      <Drawer
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ top: '56px' }}
        PaperProps={{
          style: {
            top: '56px',
            maxWidth: '100%',
            borderRadius: 0,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            background: theme.palette.extra.gradient,
            boxShadow: 'none',
          },
        }}
        slotProps={{
          backdrop: {
            style: { top: 56 },
          },
        }}
      >
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ width: 24, height: 24 }} alt={user.name} src={user.avatar} />
              <Typography variant="body2" color="extra.white" fontWeight={700}>
                {user.lastName} {user.name}
              </Typography>
            </Stack>
            {user.roles.length === 1 && (
              <Typography variant="body2" color="extra.white">
                {currentRole}
              </Typography>
            )}
            {user.roles.length > 1 && <RoleSelector user={user} color="extra.white" />}
          </Stack>
          <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />
          <Stack>
            {SIDEBAR_MENU.map((menuItem) => {
              return (
                <Fragment key={menuItem.key}>
                  {hasPermission(menuItem.permission) && (
                    <ListItem disableGutters sx={{ py: 0 }}>
                      <ListItemButton
                        sx={{ color: 'extra.white' }}
                        component={Link}
                        to={menuItem.to}
                        onClick={() => handleMenuItemClick(menuItem.key)}
                        disableGutters
                      >
                        <ListItemIcon sx={{ color: 'extra.white' }}>{menuItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={t(`mainMenu.${menuItem.key}`)}
                          sx={{
                            color: menuItem.to === rootPath ? 'primary.main' : 'extra.white',
                            backgroundColor: menuItem.to === rootPath ? 'primary.lighter' : 'transparent',
                            borderRadius: 1,
                            pl: 2,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                </Fragment>
              );
            })}
          </Stack>
          <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />
          <Stack>
            <ListItem disableGutters sx={{ py: 0 }}>
              <ListItemButton
                sx={{ color: 'extra.white' }}
                onClick={() => store.dispatch(userThunks.logout())}
                disableGutters
              >
                <ListItemIcon sx={{ color: 'extra.white' }}>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('base.buttons.logout')}
                  sx={{
                    borderRadius: 1,
                    pl: 2,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default memo(MobileMenu);
