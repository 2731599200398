import { createContext, useState, useContext } from 'react';

const ProgramMobileMenuOpenContext = createContext();

export const useProgramMobileMenuOpenContext = () => {
  return useContext(ProgramMobileMenuOpenContext);
};

export const ProgramMobileMenuOpenContextProvider = (props) => {
  const [isProgramMenuOpen, setIsProgramMenuOpen] = useState(false);

  const closeProgramMobileMenu = () => {
    setIsProgramMenuOpen(false);
  };

  const toggleProgramMobileMenu = () => {
    setIsProgramMenuOpen((prev) => !prev);
  };
 
  return (
    <ProgramMobileMenuOpenContext.Provider
      value={{ isProgramMenuOpen, closeProgramMobileMenu, toggleProgramMobileMenu }}
    >
      {props.children}
    </ProgramMobileMenuOpenContext.Provider>
  );
};
