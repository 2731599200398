import { studentSelectors, studentThunks } from 'store/ducks/student';
import { mentorSelectors, mentorThunks } from 'store/ducks/mentor';
import { trainerSelectors, trainerThunks } from 'store/ducks/trainer';
import { adminSelectors, adminThunks } from 'store/ducks/admin';

import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import { ReactComponent as PresentationIcon } from '../../theme/icons/presentation-icon.svg';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { ReactComponent as HomeWorkIcon } from '../../theme/icons/hw_icon.svg';
import { ReactComponent as StepProjectIcon } from '../../theme/icons/step_project_icon.svg';
import { ReactComponent as FinalProjectIcon } from '../../theme/icons/final-project-icon.svg';

/** ---  Constants for defining selectors and thunks depending on current user Role.
 *       For getting data for ALL USER'S PROGRAMS  --- */
export const PROGRAMS_SELECTORS_BY_ROLES = {
  Student: studentSelectors.getPrograms(),
  Mentor: mentorSelectors.getGroups(),
  Trainer: trainerSelectors.getGroups(),
  Admin: adminSelectors.getPrograms(),
};
export const PROGRAMS_IS_LOADING_BY_ROLES = {
  Student: studentSelectors.programsLoading(),
  Mentor: mentorSelectors.groupsLoading(),
  Trainer: trainerSelectors.groupsLoading(),
  Admin: adminSelectors.programsLoading(),
};

export const PROGRAMS_FETCH_BY_ROLES = {
  Student: studentThunks.fetchPrograms(),
  Mentor: mentorThunks.fetchGroups(),
  Trainer: trainerThunks.fetchGroups(),
  Admin: adminThunks.fetchPrograms(),
};

/** ---  Constants for defining selectors and thunks depending on current user Role.
 *       For getting data for a ONE SPECIFIC program  --- */
export const PROGRAM_SELECTORS_BY_ROLES = {
  Student: studentSelectors.getProgram(),
  Mentor: mentorSelectors.getGroupProgram(),
  Trainer: trainerSelectors.getGroupProgram(),
  Admin: adminSelectors.getProgram(),
};
export const PROGRAM_IS_LOADING_BY_ROLES = {
  Student: studentSelectors.programLoading(),
  Mentor: mentorSelectors.groupProgramLoading(),
  Trainer: trainerSelectors.groupProgramLoading(),
  Admin: adminSelectors.programLoading(),
};

export const PROGRAM_HAS_ERROR_BY_ROLES = {
  Student: studentSelectors.getProgramError(),
  Mentor: mentorSelectors.getGroupProgramError(),
  Trainer: trainerSelectors.getGroupProgramError(),
  Admin: adminSelectors.getProgramError(),
};

export const PROGRAM_FETCH_BY_ROLES = {
  Student: (id) => studentThunks.fetchProgram(id),
  Mentor: (id) => mentorThunks.fetchGroupProgram(id),
  Trainer: (id) => trainerThunks.fetchGroupProgram(id),
  Admin: (id) => adminThunks.fetchProgram(id),
};

/** ---  Constant for defining types of materials which represented
 * in Menu of adding material from a certain program --- */
export const ADD_MATERIAL_MENU_OPTIONS = (theme) => [
  {
    title: 'Video',
    name: 'YOUTUBE',
    icon: <PlayCircleOutlinedIcon sx={{ mr: 2 }} />,
  },
  {
    title: 'Presentation',
    name: 'PRESENTATION',
    icon: <DvrOutlinedIcon sx={{ mr: 2 }} />,
  },
  {
    title: 'Link',
    name: 'LINK',
    icon: <LinkOutlinedIcon sx={{ mr: 2 }} />,
  },
  {
    title: 'Article',
    name: 'ARTICLE',
    icon: <ArticleOutlinedIcon sx={{ mr: 2 }} />,
  },
  {
    title: 'Assignment',
    name: 'ASSIGNMENT',
    icon: <HomeWorkIcon stroke={theme.palette.primary.main} fill="none" style={{ marginRight: theme.spacing(2) }} />,
  },
];
/** ---  Constant for defining ICONS for each type of materials  --- */
export const MATERIAL_TYPE_ICONS = (theme) => ({
  PRESENTATION: (size) => <PresentationIcon width={size} height={size} style={{ alignSelf: 'center' }} />,
  YOUTUBE: (size) => (
    <PlayCircleOutlinedIcon
      sx={{
        width: size,
        height: size,
        alignSelf: 'center',
        color: theme.palette.extra.grey,
      }}
    />
  ),
  LINK: (size) => (
    <LinkOutlinedIcon
      sx={{
        width: size,
        height: size,
        rotate: '-45deg',
        alignSelf: 'center',
        color: theme.palette.extra.grey,
      }}
    />
  ),
  ARTICLE: (size) => (
    <ArticleOutlinedIcon
      sx={{
        width: size,
        height: size,
        alignSelf: 'center',
        color: theme.palette.extra.grey,
      }}
    />
  ),
});

export const MATERIALS_PRIORITY = [
  { urlParam: 'main', label: 'Main materials', name: 'MAIN' },
  { urlParam: 'additional', label: 'Additional materials', name: 'ADDITIONAL' },
  { urlParam: 'assignment', label: 'Assignments' },
];

export const MATERIAL_STATUSES = {
  notStarted: 'NOT_STARTED',
  inProgress: 'IN_PROGRESS',
  passed: 'DONE',
};

/** ---  Constant for defining ICONS for each type of Assignment  --- */
export const ASSIGNMENT_TYPE_ICONS = (theme) => ({
  HOMEWORK: (size) => (
    <HomeWorkIcon
      stroke={theme.palette.extra.grey}
      fill="none"
      style={{ width: size, height: size, alignSelf: 'center' }}
    />
  ),
  STEP_PROJECT: (size) => (
    <StepProjectIcon
      stroke={theme.palette.extra.grey}
      fill="none"
      style={{ width: size, height: size, alignSelf: 'center' }}
    />
  ),
  FINAL_PROJECT: (size) => (
    <FinalProjectIcon
      stroke={theme.palette.extra.grey}
      fill="none"
      style={{ width: size, height: size, alignSelf: 'center' }}
    />
  ),
});
