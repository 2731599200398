import { Button, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageViewPaperVioletLight } from 'views/common/styledComponents';

/** --- INFORMATIONAL VIEW ABOUT UNPAID INVOICE
 *   uses for student role in cases when the modules returns empty. It happens when student has unpaid invoice.  ----  */

const UnpaidInvoice = () => {
  const { t } = useTranslation();

  return (
    <PageViewPaperVioletLight>
      <Stack direction="column" alignItems="center" justifyContent="center" height="100%" gap={2}>
        <Typography variant="h3">{t('messages.warning.unpaidInvoice.title')}</Typography>
        <Typography variant="h5">{t('messages.warning.unpaidInvoice.subtitle')}</Typography>
        <Typography variant="body1">{t('messages.warning.unpaidInvoice.message')}</Typography>
        <Button variant="contained" component={Link} to="/settings/">
          {t('base.buttons.goToCabinet')}
        </Button>
      </Stack>
    </PageViewPaperVioletLight>
  );
};

export default memo(UnpaidInvoice);
