import { Box, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import { memo } from 'react';

import { MATERIALS_PRIORITY } from 'utils/constants/program';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { useTranslation } from 'react-i18next';

const LessonStructure = ({ selectedLesson }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isAdmin = currentRole === 'Admin';

  const { id, groupId, moduleId, lessonId, priorityName } = useParams();

  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { closeProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const selectedPriority = MATERIALS_PRIORITY.find((priority) => priority.urlParam === priorityName);
  const isAssignmentsPage = location.pathname.includes('assignments');
  const isSessionRecord = location.pathname.includes('record');

  const hasAdditionalMaterials = selectedLesson?.materials?.some(
    (material) => material.priority?.name === 'ADDITIONAL'
  );
  const hasSessionRecords = selectedLesson?.sessions?.some((session) => session.record);

  const handlePriorityClick = (item) => {
    const clickedPriority = MATERIALS_PRIORITY.find((priority) => priority.label === item);
    const isAssignment = item === 'Assignment';
    const isRecord = item === 'Record';

    if (isAssignment) {
      !isDesktop && closeProgramMobileMenu();
      return groupId
        ? navigate(`/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments`)
        : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments`);
    }

    if (isRecord) {
      !isDesktop && closeProgramMobileMenu();
      const noSessions = selectedLesson?.sessions?.length === 0;
      const hasOneSession = selectedLesson?.sessions?.length === 1;
      const hasSomeSessions = selectedLesson?.sessions?.length > 1;
      const recordId = selectedLesson?.sessions?.[0].id;

      if (noSessions) {
        return;
      }

      if (hasOneSession) {
        return groupId
          ? navigate(`/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/records/${recordId}`)
          : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/records/${recordId}`);
      }
      if (hasSomeSessions) {
        return groupId
          ? navigate(`/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/records`)
          : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/records`);
      }
    }

    groupId
      ? navigate(
          `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${clickedPriority.urlParam}`
        )
      : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${clickedPriority.urlParam}`);

    closeProgramMobileMenu();
  };

  const listItemButtonStyle = (isSelected) => ({
    py: 0.5,
    backgroundColor: isSelected ? 'primary.lighter' : 'transparent',
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: isSelected ? 'primary.lighter' : 'transparent',
      fontWeight: 600,
    },
  });

  return (
    <Box mt={0.5} ml={2} mr={1}>
      {/** --- MAIN MATERIALS ----*/}
      <ListItemButton
        sx={listItemButtonStyle(selectedPriority?.label === 'Main materials')}
        onClick={() => handlePriorityClick('Main materials')}
      >
        <ListItemText
          primary={t('types.program.navigation.mainMaterials')}
          primaryTypographyProps={{
            fontSize: 14,
          }}
          sx={{ my: 0 }}
        />
      </ListItemButton>

      {/** --- ADDITIONAL MATERIALS ----*/}
      {(isAdmin || hasAdditionalMaterials) && (
        <ListItemButton
          onClick={() => handlePriorityClick('Additional materials')}
          sx={listItemButtonStyle(selectedPriority?.label === 'Additional materials')}
        >
          <ListItemText
            primary={t('types.program.navigation.additionalMaterials')}
            primaryTypographyProps={{
              fontSize: 14,
            }}
            sx={{ my: 0 }}
          />
        </ListItemButton>
      )}

      {/** --- ASSIGNMENTS ----*/}
      {selectedLesson?.assignments.length !== 0 && (
        <>
          <ListItemButton sx={listItemButtonStyle(isAssignmentsPage)} onClick={() => handlePriorityClick('Assignment')}>
            <ListItemText
              primary={t('types.program.navigation.assignments')}
              primaryTypographyProps={{
                fontSize: 14,
              }}
              sx={{ my: 0 }}
            />
          </ListItemButton>
        </>
      )}
      {/** --- SESSION RECORD ---  */}
      {hasSessionRecords && (
        <ListItemButton
          sx={listItemButtonStyle(isSessionRecord)}
          onClick={() => handlePriorityClick('Record')}
        >
          <ListItemText
            primary={t('types.program.navigation.sessionRecord')}
            primaryTypographyProps={{
              fontSize: 14,
            }}
            sx={{ my: 0 }}
          />
        </ListItemButton>
      )}
    </Box>
  );
};

export default memo(LessonStructure);
