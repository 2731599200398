import { Button, IconButton } from '@mui/material';
import { memo, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../../../theme/icons/deleteIcon.svg';
import useModal from 'hooks/useModal';
import store from 'store';
import { materialThunks } from 'store/ducks/material';
import { toast } from 'react-toastify';
import Modal from 'views/common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { adminActions } from 'store/ducks/admin';

const DeleteMaterial = ({ material, selectedLesson, selectedModule }) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { t } = useTranslation();

  const handleModalOpen = (e) => {
    e.stopPropagation();
    openModal();
  };

  const handleDeleteMaterial = (e) => {
    e.stopPropagation();
    const selectedModuleId = selectedModule?.id;
    const selectedLessonId = selectedLesson?.id;
    const materialId = material?.id;

    setDeleteLoading(true);
    store
      .dispatch(materialThunks.deleteMaterial(material.id))
      .then((res) => {
        !res.error && (toast.success(t('messages.success.toast.delete')), setDeleteLoading(false)), closeModal();
        res.meta.requestStatus === 'fulfilled' &&
          store.dispatch(adminActions.deleteMaterial({ selectedModuleId, selectedLessonId, materialId }));
      })
      .finally(setDeleteLoading(false));
  };

  return (
    <>
      <IconButton
        sx={{
          borderRadius: '10px',
          transition: 'all 0.5s',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        }}
        onClick={(event) => handleModalOpen(event)}
      >
        <DeleteIcon />
      </IconButton>
      <Modal
        title={`${t('types.material.delete')} ${material.name}`}
        questionIcon
        open={modalOpen}
        onClose={closeModal}
        actions={
          <>
            <Button
              sx={{ px: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              {t('base.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{ px: 8 }}
              type="button"
              onClick={(e) => handleDeleteMaterial(e)}
              disabled={deleteLoading}
              color="error"
            >
              {t('base.buttons.delete')}
            </Button>
          </>
        }
      />
    </>
  );
};

export default memo(DeleteMaterial);
