import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import store from 'store';
import { userSelectors } from 'store/ducks/user';
import { studentActions, studentThunks } from 'store/ducks/student';

import { Button, Stack, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import VideoViewCard from './VideoViewCard';
import LinkViewCard from './LinkViewCard';
import PresentationViewCard from './PresentationViewCard';
import ArticleViewCard from './ArticleViewCard';
import { MOBILE_VIEW } from 'utils/constants/common';
import { MATERIAL_STATUSES } from 'utils/constants/program';

const MaterialView = ({ materialsList, selectedMaterial }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { id, groupId, priorityName, lessonId, moduleId } = useParams();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const SELECTED_MATERIAL_TYPE = {
    YOUTUBE: <VideoViewCard link={selectedMaterial?.link} />,
    LINK: <LinkViewCard material={selectedMaterial} />,
    PRESENTATION: <PresentationViewCard material={selectedMaterial} />,
    ARTICLE: <ArticleViewCard material={selectedMaterial} />,
  };

  const materialName = selectedMaterial?.type.name;

  const isFirstMaterial = materialsList?.length > 0 && materialsList[0]?.id === selectedMaterial?.id;
  const isLastMaterial =
    materialsList?.length > 0 && materialsList[materialsList.length - 1]?.id === selectedMaterial?.id;

  const isCompleted = selectedMaterial?.status === MATERIAL_STATUSES.passed;
  const isStudent = currentRole === 'Student';

  const handlePrevious = () => {
    const currentIndex = materialsList.findIndex((material) => material.id === selectedMaterial.id);
    if (!isFirstMaterial) {
      const previousMaterial = materialsList[currentIndex - 1];
      groupId
        ? navigate(
            `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${previousMaterial.id}`
          )
        : navigate(
            `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${previousMaterial.id}`
          );
    }
  };

  const handleNext = () => {
    const currentIndex = materialsList.findIndex((material) => material.id === selectedMaterial.id);
    if (!isLastMaterial) {
      const nextMaterial = materialsList[currentIndex + 1];
      groupId
        ? navigate(
            `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${nextMaterial.id}`
          )
        : navigate(
            `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${nextMaterial.id}`
          );
    }
  };

  const handleComplete = () => {
    const selectedModuleId = +moduleId;
    const selectedLessonId = +lessonId;
    const materialId = selectedMaterial.id;
    const payload = { ...selectedMaterial, status: MATERIAL_STATUSES.passed };

    store.dispatch(studentThunks.updateMaterialStatus({ id: materialId, status: MATERIAL_STATUSES.passed })).then(
      (res) =>
        res.meta.requestStatus === 'fulfilled' &&
        store.dispatch(
          studentActions.replaceMaterial({
            selectedModuleId,
            selectedLessonId,
            materialId,
            value: payload,
          })
        ),
      setTimeout(() => {
        handleNext();
      }, 1000)
    );
  };
  return (
    <Stack alignContent="center" height="calc(100% - 33px)">
      <Stack sx={{ overflowY: 'auto' }} flex={3}>
        {SELECTED_MATERIAL_TYPE[materialName]}
      </Stack>

      {isStudent && isMobile && (
        <Button
          variant="contained"
          sx={{ width: '100%', fontSize: 14 }}
          onClick={handleComplete}
          disabled={isCompleted}
        >
          {isCompleted ? t('base.buttons.completed') : t('base.buttons.completeAndContinue')}
        </Button>
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Button
          variant="text"
          startIcon={<ChevronLeftIcon sx={{ width: 24, height: 24 }} />}
          sx={{ color: 'text.main', minWidth: 'fit-content', fontSize: isMobile ? 12 : 16 }}
          disabled={isFirstMaterial}
          onClick={handlePrevious}
        >
          {t('base.buttons.previous')}
        </Button>
        {isStudent && !isMobile && (
          <Button variant="contained" sx={{ width: 'fit-content' }} onClick={handleComplete} disabled={isCompleted}>
            {isCompleted ? t('base.buttons.completed') : t('base.buttons.completeAndContinue')}
          </Button>
        )}
        <Button
          variant="text"
          endIcon={<ChevronRightIcon sx={{ width: 24, height: 24 }} />}
          sx={{ color: 'text.main', minWidth: 'fit-content', fontSize: isMobile ? 12 : 16 }}
          disabled={isLastMaterial}
          onClick={handleNext}
        >
          {t('base.buttons.next')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default memo(MaterialView);
