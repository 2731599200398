import { memo } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors, userThunks } from 'store/ducks/user';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { Form, withFormik } from 'formik';
import store from 'store';
import FormikTextField from '../../form/FormikTextField';
import Loader from 'views/common/Loader';
import { logInFormSchema } from 'utils/schema';
import PasswordInput from 'views/form/PasswordInput';
import { GradientButton } from 'views/common/styledComponents';
import { useTranslation } from 'react-i18next';
import { LmsTextIcon } from 'theme/icons';
import defaultLogo from '../../../theme/icons/dan-logo-color.svg';

const Login = ({ values }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const userLoading = useSelector(userSelectors.userLoading());
  const theme = useTheme();

  const { t } = useTranslation();

  if (user) {
    return <Navigate to="/" replace />;
  }
  //TODO: google auth

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: '30%',
        left: '50%',
        transform: ' translate(-50%, -30%)',
      }}
    >
      {/* <Box sx={{ mb: 8, width: 250, height: 72 }}>
        <LogoDanItLms />
      </Box> */}
      <Stack sx={{ mb: 6 }} alignItems="center" gap={1} width={'100%'}>
        <img style={{ width: 300 }} src={theme.logo || defaultLogo} />
        <LmsTextIcon width={300} height={16} />
      </Stack>

      {/* --- LOGIN FORM --- */}

      <Stack
        alignItems="center"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.1)"
        sx={{
          width: '400px',
          backgroundColor: 'extra.white',
          borderRadius: 2,
        }}
      >
        <Typography variant="h1" color="text.dark" p={5}>
          {t('auth.welcome')}
        </Typography>

        <Box component={Form}>
          <Stack direction="column" gap={3}>
            <FormikTextField
              variant="outlined"
              hiddenLabel
              name="email"
              placeholder={t('base.placeholders.email')}
              autoComplete="email"
            />
            <PasswordInput name="password" placeholder={t('base.placeholders.password')} />
          </Stack>
          <Stack justifyContent="center" alignItems="center" flexDirection="column" sx={{ p: 0, mt: 3 }} spacing={2}>
            {userLoading && <Loader fullscreen={false} />}
            <GradientButton
              variant="contained"
              type="submit"
              disabled={userLoading || !values.email || !values.password}
            >
              {t('base.buttons.login')}
            </GradientButton>
            {/* TODO:  hidden until feature is implemented
                <Button
              variant="outlined"
              disabled={userLoading}
              sx={{
                width: '345px',
                color: 'text.main',
              }}
            >
              Log in via Google
            </Button> */}
            <Typography p="32px 0" color="text.dark">
              {t('auth.forgotThe')}{' '}
              <Link
                component={RouterLink}
                to="/forgot"
                underline="hover"
                sx={{
                  color: 'primary.main',
                  fontFamily: 'inherit',
                }}
              >
                {t('auth.passwordLink').toLowerCase()}
              </Link>
              ?
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),
    validationSchema: logInFormSchema,
    handleSubmit: (data, helpers) => {
      helpers.setSubmitting(true);
      store.dispatch(userThunks.login(data));
      helpers.setSubmitting(false);
    },
  })(Login)
);
