import { Form, withFormik } from 'formik';
import { memo } from 'react';

import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import { FormColumnTitle, FormColumnWrapper, FormWrapper } from 'views/common/styledComponents';
import { TabPanel } from 'utils/tabsFunc';
import FormikQuillEditor from 'views/form/FormikQuillEditor';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { validationAssignmentForm } from 'utils/schema';
import store from 'store';
import { assignmentThunks } from 'store/ducks/assignment';
import { toast } from 'react-toastify';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';
import { adminActions } from 'store/ducks/admin';
// import { turndownService } from 'utils/helpers';

const AssignmentForm = ({
  tabsValue,
  assignment,
  selectedModule,
  selectedLesson,
  programs,
  program,
  lessons,
  values,
}) => {
  const modules = program?.modules?.filter((obj) => Object.prototype.hasOwnProperty.call(obj, 'id')) || [];
  const enums = useSelector(dictionarySelectors.getEnums());

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();

  return (
    <Form
      id="assignmentForm"
      style={{ height: '100%', overflowY: 'auto', display: tabsValue === 2 ? 'none' : 'block' }}
    >
      <TabPanel value={tabsValue} index={0} sx={{ height: isMobile ? '80%' : '90%' }}>
        <FormikQuillEditor name="description" placeholder={t('base.placeholders.enterContent')} />
      </TabPanel>
      <TabPanel value={tabsValue} index={1}>
        <FormWrapper>
          <FormColumnWrapper sx={{ width: '50%' }}>
            <FormColumnTitle>{t('types.assignment.form.data')}</FormColumnTitle>
            <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums?.activeStatuses} />
            <FormikTextField
              name="number"
              label={t('base.labels.number')}
              placeholder={t('base.placeholders.enterNumber')}
            />
            <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.enterName')} />
            <FormikAutocomplete name="type" label={t('base.labels.type')} options={enums?.assignmentTypes} />
            <FormikAutocomplete
              name="requirement"
              label={t('base.labels.requirement')}
              options={enums?.assignmentRequirements}
            />
          </FormColumnWrapper>
          <FormColumnWrapper sx={{ width: '50%' }}>
            <FormColumnTitle>{t('types.assignment.form.about')}</FormColumnTitle>
            <FormikAutocomplete
              name="program"
              label={t('base.labels.program')}
              options={programs}
              defaultValue={program}
              placeholder={t('base.placeholders.typeForSearch')}
            />
            <FormikAutocomplete
              name="module"
              label={t('base.labels.module')}
              options={modules}
              defaultValue={selectedModule}
            />
            <FormikAutocomplete
              name="lesson"
              label={t('base.labels.lesson')}
              options={lessons}
              defaultValue={selectedLesson}
            />
            <FormikTextField
              name="deadlineDays"
              label={t('base.labels.deadlineDays')}
              placeholder={t('base.placeholders.enterDaysDeadline')}
            />
            <FormikArrayTextField
              name="documents"
              values={values.documents}
              label={t('base.labels.docLink')}
              placeholder={t('base.placeholders.pasteLink')}
              copyInputAdornment
            />
          </FormColumnWrapper>
        </FormWrapper>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button type="submit" variant="contained" sx={{ minWidth: '360px', textAlign: 'center', mt: 4 }}>
            {assignment ? t('base.buttons.save') : t('base.buttons.add')}
          </Button>
        </Box>
      </TabPanel>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ assignment = {} }) => ({
      ...assignment,
      id: assignment?.id,
      number: assignment?.number || '',
      name: assignment?.name || '',
      program: assignment?.program || null,
      module: assignment?.module || null,
      lesson: assignment?.lesson || null,
      status: assignment?.status || null,
      description: assignment?.description || '',
      documents: assignment?.documents || [],
      type: assignment?.type || null,
      requirement: assignment?.requirement || null,
      deadlineDays: assignment?.deadlineDays || '',
    }),
    validationSchema: validationAssignmentForm,
    handleSubmit: (values, { props, setSubmitting }) => {
      const selectedModuleId = props.selectedModule?.id;
      const selectedLessonId = props.selectedLesson?.id;
      const assignmentId = props.assignment?.id;

      // TODO: markdown 

      // const markdown = turndownService.turndown(values.description);
      // const valuesWithMarkDown = { ...values, description: markdown };

      props.assignment
        ? store
            .dispatch(assignmentThunks.updateAssignment(values))
            .then((res) => {
              !res.error &&
                (toast.success(props.t('messages.success.toast.assignmentUpdate')),
                setSubmitting(false),
                res.meta.requestStatus === 'fulfilled' &&
                  store.dispatch(
                    adminActions.replaceAssignment({
                      selectedModuleId,
                      selectedLessonId,
                      assignmentId,
                      value: res.payload,
                    })
                  ));
            })
            .finally(setSubmitting(false))
        : store
            .dispatch(assignmentThunks.createAssignment(values))
            .then((res) => {
              !res.error && (setSubmitting(false), toast.success(props.t('messages.success.toast.assignmentAdd'))),
                res.meta.requestStatus === 'fulfilled' &&
                  store.dispatch(
                    adminActions.addAssignment({ selectedModuleId, selectedLessonId, values: res.payload })
                  );
            })
            .finally(setSubmitting(false));
    },
    enableReinitialize: true,
  })(AssignmentForm)
);
