import { useState, useEffect } from 'react';
import store from 'store';
import { studentThunks } from 'store/ducks/student';

const ActivityTracker = () => {
  const [hasActivity, setHasActivity] = useState(false);

  const handleActivity = () => {
    setHasActivity(true);
  };

  useEffect(() => {
    const activityTimer = setTimeout(() => {
      // If there was any activity during the last minute, send a request to the server
      if (hasActivity) {
        store.dispatch(studentThunks.updateActivity()).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            // Reset the activity flag for the next minute
            setHasActivity(false);
          }
        });
      }
    }, 60000);

    // Attach event listeners to track mouse and keyboard events
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Cleanup the event listeners and timers on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(activityTimer);
    };
  }, [hasActivity]);
};

export default ActivityTracker;
