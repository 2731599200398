import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import store from 'store';
import Loader from 'views/common/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { studentSelectors, studentThunks } from 'store/ducks/student';
import AssignmentSubmission from './components/assignments/AssignmentSubmission';
import CommentsHistory from '../submissions/components/CommentsHistory';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { makeShortString } from 'utils/helpers';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const AssignmentPage = () => {
  const { id, lessonId, moduleId, assignmentId } = useParams();
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const { t } = useTranslation();

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]) && !program;
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const assignment = selectedLesson?.assignments.find((assignment) => {
    return assignment.id === +assignmentId;
  });

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const isStudent = currentRole === 'Student';
  const studentGrade = useSelector(studentSelectors.getGrade());

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));
  }, [currentRole, id, program]);

  useEffect(() => {
    isStudent && store.dispatch(studentThunks.fetchGrade(assignmentId));
  }, [isStudent, assignmentId]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.assignment.name')} />
        </ErrorBoundary>
      )}

      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={makeShortString(assignment?.name, 30)}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          width="100%"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            justifyContent="space-between"
            padding={isDesktop ? 2 : 1}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar
                program={program}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                selectedMaterial={assignment}
                isAssignment
                studentGrade={studentGrade}
                hideProgramViewTopBar={isDesktop ? false : true}
              />
            </ErrorBoundary>

            <Stack alignSelf="flex-start" height={'100%'} sx={{ overflowY: 'auto' }}>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ReactQuill theme="snow" value={assignment?.description} modules={{ toolbar: false }} readOnly={true} />
              </ErrorBoundary>
            </Stack>
            {isStudent && studentGrade?.comments?.length > 0 && (
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <CommentsHistory comments={studentGrade?.comments} />
              </ErrorBoundary>
            )}

            {isStudent && (
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <AssignmentSubmission assignment={assignment} />
              </ErrorBoundary>
            )}
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(AssignmentPage);
