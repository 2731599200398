import { memo } from 'react';
import { LinearProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import { ElementTitleBlackColor } from 'views/common/styledComponents';
import { useProgramsViewContext } from 'services/context/programsListViewContext';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ location, progress, height, marginY, border, borderColor, backgroundColor, activeColor }) => {
  const { programsView } = useProgramsViewContext();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  return (
    <Stack
      flex={0.1}
      sx={{
        ...(location === 'programs' && programsView === 'row' ? { py: 0 } : { pt: 1 }),
      }}
    >
      {location === 'dashboard' && (
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" mt={isDesktop ? 3 : 1}>
          <ElementTitleBlackColor>{t('progressBar.total')}</ElementTitleBlackColor>
          <ElementTitleBlackColor>{progress}%</ElementTitleBlackColor>
        </Stack>
      )}
      <LinearProgress
        sx={{
          height: height,
          my: marginY,
          borderRadius: 1,
          border: border,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: activeColor,
          },
        }}
        variant="determinate"
        value={progress}
      />
      {location === 'programs' && (
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="capture" color="text.main">
            0%
          </Typography>
          <Typography variant="capture" color="text.main">
            {t('progressBar.programProgress')}
          </Typography>
          <Typography variant="capture" color="text.main">
            100%
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(ProgressBar);
