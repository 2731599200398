import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import store from 'store';
import { articleSelectors, articleThunks } from 'store/ducks/article';

import Loader from 'views/common/Loader';
import HeaderList from 'views/common/HeaderList';
import ArticlesList from './components/ArticlesList';
import NoArticles from './components/NoArticles';
import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import { useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const ArticlesPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchPhrase = searchParams.get('q') || '';

  const articles = useSelector(articleSelectors.getArticles());
  const isLoading = useSelector(articleSelectors.articlesLoading());

  const query = `?q=${searchPhrase}`;

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  useEffect(() => {
    query && store.dispatch(articleThunks.fetchArticles(query));
  }, [query]);

  const handleOpenArticle = (event, articleId) => {
    event.stopPropagation();
    navigate(`${articleId}`);
  };

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.article.name')}
            to={`create-article`}
            buttonTitle={t('base.buttons.create')}
            setSearchParams={setSearchParams}
            isDisplaySearchBar
            isServerSearch
          />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            to={`create-article`}
            buttonTitle={t('base.buttons.create')}
            setSearchParams={setSearchParams}
            isDisplaySearchBar
            isServerSearch
          />
        </ErrorBoundary>
      )}
      <PageViewPaperVioletLight>
        {isLoading && <Loader />}
        {!isLoading && articles.length === 0 && <NoArticles searchPhrase={searchPhrase} />}
        {!isLoading && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <ArticlesList articles={articles} handleCardClick={handleOpenArticle} />
          </ErrorBoundary>
        )}
      </PageViewPaperVioletLight>
    </PageWrapper>
  );
};

export default ArticlesPage;
