import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid } from '@mui/x-data-grid';
import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';

import filters from 'filters';

import { DESKTOP_VIEW, STATUSES_COLOR } from 'utils/constants/common';

const InvoicesList = ({ list, isLoading }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const columns = [
    {
      field: 'number',
      headerName: '№',
      filterable: false,
      minWidth: 80,
      flex: 0.15,
    },
    {
      field: 'contract',
      headerName: t('types.settings.profile.invoice.contractNumber'),
      filterable: false,
      minWidth: 120,
      flex: 0.2,
      renderCell: ({ row: { contract } }) => {
        return <Typography variant="body2">{contract.number}</Typography>;
      },

      valueGetter: ({ row: { contract } }) => contract.number,
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.2,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return (
          <Typography variant="body2" color={statusColor}>
            {status.label}
          </Typography>
        );
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'dueDate',
      headerName: t('types.settings.profile.invoice.dueDate'),
      filterable: false,
      minWidth: 120,
      flex: 0.25,
      renderCell: ({ row: { dueDate, status } }) => {
        const shortDate = filters.date(dueDate);
        const today = new Date();
        const isOverdue = today.getTime() > new Date(dueDate).getTime();
        return (
          <Typography variant="body2" color={isOverdue && status.name === 'ACTIVE' ? 'error' : 'text.dark'}>
            {shortDate}
          </Typography>
        );
      },
    },
    {
      field: 'amountDue',
      headerName: t('types.settings.profile.invoice.amountDue'),
      filterable: false,
      minWidth: 130,
      flex: 0.2,
    },
    {
      field: 'paymentDate',
      headerName: t('types.settings.profile.invoice.paymentDate'),
      filterable: false,
      minWidth: 140,
      flex: 0.25,
      renderCell: ({ row: { paymentDate } }) => {
        const shortDate = filters.date(paymentDate);
        return <Typography variant="body2">{shortDate}</Typography>;
      },
    },
    {
      field: 'amountPaid',
      headerName: t('types.settings.profile.invoice.amountPaid'),
      filterable: false,
      minWidth: 140,
      flex: 0.2,
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        py: 1,
        px: isDesktop ? 2 : 1,
        backgroundColor: 'extra.white',
        borderRadius: 2,
        height: '100%',
      }}
    >
      <DataGrid
        rows={list}
        columns={columns}
        loading={isLoading}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        hideFooter
        rowHeight={48}
        columnHeaderHeight={48}
        disableColumnMenu
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      />
    </Box>
  );
};

export default memo(InvoicesList);
