import { ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { memo, useState } from 'react';

import defaultLogoShort from '../../../theme/icons/logo-short.svg';
import { ReactComponent as JoinLesson } from '../../../theme/icons/join-icon-violet.svg';
import MobileMenu from './MobileMenu';
import { useSelector } from 'react-redux';
import { studentSelectors } from 'store/ducks/student';
import { getCurrentDate } from 'utils/helpers';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MobileTopBar = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const nearestLessons = useSelector(studentSelectors.getCalendarNearest());
  const today = getCurrentDate();
  const todaysLesson = nearestLessons.find((lesson) => DateTime.fromISO(lesson.date).hasSame(today, 'day'));
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Stack
      p={2}
      sx={{
        background: theme.palette.extra.gradient,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <img src={theme.logoShort || defaultLogoShort} />
        <Typography variant="body1" color="extra.white">
          {open ? t('base.dictionary.menu') : selected}
        </Typography>
        <MobileMenu open={open} setOpen={setOpen} setSelected={setSelected} />
      </Stack>
      {todaysLesson && (
        <ListItemButton
          sx={{
            backgroundColor: 'primary.lighter',
            color: 'primary.main',
            justifyContent: 'center',
            gap: 2,
            py: 0,
          }}
          component={Link}
          to={todaysLesson?.link}
          target="_blank"
          disableGutters
        >
          <ListItemText primary={t('base.joinLesson')} sx={{ flex: '0 1 auto' }} />
          <ListItemIcon sx={{ minWidth: 0 }}>{<JoinLesson />}</ListItemIcon>
        </ListItemButton>
      )}
    </Stack>
  );
};

export default memo(MobileTopBar);
