import { memo } from 'react';

import { ELementWrapper } from 'views/common/styledComponents';
import ProgramItemHeader from './ProgramItemHeader';
import ModuleNavItem from './ModuleNavItem';

const ProgramNavigation = ({ program }) => {
  return (
    <ELementWrapper
      sx={{
        minHeight: 64,
        overflowY: 'auto',
        flex: 1,
        height: '100%',
      }}
    >
      <ProgramItemHeader program={program} />

      {program?.modules?.map((module) => {
        return <ModuleNavItem key={module.number} program={program} module={module} />;
      })}
    </ELementWrapper>
  );
};

export default memo(ProgramNavigation);
