import { memo } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ArticleViewCard = ({ material }) => {
  return <ReactQuill theme="snow" value={material?.article?.text} modules={{ toolbar: false }} readOnly={true} />;
};

export default memo(ArticleViewCard);
