import { memo, useEffect } from 'react';
import { userSelectors } from 'store/ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import { dictionaryThunks } from 'store/ducks/dictionary';
import { getDataFromLS, transformErpRoleToLmsRole } from 'utils/helpers';
import { ERP_ROLES_TO_LMS_ROLES } from 'utils/constants/common';
import { setCurrentRole } from 'store/ducks/user/slice';
import { useTranslation } from 'react-i18next';

const AppDataContainer = ({ children }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const currentLang = i18n.language;
  const userLanguage = user?.language.name.toLowerCase();

  useEffect(() => {
    if (!userLanguage && !currentLang) {
      i18n.changeLanguage('en');
      return;
    }

    if (currentLang !== userLanguage) {
      i18n.changeLanguage(userLanguage);
      return;
    }
  }, [userLanguage, currentLang, i18n]);

  useEffect(() => {
    if (user?.id) {
      const userLmsRoles = [
        ...new Set((user?.roles || []).map((role) => transformErpRoleToLmsRole(ERP_ROLES_TO_LMS_ROLES, role.label))),
      ];
      const savedRole = getDataFromLS('role');

      let currentRole;

      if (savedRole && userLmsRoles?.includes(savedRole)) {
        currentRole = savedRole;
      } else {
        currentRole = userLmsRoles?.includes('Admin') ? 'Admin' : userLmsRoles?.[0];
      }
      dispatch(setCurrentRole(currentRole));
      dispatch(dictionaryThunks.fetchLanguages());

      if (currentRole === 'Admin') {
        dispatch(dictionaryThunks.fetchEnums());
      }
    }
  }, [dispatch, user?.id, user?.roles]);

  return children;
};

export default memo(AppDataContainer);
