import { Box, Button, Stack, Typography, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DESKTOP_VIEW_HEIGHT, MOBILE_HEIGHT, TABLET_HEIGHT } from 'utils/constants/common';

export const GradientButton = styled(Button)(({ theme }) => ({
  background: theme.palette.extra.gradient,
  width: '345px',
  fontWeight: 600,
  '&.Mui-disabled': {
    opacity: 0.3,
    pointerEvents: 'auto',
    color: theme.palette.extra.white,
  },
}));

export const ElementTitlePurpleColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontSize: 18,
  fontWeight: 700,
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { fontSize: 14 },
}));

export const ElementTitleBlackColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: 18,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: { fontSize: 14 },
}));

export const ELementWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.extra.white,
  borderRadius: theme.spacing(2),
}));

export const PageViewPaperVioletLight = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(2),
  overflowY: 'hidden',
  backgroundColor: theme.palette.primary.lightest,
  borderRadius: theme.spacing(2),
  boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10)',
  [theme.breakpoints.down('sm')]: {
    borderTopLeftRadius: theme.spacing(0),
    borderTopRightRadius: theme.spacing(0),
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.lightest,
  },
}));

export const FormWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

/** --- Form's Column Wrapper (applied to form's columns) */

export const FormColumnWrapper = styled(Stack)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.extra.white,
  borderRadius: 8,
  border: '1px solid',
  borderColor: theme.palette.primary.lighter,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(2),
}));

/** --- Form's Column's Title */

export const FormColumnTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.main,
  lineHeight: 1,
  fontSize: 18,
  fontWeight: 700,
}));

/** --- GradeBook table cells --- */
export const StickyTableCell = styled(TableCell)(({ theme, index, isLeft, isRight }) => ({
  [`&.${tableCellClasses.head}`]: {
    minWidth: 150,
    position: 'sticky',
    left: isLeft && '0',
    right: isRight && '0',
    borderTopLeftRadius: isLeft && '8px',
    borderBottomLeftRadius: isLeft && '8px',
    borderTopRightRadius: isRight && '8px',
    borderBottomRightRadius: isRight && '8px',
    zIndex: '4',
    fontWeight: 600,
    padding: '16px 8px',
    borderRight: isLeft && '1px solid',
    borderLeft: isRight && '1px solid',
    borderColor: theme.palette.primary.lighter,
  },
  [`&.${tableCellClasses.body}`]: {
    position: 'sticky',
    left: isLeft && '0',
    right: isRight && '0',
    zIndex: '2',
    paddingLeft: isLeft && '16px',
    fontWeight: 600,
    backgroundColor: index % 2 !== 0 ? theme.palette.primary.lightest : theme.palette.extra.white,
    borderTopLeftRadius: isLeft && '8px',
    borderBottomLeftRadius: isLeft && '8px',
    borderTopRightRadius: isRight && '8px',
    borderBottomRightRadius: isRight && '8px',
    borderRight: isLeft && '1px solid',
    borderLeft: isRight && '1px solid',
    borderColor: theme.palette.primary.lighter,
  },
}));

export const PageWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: DESKTOP_VIEW_HEIGHT,
  marginBottom: theme.spacing(1),
   [theme.breakpoints.down('lg')]: {
    gap: 0,
    height: TABLET_HEIGHT,
  },
  [theme.breakpoints.down('sm')]: {
    gap: 0,
    height: MOBILE_HEIGHT,
  },
}));
