import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';

import filters from 'filters';

import { DataGrid } from '@mui/x-data-grid';
import { DESKTOP_VIEW, STATUSES_COLOR } from 'utils/constants/common';

const ContractsList = ({ list, isLoading }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const columns = [
    { field: 'number', headerName: '№', filterable: false, minWidth: 80, flex: 0.15 },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.2,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return (
          <Typography variant="body2" color={statusColor}>
            {status.label}
          </Typography>
        );
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'date',
      headerName: t('types.settings.profile.contract.date'),
      filterable: false,
      minWidth: 100,
      flex: 0.25,
      renderCell: ({ row: { date } }) => {
        const shortDate = filters.date(date);
        return <Typography variant="body2">{shortDate}</Typography>;
      },
    },
    {
      field: 'amount',
      headerName: t('types.settings.profile.contract.amount'),
      filterable: false,
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: 'paymentMethod',
      headerName: t('types.settings.profile.contract.paymentMethod'),
      filterable: false,
      minWidth: 160,
      flex: 0.25,
      renderCell: ({ row: { paymentMethod } }) => {
        return <Typography variant="body2">{paymentMethod?.label}</Typography>;
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'program',
      headerName: t('types.settings.profile.contract.program'),
      filterable: false,
      minWidth: 200,
      flex: 0.25,
      renderCell: ({ row: { program } }) => {
        return <Typography variant="body2">{program?.name}</Typography>;
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        py: 1,
        px: isDesktop ? 2 : 1,
        backgroundColor: 'extra.white',
        borderRadius: 2,
        height: '100%',
      }}
    >
      <DataGrid
        rows={list}
        columns={columns}
        loading={isLoading}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        hideFooter
        rowHeight={48}
        columnHeaderHeight={48}
        disableColumnMenu
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      />
    </Box>
  );
};

export default memo(ContractsList);
