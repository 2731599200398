import { memo, useEffect, useState } from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';
import Loader from 'views/common/Loader';
import ControlPanel from './pdf/ControlPanel';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import api from '../../../../../services/api';
import { MOBILE_VIEW } from 'utils/constants/common';

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

const PresentationViewCard = ({ material }) => {
  const [scale, setScale] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);
  const [contentDisposition, setContentDisposition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery(MOBILE_VIEW);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    isMobile ? setScale(0.6) : setScale(1.0);
  }, [isMobile]);

  useEffect(() => {
    setIsLoading(true);
    api.get(`files/drive?id=${material?.fileId}`, {}, { responseType: 'blob', raw: true }).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type });
      setFile(blob);
      const contentDisposition = response.headers?.['content-disposition'];
      setContentDisposition(contentDisposition);
      setIsLoading(false);
    });
  }, [material]);

  const handleDownloadFile = () => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    const decodedFilename = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf('"') + 1, contentDisposition.lastIndexOf('"'))
    );
    link.download = decodedFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Stack width="100%" height="99%" position="relative" alignItems="center" direction="column">
      <Box sx={{ position: 'sticky', mb: 1, zIndex: 1 }} display="flex" flexDirection="row" gap={2}>
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={file}
          handleDownloadFile={handleDownloadFile}
        />
      </Box>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box width="100%" display="flex" justifyContent="center" sx={{ overflowY: 'scroll', overflowX: 'auto' }}>
          {file && (
            <>
              <Document options={options} file={file} loading={<Loader />} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} orientation="landscape" />
              </Document>
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default memo(PresentationViewCard);
