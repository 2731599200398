import { Badge, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import filters from 'filters';
import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { DESKTOP_VIEW } from 'utils/constants/common';

const AssignmentNavItem = ({ assignment }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { groupId, assignmentId } = useParams();
  const isSelectedAssignment = assignment?.id === +assignmentId;

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { closeProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const handleAssignmentSelect = (assignment) => {
    navigate(`/assignments/groups/${groupId}/${assignment.id}`);
    !isDesktop && closeProgramMobileMenu();
  };

  const listItemRef = useRef(null);

  useEffect(() => {
    if (isSelectedAssignment && listItemRef.current) {
      listItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSelectedAssignment]);

  return (
    <ListItemButton
      ref={listItemRef}
      key={assignment.id}
      onClick={() => handleAssignmentSelect(assignment)}
      sx={{
        flexGrow: 0,
        py: 0.5,
        pr: 2,
        backgroundColor: isSelectedAssignment ? 'primary.lighter' : 'transparent',
        '&:hover': {
          color: 'primary.main',
        },
      }}
    >
      <ListItemText
        primary={assignment?.number + '. ' + assignment?.name}
        primaryTypographyProps={{
          fontWeight: 500,
          fontSize: 14,
        }}
        secondary={
          assignment?.deadline &&
          `${t('types.assignment.deadline')}: ${filters.date(assignment?.deadline, 'dd.MM.yyyy')}`
        }
        secondaryTypographyProps={{
          fontWeight: 400,
          fontSize: 12,
          color: 'text.light',
        }}
        sx={{ my: 0 }}
      />
      <Badge badgeContent={assignment?.unchecked} color="secondary" />
    </ListItemButton>
  );
};

export default memo(AssignmentNavItem);
