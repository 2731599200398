import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const studentSlice = createSlice({
  name: 'studentSlice',
  initialState: {
    activity: loadDataInitialState([]),
    activityTotal: loadDataInitialState(null),
    programs: loadDataInitialState([]),
    program: loadDataInitialState(null),
    grade: loadDataInitialState(null),
    grades: loadDataInitialState([]),
    calendar: loadDataInitialState([]),
    calendarNearest: loadDataInitialState([]),
    invoices: loadDataInitialState([]),
  },
  reducers: {
    replaceMaterial(state, action) {
      const { selectedModuleId, selectedLessonId, materialId, value } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          const material = lesson.materials.find((material) => {
            return materialId === material.id;
          });
          if (material) {
            material.status = value.status;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchActivity, 'activity');
    loadDataExtraReducer(builder, thunks.fetchActivityTotal, 'activityTotal');
    loadDataExtraReducer(builder, thunks.fetchPrograms, 'programs');
    loadDataExtraReducer(builder, thunks.fetchProgram, 'program');
    loadDataExtraReducer(builder, thunks.fetchGrade, 'grade');
    loadDataExtraReducer(builder, thunks.fetchAllGradesForProgram, 'grades');
    loadDataExtraReducer(builder, thunks.fetchCalendar, 'calendar');
    loadDataExtraReducer(builder, thunks.fetchCalendarNearest, 'calendarNearest');
    loadDataExtraReducer(builder, thunks.fetchInvoices, 'invoices');
  },
});

export const { replaceMaterial } = studentSlice.actions;
export default studentSlice.reducer;
