import { userSelectors } from 'store/ducks/user';
import store from 'store';
import { useState } from 'react';
import { DateTime } from 'luxon';
// import TurndownService from 'turndown';

const navigator = window.navigator || {};

const fallbackCopyTextToClipboard = async (text) => {
  const textArea = document.createElement('input');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '-9999px';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  textArea.setSelectionRange(0, 99999);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    return msg;
  } catch (err) {
    console.err('Unable to copy to clipboard'); //eslint-disable-line
  }

  document.body.removeChild(textArea); //eslint-disable-line
  return 'success';
};

/** --- Copy to Clipboard --- */
export function useCopyToClipboard() {
  const [copyResult, setCopyResult] = useState(null);

  const copy = async (text) => {
    try {
      if (!navigator.clipboard) {
        const msg = await fallbackCopyTextToClipboard(text);
        setCopyResult({ state: 'success', message: msg });
      } else await navigator.clipboard.writeText(text);
      setCopyResult({ state: 'success' });
    } catch (e) {
      setCopyResult({ state: 'error', message: e.message });
      throw e;
    } finally {
      // 👇 Show the result feedback for 2 seconds
      setTimeout(() => {
        setCopyResult(null);
      }, 2000);
    }
  };

  // 👇 We want the result as a tuple
  return [copy, copyResult];
}

export const transformErpRoleToLmsRole = (rolesObject, userRole) => {
  let foundRole = null;

  Object.entries(rolesObject).forEach(([key, valueArray]) => {
    if (valueArray.includes(userRole)) {
      foundRole = key;
    }
  });

  return foundRole;
};

export const getNameAndNumber = (option) => {
  return option ? '№' + ' ' + option.number + '.' + ' ' + option.name : '';
};

/** --- Get data from local storage --- */
export const getDataFromLS = (key) => {
  const lsData = localStorage.getItem(key);
  if (!lsData) return '';
  try {
    const value = JSON.parse(lsData);
    return value;
  } catch (e) {
    return '';
  }
};

export const hasPermission = (permission) => {
  const currentRole = userSelectors.getCurrentRole()(store.getState());

  return permission.includes(currentRole);
};

export const getLastNameAndNameLetter = (option) => {
  return option?.contact ? option.contact.lastName + ' ' + option.contact.name[0] + '.' : '';
};

export const getLastNameAndFirstName = (option) => {
  return option ? option.contact.lastName + ' ' + option.contact.name : '';
};

export const getStartAndEndOfMonth = (selectedDate) => {
  const startOfMonth = selectedDate.startOf('month').toISODate();
  const endOfMonth = selectedDate.endOf('month').toISODate();
  return { startOfMonth, endOfMonth };
};

export const getCurrentDate = () => {
  return DateTime.now();
};

export const makeShortString = (string, symbolsNumber) => {
  if (!string) {
    return;
  }
  if (string?.length <= symbolsNumber) {
    return string;
  }
  return string?.slice(0, symbolsNumber) + '...';
};

//TODO: markdown
// export const turndownService = new TurndownService({
//   headingStyle: 'atx',
//   hr: '---',
//   bulletListMarker: '-',
//   codeBlockStyle: 'fenced',
//   emDelimiter: '*',
//   strongDelimiter: '**',
//   linkStyle: 'inlined',
//   linkReferenceStyle: 'full',
//   preformattedCode: true,
// });
// turndownService.keep(['pre']);

// turndownService.addRule('linebreak', {
//   filter: ['br'],
//   replacement: () => '\n',
// });

// // turndownService.addRule('paragraph', {
// //   filter: 'p',
// //   replacement: (content) => `\n${content}\n`,
// // });

// // turndownService.addRule('codeblock', {
// //   filter: (node) => node.nodeName === 'PRE' && node.firstChild.nodeName === 'CODE',
// //   replacement: (content, node) => {
// //     return `\n\`\`\`\n${node.textContent}\n\`\`\`\n`;
// //   },
// // });

// // Custom rule for alignment
// turndownService.addRule('alignment', {
//   filter: (node) => ['LEFT', 'RIGHT', 'CENTER', 'JUSTIFY'].includes(node.style.textAlign.toUpperCase()),
//   replacement: (content, node) => {
//     const alignment = node.style.textAlign.toLowerCase();
//     return `{:${alignment}}${content}{:${alignment}}`;
//   },
// });

