import { memo } from 'react';
import { IconButton, Stack } from '@mui/material';

import GridViewIcon from '@mui/icons-material/GridView';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import SortPrograms from '../pages/programs/components/programs/topbar/SortPrograms';
import { useProgramsViewContext } from 'services/context/programsListViewContext';

const ProgramsListTopBar = ({ sortBy, setSortBy, hideTopBar }) => {
  const { programsView, toggleView } = useProgramsViewContext();

  return (
    <Stack
      display={hideTopBar ? 'none' : 'flex'}
      direction="row"
      justifyContent={'flex-end'}
      alignItems="center"
      pb={1}
      mb={2}
      sx={{ borderBottom: '1px solid', borderBottomColor: 'primary.lighter' }}
    >
      <Stack direction="row" gap={3} alignItems="center">
        <SortPrograms sortBy={sortBy} setSortBy={setSortBy} />
        <IconButton onClick={toggleView}>
          {programsView === 'column' ? (
            <GridViewIcon sx={{ color: 'primary.main' }} />
          ) : (
            <SplitscreenIcon sx={{ color: 'primary.main' }} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default memo(ProgramsListTopBar);
