import { Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';

import { a11yProps } from 'utils/tabsFunc';

import AssignmentForm from './components/assignments/AssignmentForm';
import store from 'store';
import { lessonSelectors, lessonThunks } from 'store/ducks/lesson';
import { assignmentActions, assignmentSelectors, assignmentThunks } from 'store/ducks/assignment';
import AssignmentDetails from './components/assignments/AssignmentDetails';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const AddEditAssignmentPage = () => {
  const { id, lessonId, moduleId, assignmentId } = useParams();
  const [tabsValue, setTabsValue] = useState(0);
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const { t } = useTranslation();

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);

  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const lessons = useSelector(lessonSelectors.getLessons());
  const assignment = useSelector(assignmentSelectors.getAssignment());
  const assignmentLoading = useSelector(assignmentSelectors.assignmentLoading());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    programs?.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole, programs]);

  useEffect(() => {
    lessons?.length === 0 && store.dispatch(lessonThunks.fetchLessons(id));
  }, [id, currentRole, lessons]);

  useEffect(() => {
    assignmentId && store.dispatch(assignmentThunks.fetchAssignment(assignmentId));
  }, [assignmentId, currentRole]);

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));
  }, [program, id, currentRole]);

  useEffect(() => {
    return () => {
      store.dispatch(assignmentActions.clearAssignment());
    };
  }, []);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={assignment ? t('types.assignment.edit') : t('types.assignment.add')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            selectedMaterialPriorityLabel={
              assignment ? t('types.assignment.edit') : t('types.assignment.add')
            }
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
          />
        </ErrorBoundary>
      )}
      <Stack
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="center"
        gap={2}
        height="100%"
        sx={{ overflowY: 'auto' }}
      >
        {(isDesktop || isProgramMenuOpen) && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <ProgramNavigation program={program} />
          </ErrorBoundary>
        )}
        {!isProgramMenuOpen && (
          <ELementWrapper sx={{ width: '100%', height: '100%' }} flex={3} padding={2}>
            <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
              <Tab
                sx={{ width: '33.33%', fontSize: isDesktop ? 16 : 14 }}
                label={t('types.assignment.content')}
                {...a11yProps(0)}
              />
              <Tab
                sx={{ width: '33.33%', fontSize: isDesktop ? 16 : 14 }}
                label={t('types.assignment.details')}
                {...a11yProps(1)}
              />
              <Tab
                sx={{ width: '33.33%', fontSize: isDesktop ? 16 : 14 }}
                label={t('types.assignment.criteria')}
                {...a11yProps(2)}
              />
            </Tabs>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <AssignmentForm
                tabsValue={tabsValue}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                program={program}
                programs={programs}
                lessons={lessons}
                assignment={assignment}
                t={t}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <AssignmentDetails tabsValue={tabsValue} assignment={assignment} assignmentLoading={assignmentLoading} />
            </ErrorBoundary>
          </ELementWrapper>
        )}
      </Stack>
    </PageWrapper>
  );
};

export default memo(AddEditAssignmentPage);
