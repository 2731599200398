export default {
  primaryBaseLms: '#4D54C9', // purple
  secondaryBaseLms: '#30BFAD', // cyan
  textBase: '#40404B',
  errorBase: '#BF3030', // red
  infoBase: '#1380FF', // blue
  warningBase: '#CEC600', //yellow
  successBase: '#30BFAD', // --- CYAN ---
  changed: '#DB00FF', // pink
  white: '#FEFEFE',
  grey: '#A9A9A9',
};
