import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'store/utils/loadDataInitialState';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';

const materialSlice = createSlice({
  name: 'materialSlice',
  initialState: {
    material: loadDataInitialState(null),
  },

  reducers: {},
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.createMaterial, 'material');
    loadDataExtraReducer(builder, thunks.updateMaterial, 'material');
  },
});

export const {} = materialSlice.actions;
export default materialSlice.reducer;
