import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import {
  GROUPS_FETCH_BY_ROLES,
  GROUPS_SELECTORS_BY_ROLES,
  GROUP_GRADES_FETCH_BY_ROLES,
  GROUP_GRADES_IS_LOADING_BY_ROLES,
  GROUP_GRADES_SELECTORS_BY_ROLES,
} from 'utils/constants/grades';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import GradeBookTable from './components/GradeBookTable';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const GradeBookPage = () => {
  const { groupId } = useParams();
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const groupGrades = useSelector(GROUP_GRADES_SELECTORS_BY_ROLES[currentRole]);
  const groupGradesLoading = useSelector(GROUP_GRADES_IS_LOADING_BY_ROLES[currentRole]);
  const groups = useSelector(GROUPS_SELECTORS_BY_ROLES[currentRole]);
  const chosenGroup = groups.find((group) => group.id === +groupId);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(GROUP_GRADES_FETCH_BY_ROLES[currentRole](groupId));
  }, [currentRole, groupId]);

  useEffect(() => {
    groups.length === 0 && store.dispatch(GROUPS_FETCH_BY_ROLES[currentRole]);
  }, [groups, currentRole]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={`${chosenGroup?.name} ${t('types.grade.book')}`} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile selectedMaterialPriorityLabel={chosenGroup?.name} isToShowProgramMenuBtn />
        </ErrorBoundary>
      )}
      <ELementWrapper
        width="100%"
        height="100%"
        sx={{
          p: { xs: 0.5, sm: 1, lg: 3 },
          zIndex: 2,
          position: 'relative',
          overflowY: 'hidden',
        }}
      >
        {groupGradesLoading && <Loader />}
        {!groupGradesLoading &&
          (groupGrades.length === 0 ? (
            <Stack justifyContent="center" width="100%" height="100%">
              <Typography variant="h5" color="text.main" textAlign="center">
                {t('messages.warning.noGrades')}
              </Typography>
            </Stack>
          ) : (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <GradeBookTable groupGrades={groupGrades} />
            </ErrorBoundary>
          ))}
      </ELementWrapper>
    </PageWrapper>
  );
};

export default GradeBookPage;
