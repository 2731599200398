import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'store/utils/loadDataInitialState';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';

const lessonSlice = createSlice({
  name: 'lessonSlice',
  initialState: {
    lessons: loadDataInitialState([]),
  },
  reducers: {},
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchLessons, 'lessons');
  },
});

export const {} = lessonSlice.actions;
export default lessonSlice.reducer;
