import { ErrorOutline } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

const Error = ({ message }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" p={2} gap={1} height="100%">
      <ErrorOutline color="error" />
      <Typography variant="body2" color="error">
        {message}
      </Typography>
    </Stack>
  );
};

export default Error;
