import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import { Stack, Tab, Tabs, useTheme } from '@mui/material';
import { studentSelectors } from 'store/ducks/student';
import InvoicesList from './InvoicesList';
import ContractsList from './ContractsList';
import Error from 'views/common/Error';
import { TabPanel, a11yProps } from 'utils/tabsFunc';

const ContractDetails = () => {
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const theme = useTheme();

  const invoices = useSelector(studentSelectors.getInvoices());
  const invoicesLoading = useSelector(studentSelectors.invoicesLoading()) && !invoices;

  let uniqueContracts = [];

  if (invoices && invoices.length > 0) {
    invoices.forEach(({ contract }) => {
      uniqueContracts[contract.number] = contract;
    });
  }
  uniqueContracts = Object.values(uniqueContracts);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const sortedByDueDateList = invoices?.slice().sort((a, b) => {
    const dateA = new Date(a.dueDate);
    const dateB = new Date(b.dueDate);
    return dateA - dateB;
  });

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden', justifyContent: 'flex-start' }}>
      <Tabs
        value={tabsValue}
        onChange={handleChange}
        aria-label="main data"
        sx={{ '&.MuiTabs-flexContainer': { gap: 2 } }}
      >
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
            borderRadius: '16px 16px 0px 0px',
            '&:first-of-type': {
              borderBottomLeftRadius: 0,
            },
            '&.Mui-selected': {
              border: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.extra.white,
              fontWeight: 700,
            },
          }}
          label={t('types.settings.profile.invoice.namePlural')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
            borderRadius: '16px 16px 0px 0px',
            '&:last-of-type': {
              borderBottomRightRadius: 0,
            },
            '&.Mui-selected': {
              border: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.extra.white,
              fontWeight: 700,
            },
          }}
          label={t('types.settings.profile.contract.namePlural')}
          {...a11yProps(1)}
        />
      </Tabs>

      <Stack
        sx={{
          backgroundColor: theme.palette.extra.white,
          borderRadius: theme.spacing(0, 0, 2, 2),
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <TabPanel value={tabsValue} index={0}>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <InvoicesList list={sortedByDueDateList} totalElements={invoices.length} isLoading={invoicesLoading} />
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <ContractsList list={uniqueContracts} isLoading={invoicesLoading} totalElements={uniqueContracts?.length} />
          </ErrorBoundary>
        </TabPanel>
      </Stack>
    </Stack>
  );
};

export default memo(ContractDetails);
