import { memo } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { PROGRAMS_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { studentSelectors } from 'store/ducks/student';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const KeyIndicatorsBar = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const activityTotal = useSelector(studentSelectors.getActivityTotal());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  if (!programs || !activityTotal) {
    return <Loader />;
  }

  //TODO: add points and achievements
  const indicators = [
    { number: programs?.length, key: 'programs' },
    { number: activityTotal, key: 'minutes' },
    // points: { number: 812, key: 'totalPoints' },
    // achievements: { number: 12, key: 'achievements' },
  ];

  return (
    <Stack flex={0.1} flexDirection="row" alignItems="center" justifyContent="space-around" py={isDesktop ? 2 : 1}>
      {indicators.map((indicator) => (
        <Stack key={indicator.key} justifyContent="center" alignItems="center">
          <Typography
            variant="h1"
            color="primary.main"
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: { fontSize: 24 },
              [theme.breakpoints.down('sm')]: { fontSize: 20 },
            })}
          >
            {indicator.number}
          </Typography>
          <Typography
            variant="h6"
            color="text.main"
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: { fontSize: 16 },
              [theme.breakpoints.down('sm')]: { fontSize: 14 },
            })}
          >
            {t(`types.dashboard.${indicator.key}`).toLowerCase()}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default memo(KeyIndicatorsBar);
