import { memo } from 'react';
import {
  Breadcrumbs,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { userSelectors } from 'store/ducks/user';
import { useSelector } from 'react-redux';
import AddMaterialMenu from './AddMaterialMenu';
import MaterialsFilter from './MaterialsFilter';

import { useMaterialsViewContext } from 'services/context/materialsListViewContext';

import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import GridViewIcon from '@mui/icons-material/GridView';
import { Link, useLocation, useParams } from 'react-router-dom';
import EditMaterial from '../../materials/EditMaterial';
import { makeShortString } from 'utils/helpers';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';
import { HelpOutlineOutlined } from '@mui/icons-material';

const ProgramViewTopBar = ({
  program,
  selectedLesson,
  selectedMaterial,
  selectedModule,
  selectedPriority,
  allMaterials,
  setFilteredMaterialsList,
  isAssignment,
  studentGrade,
  hideProgramViewTopBar,
  hideToggleView,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { id, priorityName, lessonId, moduleId, materialId, assignmentId, groupId } = useParams();
  const isStudent = currentRole === 'Student';
  const isAdmin = currentRole === 'Admin';
  const isSessionRecord = location.pathname.includes('record');

  const { materialsView, toggleView } = useMaterialsViewContext();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const breadcrumbNavigation = () => {
    if (groupId) {
      return isAssignment
        ? `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments`
        : `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}`;
    }
    return isAssignment
      ? `/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments`
      : `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}`;
  };

  const breadcrumbs = [
    <Typography key="1" color="text.primary" variant="body2" fontWeight={700}>
      {selectedLesson?.number}. {makeShortString(selectedLesson?.name, 15)}
    </Typography>,
  ];

  if (isAssignment || selectedPriority?.label) {
    breadcrumbs.push(
      <Link key="2" to={breadcrumbNavigation()} style={{ textDecoration: 'none' }}>
        <Typography color="text.primary" variant="body2">
          {isAssignment ? 'Assignments' : selectedPriority?.label}
        </Typography>
      </Link>
    );
  }

  if (selectedMaterial?.name) {
    breadcrumbs.push(
      <Typography key="3" color="text.primary" variant="body2">
        {makeShortString(selectedMaterial?.name, 15)}
      </Typography>
    );
  }

  const showStudentGrade = () => {
    if (!studentGrade) {
      return;
    }

    const hasGrade = studentGrade.grade !== undefined && studentGrade.grade !== null;
    const lastComment = studentGrade.comments[studentGrade.comments?.length - 1];
    const lastSubmission = studentGrade.submissions[studentGrade.submissions?.length - 1];
    const hasNewComment = !hasGrade && new Date(lastComment?.createdAt) > new Date(lastSubmission);

    if (hasNewComment) {
      return (
        <Typography variant="body2" color="error" fontWeight={600}>
          {t('types.grade.mentorComment')}
        </Typography>
      );
    }
    if (!hasNewComment && !hasGrade) {
      return (
        <Typography variant="body2" color="info.main" fontWeight={600}>
          {t('types.grade.pendingReply')}
        </Typography>
      );
    }
    if (hasGrade) {
      return (
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" color="text.main" fontWeight="700">
            {t('types.grade.name')}:
          </Typography>
          <Typography
            variant="body2"
            color={studentGrade?.grade >= 50 ? 'success.main' : 'error.main'}
            fontWeight="700"
            mr={0.5}
          >
            {studentGrade?.grade}
          </Typography>
          {studentGrade?.criteria.length > 0 && (
            <Tooltip
              title={
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'extra.white', my: 0 }}> {t('types.assignment.criteria')}</TableCell>
                      <TableCell sx={{ color: 'extra.white' }} align="center">
                        {t('base.dictionary.weight')}
                      </TableCell>
                      <TableCell sx={{ color: 'extra.white' }} align="center">
                        {t('types.grade.name')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentGrade?.criteria.map((row) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ color: 'extra.white' }} component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ color: 'extra.white' }} align="center">
                          {row.weight}
                        </TableCell>
                        <TableCell sx={{ color: 'extra.white' }} align="center">
                          {row.grade}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            >
              <HelpOutlineOutlined sx={{ color: 'primary.main', width: '18px', height: '18px', cursor: 'pointer' }} />
            </Tooltip>
          )}
        </Stack>
      );
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent={hideProgramViewTopBar ? 'flex-end' : 'space-between'}
      alignItems="center"
      pb={isDesktop ? 1 : 0}
      px={isDesktop ? 0 : 2}
      sx={{ zIndex: 1, borderBottom: isDesktop ? '1px solid' : 'none', borderBottomColor: 'primary.lighter' }}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ display: hideProgramViewTopBar ? 'none' : 'flex' }}>
        {selectedLesson && breadcrumbs}
      </Breadcrumbs>

      <Stack direction="row" gap={3} alignItems="center">
        {isAdmin && (
          <Stack direction="row" gap={1} alignItems="center">
            <AddMaterialMenu
              program={program}
              selectedLesson={selectedLesson}
              selectedPriority={selectedPriority}
              selectedModule={selectedModule}
            />
            {(materialId || assignmentId) && (
              <EditMaterial
                isAssignment={isAssignment}
                material={selectedMaterial}
                selectedLesson={selectedLesson}
                selectedModule={selectedModule}
              />
            )}
            {!materialId && !isAssignment && (
              <MaterialsFilter
                allMaterials={allMaterials}
                setFilteredMaterialsList={setFilteredMaterialsList}
                program={program}
                selectedLesson={selectedLesson}
                selectedMaterial={selectedMaterial}
                selectedPriority={selectedPriority}
                selectedModule={selectedModule}
              />
            )}
          </Stack>
        )}
        {isStudent && showStudentGrade()}

        {!materialId && !assignmentId && !isSessionRecord && (
          <IconButton onClick={toggleView} sx={{ display: hideToggleView ? 'none' : 'flex' }}>
            {materialsView === 'column' ? (
              <GridViewIcon sx={{ color: 'primary.main' }} />
            ) : (
              <SplitscreenIcon sx={{ color: 'primary.main' }} />
            )}
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ProgramViewTopBar);
