import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button, Stack, useTheme } from "@mui/material";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";

import SearchBar from "views/common/SearchBar";
import SettingsMenu from "./SettingsMenu";

const HeaderSettings = ({ search, handleButtonClick, buttonTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="extra.white"
        gap={4}
        py={1}
        px={2}
        sx={{
          borderBottomLeftRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
        }}
      >
        <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlined />} LinkComponent={Link} onClick={goBack}>
            {t("base.buttons.back")}
          </Button>
          <SettingsMenu />
        </Stack>
        <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
          {buttonTitle && (
            <Button onClick={handleButtonClick} variant="contained" sx={{ width: "fit-content" }}>
              {buttonTitle}
            </Button>
          )}
          {search && <SearchBar />}
        </Stack>
      </Stack>
    </>
  );
};

export default memo(HeaderSettings);
