import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchLessons = createAsyncThunk('lesson/fetchLessons', (programId) => {
  return api.get(`/lessons/programs/${programId}`);
});

export default {
  fetchLessons,
};
