import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, ListItemButton, ListItemText } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LessonStructure from './LessonStructure';

const LessonNavItem = ({ lesson, program, selectedModule }) => {
  const navigate = useNavigate();
  const { lessonId, groupId } = useParams();
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);

  /** --- Check clicked lesson with already selected lesson --- */
  const isSelectedLesson = lesson.id === selectedLesson?.id;

  const handleSelect = (lesson) => {
    /** --- If there is no selected lesson, we add lesson id param to url. --- */
    if (!isSelectedLesson) {
      groupId
        ? navigate(`/groups/${groupId}/programs/${program?.id}/module/${selectedModule?.id}/lesson/${lesson.id}`)
        : navigate(`/programs/${program?.id}/module/${selectedModule?.id}/lesson/${lesson.id}`);
    } else {
      /** --- If we click on already selected lesson we remove the param from URL --- */

      /** If current role Mentor or Trainer we use groups for routing and check
       * if url has groupId we navigate in routing with groups otherwise just programs */
      groupId
        ? navigate(`/groups/${groupId}/programs/${program?.id}/module/${selectedModule?.id}`)
        : navigate(`/programs/${program?.id}/module/${selectedModule?.id}`);
    }
  };

  return (
    <Box mt={0.5} ml={2} mr={1}>
      <ListItemButton onClick={() => handleSelect(lesson)} sx={{ pr: 0, py: 0.5 }}>
        <ListItemText
          primary={lesson.number + '.' + ' ' + lesson.name}
          primaryTypographyProps={{
            fontWeight: 600,
            fontSize: 14,
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowUpIcon
          sx={{
            color: 'text.main',
            fontSize: 18,
            fontWeight: 400,
            transform: isSelectedLesson ? 'rotate(180deg)' : 'rotate(90deg)',
            transition: '0.2s',
            ml: 2,
          }}
        />
      </ListItemButton>
      {/* --- Display the list of priorities only when lesson is selected ---  */}
      {isSelectedLesson && <LessonStructure selectedLesson={selectedLesson} selectedModule={selectedModule} />}
    </Box>
  );
};

export default memo(LessonNavItem);
