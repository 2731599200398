import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "@sentry/react";

import { Stack } from "@mui/material";

import { userSelectors } from "store/ducks/user";

import { FormColumnTitle, FormColumnWrapper } from "views/common/styledComponents";
import Error from "views/common/Error";
import Account from "./Account";
import PersonalInformation from "./PersonalInformation";
import LoginDetails from "./LoginDetails";
import General from "./General";
import ContractDetails from "./ContractDetails";

const DesktopProfileView = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const {t} = useTranslation();

  const isStudent = currentRole === 'Student';

  return (
    <>
      <Stack direction="row" gap={2} mt={2} width="100%" minHeight="360px" maxHeight="424px">
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.account')}</FormColumnTitle>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <Account />
          </ErrorBoundary>
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.personalInfo')}</FormColumnTitle>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <PersonalInformation />
          </ErrorBoundary>
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.login')}</FormColumnTitle>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <LoginDetails />
          </ErrorBoundary>
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.general')}</FormColumnTitle>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <General />
          </ErrorBoundary>
        </FormColumnWrapper>
      </Stack>
      {isStudent && <ContractDetails />}
    </>
  );
};

export default memo(DesktopProfileView);
