import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';

import HeaderSettings from 'views/pages/settings/components/HeaderSettings';
import { DESKTOP_VIEW } from 'utils/constants/common';

const SettingsLayout = () => {
  const [props, setProps] = useState({
    search: false,
    buttonTitle: '',
    handleButtonClick: null,
  });
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <Box>
      {isDesktop && (
        <HeaderSettings
          search={props.search}
          buttonTitle={props.buttonTitle}
          handleButtonClick={props.handleButtonClick}
        />
      )}
      <Outlet context={[props, setProps]} />
    </Box>
  );
};

export default SettingsLayout;
