import { Box, Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { ElementTitleBlackColor } from 'views/common/styledComponents';

const EventsForTodayTomorrow = ({ todaySessions, tomorrowSessions }) => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isAdmin = currentRole === 'Admin';

  const { t } = useTranslation();

  const sections = [
    {
      title: t('types.calendar.today'),
      data: todaySessions,
    },
    {
      title: t('types.calendar.tomorrow'),
      data: tomorrowSessions,
    },
  ];

  return (
    <Stack gap={2} height="100%">
      {sections.map(
        (section) =>
          section.data.length !== 0 && (
            <Box key={section.title}>
              <ElementTitleBlackColor sx={{ fontSize: 16 }}>{section.title}</ElementTitleBlackColor>
              <Divider sx={{ my: 0.5 }} />
              {section.data.map((session) => (
                <Stack key={session.id} flexDirection="row" alignItems="center" justifyContent="space-between" gap={1}>
                  <Typography variant="body2" color="text.light">
                    {isAdmin && `[${session.group.name}] `}
                    {session.lesson.number}
                    {'. '}
                    {session.lesson.name}
                  </Typography>
                  <Typography variant="body2" color="text.light">
                    {DateTime.fromISO(session.date).toFormat('HH:mm')}
                  </Typography>
                </Stack>
              ))}
            </Box>
          )
      )}
    </Stack>
  );
};

export default EventsForTodayTomorrow;
