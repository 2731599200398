const getActivity = () => (state) => state.student.activity.data;
const activityLoading = () => (state) => state.student.activity.isLoading;
const getActivityError = () => (state) => state.student.activity.error;

const getActivityTotal = () => (state) => state.student.activityTotal.data;
const activityTotalLoading = () => (state) => state.student.activityTotal.isLoading;
const getActivityTotalError = () => (state) => state.student.activityTotal.error;

const getPrograms = () => (state) => state.student.programs.data;
const programsLoading = () => (state) => state.student.programs.isLoading;
const getProgramsError = () => (state) => state.student.programs.error;

const getProgram = () => (state) => state.student.program.data;
const programLoading = () => (state) => state.student.program.isLoading;
const getProgramError = () => (state) => state.student.program.error;

const getGrade = () => (state) => state.student.grade.data;
const gradeLoading = () => (state) => state.student.grade.isLoading;
const getGradeError = () => (state) => state.student.grade.error;

const getAllGradesForProgram = () => (state) => state.student.grades.data;
const allGradesForProgramLoading = () => (state) => state.student.grades.isLoading;
const getAllGradesForProgramError = () => (state) => state.student.grades.error;

const getCalendar = () => (state) => state.student.calendar.data;
const calendarLoading = () => (state) => state.student.calendar.isLoading;
const getCalendarError = () => (state) => state.student.calendar.error;

const getCalendarNearest = () => (state) => state.student.calendarNearest.data;
const calendarNearestLoading = () => (state) => state.student.calendarNearest.isLoading;
const getCalendarNearestError = () => (state) => state.student.calendarNearest.error;

const getInvoices = () => (state) => state.student.invoices.data;
const invoicesLoading = () => (state) => state.student.invoices.isLoading;
const getInvoicesError = () => (state) => state.student.invoices.error;

export default {
  getActivity,
  activityLoading,
  getActivityError,
  getActivityTotal,
  activityTotalLoading,
  getActivityTotalError,
  getPrograms,
  programsLoading,
  getProgramsError,
  getProgram,
  programLoading,
  getProgramError,
  getGrade,
  gradeLoading,
  getGradeError,
  getAllGradesForProgram,
  allGradesForProgramLoading,
  getAllGradesForProgramError,
  getCalendar,
  calendarLoading,
  getCalendarError,
  getCalendarNearest,
  calendarNearestLoading,
  getCalendarNearestError,
  getInvoices,
  invoicesLoading,
  getInvoicesError,
};
