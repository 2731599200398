import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import {} from './slice';

const fetchAssignment = createAsyncThunk('assignment/fetchAssignment', async (id) => {
  return api.get(`/assignments/${id}`);
});
const createAssignment = createAsyncThunk('assignment/createAssignment', (data) => {
  return api.post('/assignments', data);
});

const updateAssignment = createAsyncThunk('assignment/updateAssignment', (data) => {
  return api.put(`/assignments/${data.id}`, data);
});

const createAssignmentGradeCriteria = createAsyncThunk('assignment/createAssignmentGradeCriteria', (data) => {
  return api.post(`/assignments/${data.assignmentId}/grades`, data.grade);
});

const updateAssignmentGradeCriteria = createAsyncThunk('assignment/updateAssignmentGradeCriteria', (data) => {
  return api.put(`/assignments/${data.assignmentId}/grades`, data.grade);
});

const deleteAssignmentGradeCriteria = createAsyncThunk('assignment/deleteAssignmentGradeCriteria', (data) => {
  return api.delete(`/assignments/${data.assignmentId}/grades/${data.critireaId}`);
});

export default {
  createAssignment,
  fetchAssignment,
  updateAssignment,
  createAssignmentGradeCriteria,
  updateAssignmentGradeCriteria,
  deleteAssignmentGradeCriteria,
};
