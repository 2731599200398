import { memo } from 'react';
import { IconButton, Stack } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ProgramProgressBar from '../../../../common/ProgramProgressBar';
import { useNavigate, useParams } from 'react-router-dom';

const ProgramItemHeader = ({ program }) => {
  const navigate = useNavigate();
  const {
    id,
    moduleId,
    groupId,

  } = useParams();

  const handleCollapseMenu = () => {
    if (!moduleId) {
      return;
    }
    /** If current role Mentor or Trainer we use groups for routing and check
     * if url has groupId we navigate in routing with groups otherwise just programs */
    groupId ? navigate(`/groups/${groupId}/programs/${id}`) : navigate(`/programs/${id}`);
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap={1} p={2} pb={1}>
      <ProgramProgressBar program={program} programProgress={program?.completion} />

      {/** --- IconButton which collapses all expanded elements of menu --- */}
      <IconButton
        sx={{ borderRadius: '10px', border: '1px solid', borderColor: 'text.main', p: 0.5 }}
        size="small"
        onClick={handleCollapseMenu}
      >
        {moduleId ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </IconButton>
    </Stack>
  );
};

export default memo(ProgramItemHeader);
