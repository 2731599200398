import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

/** --- Thunks for manage material: CREATE, UPDATE, DELETE ---  */
const createMaterial = createAsyncThunk('materials/createMaterial', (data) => {
  return api.post('/materials', data);
});

const updateMaterial = createAsyncThunk('material/updateMaterial', (data) => {
  return api.put(`/materials/${data.id}`, data);
});

const deleteMaterial = createAsyncThunk('materials/deleteMaterial', (materialId) => {
  return api.delete(`/materials/${materialId}`);
});



export default {
  createMaterial,
  updateMaterial,
  deleteMaterial,

};
