const getCurrentUser = () => state => state.user.current.data;
const userLoading = () => state => state.user.current.isLoading;
const initialUserLoading = () => state => state.user.initialLoading;

const getCurrentRole = () => (state) => state.user.currentRole;

export default {
  getCurrentUser,
  userLoading,
  initialUserLoading,
  getCurrentRole,
};
