import { memo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import { DESKTOP_VIEW } from 'utils/constants/common';
import MaterialCard from './MaterialCard';

const MaterialsList = ({ filteredMaterialsList, selectedModule, selectedLesson, handleSelectMaterial }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <Grid
      container
      spacing={isDesktop ? 2 : 1}
      sx={{
        overflowY: 'auto',
        mt: isDesktop ? 1 : 0,
      }}
    >
      {filteredMaterialsList?.map((material) => {
        return (
          <MaterialCard
            key={material.id}
            selectedModule={selectedModule}
            selectedLesson={selectedLesson}
            material={material}
            onClick={(e) => handleSelectMaterial(e, material)}
          />
        );
      })}
    </Grid>
  );
};

export default memo(MaterialsList);
