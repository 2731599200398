import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const articleSlice = createSlice({
  name: 'articleSlice',
  initialState: {
    list: loadDataInitialState([]),
    item: loadDataInitialState(null),
  },
  reducers: {
    replaceArticle(state, action) {
      const index = state.list.data.findIndex((i) => i.id === +action.payload.data.id);
      state.list.data[index] = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchArticles, 'list');
    loadDataExtraReducer(builder, thunks.fetchArticle, 'item');
    loadDataExtraReducer(builder, thunks.createArticle, 'item');
    loadDataExtraReducer(builder, thunks.updateArticle, 'item');
  },
});

export const { replaceArticle } = articleSlice.actions;
export default articleSlice.reducer;
