import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { userSelectors } from 'store/ducks/user';

const RoleCheck = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isEdStuff = currentRole === 'Trainer' || currentRole === 'Mentor';
  const isStudent = currentRole === 'Student';
  const isAdmin = currentRole === 'Admin';

  if (isEdStuff) {
    return <Navigate to="/groups" replace />;
  }

  if (isStudent) {
    return <Navigate to="/dashboard" replace />;
  }

  if (isAdmin) {
    return <Navigate to="/programs" replace />;
  }
};

export default RoleCheck;
