import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { memo } from 'react';
import { ReactComponent as AddIcon } from 'theme/icons/addIcon.svg';

const AddRemoveInputAdornment = ({ name, add }) => {
  const theme = useTheme();

  return (
    <Box>
      <Tooltip title={`Add one more ${name} field`}>
        <IconButton sx={{ p: 0.25 }} onClick={add}>
          <AddIcon stroke={theme.palette.primary.main} fill="none" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(AddRemoveInputAdornment);
