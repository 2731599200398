import { memo, useEffect, useState } from 'react';
import { theme } from './theme';
import { ThemeProvider } from '@mui/material';
import api from '../services/api';
import Loader from '../views/common/Loader';
import colors from './colors';

const CustomThemeProvider = ({ children }) => {
  const [customTheme, setCustomTheme] = useState(theme(colors));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/themes').then((res) => {
      if (res) {
        const { logo, logoShort, ...updatedColors } = res;
        setCustomTheme(theme({ ...colors, ...updatedColors }, logo, logoShort));
      }

      setLoading(false);
    });
  }, []);

  return <ThemeProvider theme={customTheme}>{loading ? <Loader /> : children}</ThemeProvider>;
};

export default memo(CustomThemeProvider);
