import { IconButton, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { MOBILE_VIEW } from 'utils/constants/common';

const ControlPanel = ({ pageNumber, numPages, setPageNumber, scale, setScale, handleDownloadFile, file }) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = isMobile ? scale < 0.4 : scale < 0.7;
  const isMaxZoom = isMobile ? scale >= 0.8 : scale >= 1.3;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={isMobile ? 1 : 2}
      sx={{
        width: '100%',
        backgroundColor: 'extra.white',
      }}
    >
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <IconButton
          onClick={goToFirstPage}
          disabled={isFirstPage}
          sx={{ color: 'primary.main', '&:disabled': { color: 'primary.light' }, padding: isMobile ? 0.5 : 1 }}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={goToPreviousPage}
          disabled={isFirstPage}
          sx={{ color: 'primary.main', '&:disabled': { color: 'primary.light' }, padding: isMobile ? 0.5 : 1 }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <TextField
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root .MuiInputBase-input': {
              fontSize: isMobile ? 14 : 16,
              padding: isMobile ? '4px 0 4px 8px' : '10px 0 10px 16px',
              width: isMobile ? '24px' : '40px',
            },
          }}
          value={pageNumber}
          onChange={onPageChange}
        />
        <IconButton
          onClick={goToNextPage}
          disabled={isLastPage}
          sx={{ color: 'primary.main', '&:disabled': { color: 'primary.light' }, padding: isMobile ? 0.5 : 1 }}
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          onClick={goToLastPage}
          disabled={isLastPage}
          sx={{ color: 'primary.main', '&:disabled': { color: 'primary.light' }, padding: isMobile ? 0.5 : 1 }}
        >
          <LastPageIcon />
        </IconButton>
      </Stack>

      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <IconButton
          onClick={zoomOut}
          disabled={isMinZoom}
          sx={{ color: 'text.main', '&:disabled': { color: 'text.light' }, padding: isMobile ? 0.5 : 1 }}
        >
          <ZoomOutIcon />
        </IconButton>
        <Typography mx={1} variant="body2">
          {(scale * 100).toFixed()}%
        </Typography>
        <IconButton
          onClick={zoomIn}
          disabled={isMaxZoom}
          sx={{ color: 'text.main', '&:disabled': { color: 'text.light' } }}
        >
          <ZoomInIcon />
        </IconButton>
      </Stack>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        {file && (
          <Tooltip title="Download presentation">
            <IconButton onClick={handleDownloadFile} sx={{ color: 'primary.main' }}>
              <DownloadForOfflineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default ControlPanel;
