import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const mentorSlice = createSlice({
  name: 'mentorSlice',
  initialState: {
    groups: loadDataInitialState([]),
    groupProgram: loadDataInitialState(null),
    groupAssignments: loadDataInitialState([]),
    assignmentSubmissions: loadDataInitialState([]),
    grade: loadDataInitialState(null),
  },

  reducers: {
    replaceGrade(state, action) {
      const index = state.assignmentSubmissions.data.findIndex((i) => i.id === +action.payload.data.id);
      state.assignmentSubmissions.data[index] = action.payload.data;

      state.grade.data = action.payload.data;
    },

    decreaseCountUnchecked(state, action) {
      const { selectedAssignment, unchecked } = action.payload;
      const assignment = state.groupAssignments.data.find((a) => a.id === selectedAssignment.id);
      if (assignment) {
        assignment.unchecked = unchecked;
      }
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchGroups, 'groups');
    loadDataExtraReducer(builder, thunks.fetchGroupProgram, 'groupProgram');
    loadDataExtraReducer(builder, thunks.fetchGroupAssignments, 'groupAssignments');
    loadDataExtraReducer(builder, thunks.fetchAssignmentSubmissions, 'assignmentSubmissions');
    loadDataExtraReducer(builder, thunks.fetchGrade, 'grade');
  },
});

export const { replaceGrade, decreaseCountUnchecked } = mentorSlice.actions;
export default mentorSlice.reducer;
