import { memo } from 'react';
import { Stack, useMediaQuery } from '@mui/material';

import ListItemView from 'views/pages/grades/components/ListItemView';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import ListItemViewMobile from './ListItemViewMobile';

const GradesList = ({ grades }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);
  return (
    <>
      <Stack mx={isMobile ? 0 : 2} gap={1}>
        {grades.map((grade) =>
          isDesktop ? <ListItemView key={grade.id} item={grade} /> : <ListItemViewMobile key={grade.id} item={grade} />
        )}
      </Stack>
    </>
  );
};

export default memo(GradesList);
