import { memo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW } from 'utils/constants/common';
import SessionRecordCard from './SessionRecordCard';

const SessionRecordsList = ({ sessions, selectedLesson, handleSelectRecord }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <Grid container spacing={isDesktop ? 2 : 1} sx={{ mt: isDesktop ? 1 : 0 }}>
      {sessions?.map((session) => {
        return (
          <SessionRecordCard
            key={session.id}
            recordName={selectedLesson?.name}
            onClick={(e) => handleSelectRecord(e, session)}
          />
        );
      })}
    </Grid>
  );
};

export default memo(SessionRecordsList);
