import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useTheme,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLastNameAndNameLetter } from 'utils/helpers';
import { StickyTableCell } from 'views/common/styledComponents';

const GradeBookTable = ({ groupGrades }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const assignmentMap = new Map();
  groupGrades?.forEach((item) => {
    const assignmentId = item.assignment.id;
    const assignmentNumber = item.assignment.number;
    const assignmentName = item.assignment.name;
    const assignmentType = item.assignment.type;
    const assignmentRequirement = item.assignment.requirement;
    assignmentMap.set(assignmentId, {
      number: assignmentNumber,
      name: assignmentName,
      type: assignmentType,
      requirement: assignmentRequirement,
    });
  });

  const assignmentNames = Array.from(assignmentMap.entries()).map(([id, { number, name, type, requirement }]) => ({
    id,
    number,
    name,
    type,
    requirement,
  }));

  assignmentNames.sort((a, b) => parseInt(a.number, 10) - parseInt(b.number, 10));

  const calculateAverageGrade = (row, compulsoryAssignments) => {
    const gradeSum = compulsoryAssignments.reduce((sum, assignment) => sum + (row[assignment.id] || 0), 0);
    return gradeSum / compulsoryAssignments.length || 0;
  };

  const [rows, setRows] = useState([]);
  useEffect(() => {
    const newRows = groupGrades?.reduce((accumulator, item) => {
      const studentName = getLastNameAndNameLetter(item.student);
      const existingRow = accumulator.find((row) => row.studentName === studentName);

      if (existingRow) {
        existingRow[item.assignment.id] = item.grade;
      } else {
        const newRow = {
          id: item.id,
          studentName,
          [item.assignment.id]: item.grade,
        };
        accumulator.push(newRow);
      }

      return accumulator;
    }, []);

    setRows(newRows);
  }, [groupGrades]);

  /** --- sorting  ---- */
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  function getComparator(order, orderBy, assignmentNames) {
    if (orderBy === 'studentName') {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, 'studentName')
        : (a, b) => -descendingComparator(a, b, 'studentName');
    } else if (orderBy === 'averageGrade') {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, 'averageGrade')
        : (a, b) => -descendingComparator(a, b, 'averageGrade');
    } else {
      const assignment = assignmentNames.find((assignment) => assignment.id.toString() === orderBy);
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, 'assignment', assignment)
        : (a, b) => -descendingComparator(a, b, 'assignment', assignment);
    }
  }

  function descendingComparator(a, b, orderBy, assignment) {
    if (orderBy === 'assignment') {
      const aValue = a[assignment.id] || 0;
      const bValue = b[assignment.id] || 0;

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    } else if (orderBy === 'averageGrade') {
      const aValue = calculateAverageGrade(a, assignmentNames);
      const bValue = calculateAverageGrade(b, assignmentNames);

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    } else {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleClick = (property) => {
    const isStudentColumn = property === 'studentName';
    const isAverageGradeColumn = property === 'averageGrade';
    setOrder((prevOrder) => (orderBy === property && prevOrder === 'desc' ? 'asc' : 'desc'));
    setOrderBy(property);

    if (isStudentColumn || isAverageGradeColumn) {
      const sortedRows = stableSort(rows, getComparator(order, property, assignmentNames));
      setRows(sortedRows);
    } else {
      const sortedRows = stableSort(rows, getComparator(order, property, assignmentNames));
      setRows(sortedRows);
    }
  };

  return (
    <TableContainer
      sx={{
        width: '100%',
        overflow: 'auto',
        zIndex: 1,
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow sx={{ cursor: 'pointer' }}>
            <StickyTableCell isLeft>
              <TableSortLabel
                active={orderBy === 'studentName'}
                direction={orderBy === 'studentName' ? order : 'asc'}
                onClick={() => handleClick('studentName')}
              >
                {t('types.grade.student')}
              </TableSortLabel>
            </StickyTableCell>
            {assignmentNames.map((assignment) => (
              <Tooltip
                title={assignment.type.name === 'STEP_PROJECT' ? `Step project - ${assignment.name}` : assignment.name}
                key={assignment.id}
              >
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 600,
                    minWidth: 85,

                    backgroundColor: assignment.type.name === 'STEP_PROJECT' && theme.palette.primary.darkest,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === assignment.id.toString()}
                    direction={orderBy === assignment.id.toString() ? order : 'asc'}
                    onClick={() => handleClick(assignment.id.toString())}
                  >
                    {assignment.type.name === 'STEP_PROJECT' ? `SP${assignment.number}` : `HW${assignment.number}`}
                  </TableSortLabel>
                </TableCell>
              </Tooltip>
            ))}
            <StickyTableCell isRight align="center">
              <TableSortLabel
                active={orderBy === 'averageGrade'}
                direction={orderBy === 'averageGrade' ? order : 'asc'}
                onClick={() => handleClick('averageGrade')}
              >
                {t('types.grade.average')}
              </TableSortLabel>
            </StickyTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                '&:nth-of-type(even)': {
                  backgroundColor: theme.palette.primary.lightest,
                },
              }}
            >
              <StickyTableCell isLeft index={index}>
                {row.studentName}
              </StickyTableCell>
              {assignmentNames.map((assignment) => (
                <TableCell align="center" key={assignment.id}>
                  {row[assignment.id]}
                </TableCell>
              ))}

              <StickyTableCell isRight index={index} align="center">
                {calculateAverageGrade(row, assignmentNames).toFixed(2)}
              </StickyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(GradeBookTable);
