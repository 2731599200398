import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { userSelectors } from 'store/ducks/user';
import ActivityTracker from 'views/common/ActivityTracker';
import Error from 'views/common/Error';
import SideBar from 'views/common/Sidebar';
import MobileTopBar from 'views/common/topBar/MobileTopBar';
import TabletTopBar from 'views/common/topBar/TabletTopBar';

const PageLayout = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isStudent = currentRole === 'Student';
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const { t } = useTranslation();

  return (
    <>
      {/* --- Tracking student activity ---  */}
      {isStudent && <ActivityTracker />}

      <Box display="flex" width="100%" height="100%">
        {/* --- Aside menu  --- */}
        {isDesktop && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <SideBar />
          </ErrorBoundary>
        )}
        {isDesktop ? (
          <Container>
            <Outlet />
          </Container>
        ) : (
          <Container disableGutters>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              {/** --- Mobile view --- */}
              {isTablet ? <TabletTopBar /> : <MobileTopBar />}
            </ErrorBoundary>
            <Outlet />
          </Container>
        )}
      </Box>
    </>
  );
};

export default memo(PageLayout);
