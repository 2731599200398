import { createContext, useState, useContext, useEffect } from 'react';
import { getDataFromLS } from 'utils/helpers';

const ProgramsListViewContext = createContext();

export const useProgramsViewContext = () => {
  return useContext(ProgramsListViewContext);
};

export const ProgramsListViewContextProvider = (props) => {
  const view = getDataFromLS('programsListView');
  const [programsView, setProgramsView] = useState(view || 'row');

  const toggleView = () => {
    programsView === 'column' ? setProgramsView('row') : setProgramsView('column');
  };

  useEffect(() => {
    localStorage.setItem('programsListView', JSON.stringify(programsView));
  }, [programsView]);

  return (
    <ProgramsListViewContext.Provider value={{ programsView, toggleView }}>
      {props.children}
    </ProgramsListViewContext.Provider>
  );
};
