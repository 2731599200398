import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import { DESKTOP_VIEW } from 'utils/constants/common';
import {
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import SessionRecordsList from './components/record/SessionRecordsList';

const SessionRecordsPage = () => {
  const navigate = useNavigate();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { id, groupId, lessonId, moduleId } = useParams();

  const { t } = useTranslation();

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]) && !program;
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const sessions = selectedLesson?.sessions;
  
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const handleSelectRecord = (e, session) => {
    e.stopPropagation();
    groupId
      ? navigate(`/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/records/${session.id}`)
      : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/records/${session.id}`);
  };

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](groupId ? groupId : id));
  }, [currentRole, id, groupId, program]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.sessionRecord.namePlural')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={t('types.sessionRecord.namePlural')}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            padding={isDesktop ? 2 : 0}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
            sx={{ backgroundColor: isDesktop ? 'extra.white' : 'transparent' }}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar
                program={program}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                isAssignment
                hideProgramViewTopBar={isDesktop ? false : true}
                hideToggleView={isDesktop ? false : true}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <SessionRecordsList
                selectedLesson={selectedLesson}
                sessions={sessions}
                handleSelectRecord={handleSelectRecord}
              />
            </ErrorBoundary>
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(SessionRecordsPage);
