import { Box, Button, Stack } from '@mui/material';
import useModal from 'hooks/useModal';
import { memo } from 'react';
import AssignmentGradeCriteria from './AssignmentGradeCriteria';
import Modal from 'views/common/modal/Modal';
import AssignmentGradeCriteriaForm from './AssignmentGradeCriteriaForm';
import { TabPanel } from 'utils/tabsFunc';
import { useTranslation } from 'react-i18next';

const AssignmentDetails = ({ tabsValue, assignmentLoading, assignment }) => {
  const [modalOpen, openModal, closeModal] = useModal();

  const { t } = useTranslation();

  return (
    <>
      <TabPanel index={2} value={tabsValue}>
        <Stack>
          {!assignmentLoading && (
            <>
              <AssignmentGradeCriteria
                list={assignment.criteria}
                totalElements={assignment.criteria.length}
                isLoading={assignmentLoading}
                assignmentId={assignment.id}
                hideFooter
              />

              <>
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Button variant="contained" onClick={openModal}>
                    {t('types.assignment.addCriteria')}
                  </Button>
                </Box>
                <Modal title={t('types.assignment.addCriteria')} questionIcon open={modalOpen} onClose={closeModal}>
                  <AssignmentGradeCriteriaForm closeModal={closeModal} assignmentId={assignment.id} t={t} />
                </Modal>
              </>
            </>
          )}
        </Stack>
      </TabPanel>
    </>
  );
};

export default memo(AssignmentDetails);
