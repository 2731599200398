import { FieldArray } from 'formik';
import { Stack, Tooltip } from '@mui/material';
import FormikTextField from 'views/form/FormikTextField';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const FormikArrayCriteria = ({ name, values }) => {
  const { t } = useTranslation();
  return (
    <FieldArray
      name={name}
      render={() => (
        <>
          {values.map((option, index) => (
            <Stack key={index} direction="row" justifyContent="space-between" alignItems="center">
              <FormikTextField
                name={`${name}.${index}.name`}
                label={t('base.labels.name')}
                variant="standard"
                sx={{ width: '40%' }}
                disabled
              />
              <Tooltip title={values[index]?.description || t('base.tooltips.noDescription')}>
                <HelpOutlineIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
              </Tooltip>
              <FormikTextField
                name={`${name}.${index}.weight`}
                label={t('base.labels.weight')}
                variant="standard"
                sx={{ width: '20%' }}
                disabled
              />
              <FormikTextField
                name={`${name}.${index}.grade`}
                label={t('base.labels.grade')}
                variant="standard"
                sx={{ width: '20%' }}
              />
            </Stack>
          ))}
        </>
      )}
    />
  );
};

export default memo(FormikArrayCriteria);
