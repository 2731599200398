import { IconButton } from '@mui/material';
import { memo } from 'react';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useNavigate, useParams } from 'react-router-dom';


const EditMaterial = ({ isAssignment, material }) => {
  const navigate = useNavigate();
  const { id, moduleId, lessonId, priorityName } = useParams();

  const handleEditModalOpen = (event) => {
    event.stopPropagation();

    if (isAssignment) {
      navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments/${material.id}/edit`);
    } else {
      navigate(
        `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${material.id}/edit`
      );
    }
  };

  return (
    <>
      <IconButton
        sx={{
          borderRadius: '10px',
          transition: 'all 0.5s',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        }}
        onClick={(event) => handleEditModalOpen(event)}
      >
        <ModeEditOutlinedIcon
          fontSize="small"
          sx={{
            color: 'primary.main',
          }}
        />
      </IconButton>
    </>
  );
};

export default memo(EditMaterial);
