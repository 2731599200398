import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  MATERIALS_PRIORITY,
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import MaterialForm from './components/materials/MaterialForm';
import { dictionarySelectors } from 'store/ducks/dictionary';
import store from 'store';
import Loader from 'views/common/Loader';
import { uploadActions } from 'store/ducks/upload';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const AddEditMaterialPage = () => {
  const navigate = useNavigate();
  const { id, lessonId, moduleId, priorityName, materialType, materialId } = useParams();
  const [file, setFile] = useState(null);

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const enums = useSelector(dictionarySelectors.getEnums());

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]);
  const selectedModule = program?.modules?.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons?.find((lesson) => lesson.id === +lessonId);
  const selectedPriority = MATERIALS_PRIORITY.find((priority) => priority.urlParam === priorityName);
  const selectedMaterialType = enums?.lessonMaterialTypes?.find((type) => type.name === materialType?.toUpperCase());

  const selectedMaterial = selectedLesson?.materials.find((material) => material.id === +materialId);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const goBack = () => navigate(-1);

  const handleChooseFile = (event) => {
    store.dispatch(uploadActions.clearItem());
    setFile(event.target.files[0]);
  };

  const onDeleteFile = () => {
    store.dispatch(uploadActions.clearItem());
    setFile(null);
  };

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));
  }, [program, id, currentRole]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={selectedMaterial ? t('types.material.edit') : t('types.material.add')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            selectedMaterialPriorityLabel={
              selectedMaterial ? t('types.material.edit') : t('types.material.add')
            }
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'hidden' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}

          {!isProgramMenuOpen && (
            <ELementWrapper width="100%" height="100%" flex={3} padding={2}>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <MaterialForm
                  program={program}
                  selectedModule={selectedModule}
                  selectedLesson={selectedLesson}
                  selectedPriority={selectedPriority}
                  selectedMaterialType={selectedMaterialType}
                  material={selectedMaterial}
                  goBack={goBack}
                  file={file}
                  handleChooseFile={handleChooseFile}
                  onDeleteFile={onDeleteFile}
                  isLoading={programLoading}
                  t={t}
                />
              </ErrorBoundary>
            </ELementWrapper>
          )}
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(AddEditMaterialPage);
