import { Stack } from '@mui/material';
import GradesNavigation from './GradesNavigation';
import { ELementWrapper, ElementTitleBlackColor } from 'views/common/styledComponents';
import Loader from 'views/common/Loader';
import GradesList from './GradesList';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import NoAssignments from './NoAssignments';
import { useTranslation } from 'react-i18next';

const GradesMobileView = ({
  programs,
  allGradesLoading,
  programLoading,
  selectedProgram,
  averageGrade,
  assignments,
}) => {
  const { isProgramMenuOpen } = useProgramMobileMenuOpenContext();
  const hasFewPrograms = programs.length > 1;

  const { t } = useTranslation();

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={2} height="100%">
      {/* ---- Display menu only if student is assigned to more than one program --- */}
      {isProgramMenuOpen && <GradesNavigation programs={programs} />}

      <ELementWrapper
        width="100%"
        height="100%"
        sx={{ overflowY: 'auto', backgroundColor: 'transparent' }}
        justifyContent="flex-start"
        flex={1}
        display={isProgramMenuOpen ? 'none' : 'flex'}
      >
        {(allGradesLoading || programLoading) && <Loader />}
        {(!allGradesLoading || !programLoading) && (
          <>
            <Stack
              mx={3}
              my={2}
              mt={hasFewPrograms ? 0 : 2}
              direction="column"
              alignItems="center"
              justifyContent={'space-between'}
              gap={2}
            >
              <ElementTitleBlackColor
                sx={(theme) => ({
                  display: programs?.length > 1 ? 'none' : 'flex',
                  [theme.breakpoints.down('sm')]: { fontWeight: 400, fontSize: 16 },
                })}
              >
                {selectedProgram?.name} {t('types.grade.namePlural').toLowerCase()}
              </ElementTitleBlackColor>
              {averageGrade && (
                <Stack direction="row" gap={1} ml={hasFewPrograms ? 3 : 0}>
                  <ElementTitleBlackColor>{t('types.grade.average')}:</ElementTitleBlackColor>
                  <ElementTitleBlackColor sx={{ color: 'success.main' }}>{averageGrade}</ElementTitleBlackColor>
                </Stack>
              )}
            </Stack>
            {assignments?.length === 0 ? (
              <NoAssignments />
            ) : (
              <GradesList grades={assignments ? assignments : []} program={selectedProgram} />
            )}
          </>
        )}
      </ELementWrapper>
    </Stack>
  );
};

export default GradesMobileView;
