import { memo, useEffect, useState } from 'react';
import { Stack, useMediaQuery } from '@mui/material';

import HeaderList from 'views/common/HeaderList';
import { CalendarBox, ChartBox } from './components';

import { useSelector } from 'react-redux';
import store from 'store';
import { studentSelectors, studentThunks } from 'store/ducks/student';
import { userSelectors } from 'store/ducks/user';
import { PROGRAMS_FETCH_BY_ROLES, PROGRAMS_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { getCurrentDate, getStartAndEndOfMonth } from 'utils/helpers';
import { DESKTOP_VIEW_HEIGHT, TABLET_HEIGHT, DESKTOP_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const DashboardPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const calendarData = useSelector(studentSelectors.getCalendar());
  const calendarDataLoading = useSelector(studentSelectors.calendarLoading());

  const nearestLessons = useSelector(studentSelectors.getCalendarNearest());
  const nearestLessonsLoading = useSelector(studentSelectors.calendarNearestLoading());

  const [selectedMonth, setSelectedMonth] = useState(getCurrentDate());

  const { startOfMonth, endOfMonth } = getStartAndEndOfMonth(selectedMonth);

  const query = `?from=${startOfMonth}&to=${endOfMonth}`;

  const progress = programs.reduce((acc, cur) => acc + cur.completion, 0) || 0;

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(studentThunks.fetchActivity());
    store.dispatch(studentThunks.fetchActivityTotal());
    store.dispatch(studentThunks.fetchCalendarNearest());
  }, []);

  useEffect(() => {
    store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]);

  useEffect(() => {
    store.dispatch(studentThunks.fetchCalendar(query));
  }, [query]);

  const handleMonthChange = (newMonth) => {
    setSelectedMonth(newMonth);
  };

  return (
    <Stack
      gap={isDesktop && 2}
      mb={1}
      sx={(theme) => ({
        height: DESKTOP_VIEW_HEIGHT,
        [theme.breakpoints.between('sm', 'lg')]: { height: TABLET_HEIGHT },
        [theme.breakpoints.down('sm')]: { height: '100%' },
      })}
    >
      {isDesktop && <HeaderList title={t('types.dashboard.name')} />}
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={2}
        height="100%"
        sx={(theme) => ({
          overflowY: 'auto',
          [theme.breakpoints.down('lg')]: { flexDirection: 'column', mt: 2 },
        })}
      >
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <ChartBox progress={progress} />
        </ErrorBoundary>
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <CalendarBox
            nearestLessons={nearestLessons}
            calendarData={calendarData}
            calendarDataLoading={calendarDataLoading}
            nearestLessonsLoading={nearestLessonsLoading}
            handleMonthChange={handleMonthChange}
          />
        </ErrorBoundary>
      </Stack>
    </Stack>
  );
};

export default memo(DashboardPage);
