import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { Box, Divider, Stack, useMediaQuery } from '@mui/material';

import { ELementWrapper, ElementTitlePurpleColor } from 'views/common/styledComponents';
import Loader from 'views/common/Loader';
import LessonCard from './LessonCard';
import { MOBILE_VIEW } from 'utils/constants/common';

const UpcomingLessons = ({ nearestLessonsLoading, nearestLessons }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        overflowY: 'hidden',
        [theme.breakpoints.between('sm', 'lg')]: { flex: 1 },
      })}
    >
      <ElementTitlePurpleColor sx={{ mt: 2 }}>{t('types.dashboard.upcomingLessons')}</ElementTitlePurpleColor>
      <ELementWrapper
        sx={{
          height: 'calc(100% - 51px)',
          overflowY: 'auto',
          mb: isMobile ? 2 : 0,
        }}
      >
        <Stack>
          {nearestLessonsLoading ? (
            <Loader />
          ) : (
            <Stack
              divider={<Divider flexItem sx={{ mx: 1 }} />}
              px={1}
              sx={(theme) => ({
                gap: 1,
                [theme.breakpoints.down('sm')]: { gap: 0 },
              })}
            >
              {nearestLessons?.map((lesson) => (
                <LessonCard key={lesson.id} lesson={lesson} />
              ))}
            </Stack>
          )}
        </Stack>
      </ELementWrapper>
    </Box>
  );
};

export default memo(UpcomingLessons);
