import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const trainerSlice = createSlice({
  name: 'trainerSlice',
  initialState: {
    groups: loadDataInitialState([]),
    groupProgram: loadDataInitialState(null),
    groupGrades: loadDataInitialState([]),
    calendar: loadDataInitialState([]),
    calendarNearest: loadDataInitialState([]),
  },
  reducers: {},
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchGroups, 'groups');
    loadDataExtraReducer(builder, thunks.fetchGroupProgram, 'groupProgram');
    loadDataExtraReducer(builder, thunks.fetchGroupGrades, 'groupGrades');
    loadDataExtraReducer(builder, thunks.fetchCalendar, 'calendar');
    loadDataExtraReducer(builder, thunks.fetchCalendarNearest, 'calendarNearest');
  },
});

export const {} = trainerSlice.actions;
export default trainerSlice.reducer;
