import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchEnums = createAsyncThunk('dictionary/fetchEnums', () => {
  return api.get('/dictionaries/enums');
});

const fetchLanguages = createAsyncThunk('dictionary/fetchLanguages', () => {
  return api.get('/dictionaries/languages');
});

export default {
  fetchEnums,
  fetchLanguages,
};
