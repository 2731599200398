import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';

import Loader from 'views/common/Loader';
import HeaderList from 'views/common/HeaderList';
import ProgramsList from './components/programs/ProgramsList';

import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import { useNavigate } from 'react-router-dom';
import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import ProgramsListTopBar from '../../common/ProgramsListTopBar';
import { Stack, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import NoProgram from './components/programs/NoProgram';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const ProgramsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const isLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]);

  const [sortBy, setSortBy] = useState('Default');
  const [sortedPrograms, setSortedPrograms] = useState([]);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  useEffect(() => {
    const sortPrograms = () => {
      switch (sortBy) {
        case 'A-Z':
          return [...programs].sort((a, b) => a.name.localeCompare(b.name));
        case 'Z-A':
          return [...programs].sort((a, b) => b.name.localeCompare(a.name));
        default:
          return programs;
      }
    };

    const sorted = sortPrograms();
    setSortedPrograms(sorted);
  }, [sortBy, programs]);

  const handleCardClick = (programId) => {
    navigate(`/programs/${programId}`);
  };

  useEffect(() => {
    store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.program.namePlural')}
            setSortedPrograms={setSortedPrograms}
            isDisplaySearchBar
            programs={programs}
          />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile setSortedPrograms={setSortedPrograms} isDisplaySearchBar programs={programs} />
        </ErrorBoundary>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <PageViewPaperVioletLight>
          {programs.length !== 0 ? (
            <Stack height="100%">
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ProgramsListTopBar sortBy={sortBy} setSortBy={setSortBy} hideTopBar={isMobile} />
              </ErrorBoundary>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ProgramsList programs={sortedPrograms} handleCardClick={handleCardClick} />
              </ErrorBoundary>
            </Stack>
          ) : (
            <NoProgram />
          )}
        </PageViewPaperVioletLight>
      )}
    </PageWrapper>
  );
};

export default memo(ProgramsPage);
