import { memo, useEffect, useState } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Area, AreaChart } from 'recharts';
import { ElementTitleBlackColor } from 'views/common/styledComponents';
import { useSelector } from 'react-redux';
import { studentSelectors } from 'store/ducks/student';
import { DateTime } from 'luxon';
import { getCurrentDate } from 'utils/helpers';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const WeeklyProgressChart = () => {
  const [chartData, setChartData] = useState([]);
  const activity = useSelector(studentSelectors.getActivity());
  const theme = useTheme();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  useEffect(() => {
    const currentDate = getCurrentDate();

    /* --- Calculate the start and end dates --- */
    const lastWeekStartDate = currentDate.minus({ days: 7 });
    const lastWeekEndDate = currentDate;

    /* --- Filter data for the last week --- */
    const filteredData = activity.filter((entry) => {
      const entryDate = DateTime.fromFormat(entry.date, 'yyyy-MM-dd');
      return entryDate >= lastWeekStartDate && entryDate <= lastWeekEndDate;
    });

    /* --- Transform the filtered data into the format required by the chart --- */
    const transformedData = filteredData.map((entry) => ({
      name: DateTime.fromISO(entry.date).toFormat('EEE'), // Format to 'Mon', 'Tue', etc.
      progress: entry.minutes,
    }));

    setChartData(transformedData);
  }, [activity]);

  return (
    <Stack
      height="100%"
      flex={0.8}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: { maxHeight: 340 },
        [theme.breakpoints.up('lg')]: { maxHeight: 750 },
        [theme.breakpoints.down('lg')]: { maxHeight: 300 },
      })}
    >
      <ElementTitleBlackColor my={isDesktop ? 3 : 1}>{t('types.dashboard.weeklyProgress')}</ElementTitleBlackColor>
      <ResponsiveContainer height={'100%'}>
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="colorProgress" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
              <stop stopColor={theme.palette.dashboardChart.start} />
              <stop offset="1" stopColor={theme.palette.dashboardChart.finish} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" style={{ fontSize: isDesktop ? 16 : 14 }} />
          <YAxis style={{ fontSize: isDesktop ? 16 : 14 }} />
          <Tooltip />
          <Area type="monotone" dataKey="progress" stroke="none" fill="url(#colorProgress)" />
        </AreaChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default memo(WeeklyProgressChart);
