import { Button, Card, CardContent, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { MATERIAL_TYPE_ICONS } from 'utils/constants/program';

const SessionRecordCard = ({ recordName, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Grid item width="100%" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { '&:first-of-type': { pt: 0 } } })}>
      <Card
        sx={(theme) => ({
          borderRadius: isDesktop ? 1 : 2,
          boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10)',
          cursor: isDesktop ? 'pointer' : '',
          position: 'relative',
          border: isDesktop ? '1px solid' : 'none',
          borderColor: 'primary.lighter',
          transition: 'all 0.5s',
          [theme.breakpoints.up('sm')]: {
            '&:hover': {
              backgroundColor: 'primary.lighter',
              p: 0,
            },
          },
        })}
        onMouseEnter={isDesktop ? handleMouseEnter : null}
        onMouseLeave={isDesktop ? handleMouseLeave : null}
        onClick={isDesktop ? onClick : null}
      >
        <CardContent>
          <Stack gap={isDesktop ? 1 : 2}>
            <Stack direction={isDesktop ? 'column' : 'row'} gap={1}>
              {MATERIAL_TYPE_ICONS(theme)['YOUTUBE'](isDesktop ? 30 : 22)}
              <Typography variant={isDesktop ? 'body2' : 'capture'} color="text.light" alignSelf="center">
                {t('types.sessionRecord.name')}
              </Typography>
            </Stack>
            {/** ---- Title for Mobile View --- */}
            {!isDesktop && (
              <Typography variant="body1" color="text.primary">
                {recordName}
              </Typography>
            )}
            {/** ---- Button for Mobile view --- */}
            {!isDesktop && (
              <Button sx={{ width: '100%', fontSize: 14, minHeight: 20, py: 1, fontWeight: 700 }} onClick={onClick}>
                {t('base.buttons.view')}
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
      {/** ---- Title for Desktop view --- */}
      {isDesktop && (
        <Typography
          variant="body2"
          fontWeight={isHovered ? 600 : 400}
          color="text.main"
          textAlign="center"
          mt={0.5}
        >
          {recordName}
        </Typography>
      )}
    </Grid>
  );
};

export default memo(SessionRecordCard);
