import { memo } from 'react';

import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';

const Page404 = (props) => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      title={t('page404.title')}
      message={t('page404.message')}
      {...props}
    />
  );
};

export default memo(Page404);
