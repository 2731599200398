import { memo } from 'react';
import { Divider, useMediaQuery } from '@mui/material';

import WeeklyProgressChart from './WeeklyProgressChart';
import ProgressBar from './ProgressBar';
import KeyIndicatorsBar from './KeyIndicatorsBar';
import { ELementWrapper } from 'views/common/styledComponents';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { useTranslation } from 'react-i18next';

const ChartBox = ({ progress }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  return (
    <ELementWrapper
      divider={<Divider flexItem />}
      sx={{
        px: 2,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'space-between',
      }}
      flex={0.7}
    >
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <KeyIndicatorsBar />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <WeeklyProgressChart />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <ProgressBar
          location="dashboard"
          progress={progress}
          height={isDesktop ? 32 : 12}
          marginY={isDesktop ? 2 : 1}
          border="1px solid"
          borderColor="primary.main"
          backgroundColor="extra.white"
        />
      </ErrorBoundary>
    </ELementWrapper>
  );
};

export default memo(ChartBox);
