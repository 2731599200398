import { memo } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { ELementWrapper } from 'views/common/styledComponents';
import AssignmentNavItem from './AssignmentNavItem';
import Loader from 'views/common/Loader';
import { useTranslation } from 'react-i18next';

const SubmissionsNavigation = ({ groups, assignments, assignmentsLoading }) => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const { t } = useTranslation();

  const handleChange = (event) => {
    navigate(`/assignments/groups/${event.target.value.id}`);
  };

  return (
    <ELementWrapper
      sx={{
        minHeight: 64,
        overflowY: 'scroll',
        flex: 1,
        height: '100%',
        p: 2,
      }}
    >
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="group-select">{t('types.submission.navigation.group')}</InputLabel>
        <Select
          labelId="group-select"
          value={groupId ? groups.find((group) => group.id === +groupId) : ''}
          label={t('types.submission.navigation.group')}
          onChange={handleChange}
        >
          {groups?.map((group) => (
            <MenuItem key={group.id} value={group}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack sx={{ overflowY: 'auto', my: 2 }} height="100%">
        {assignmentsLoading && <Loader />}
        {assignments?.length === 0 && !assignmentsLoading && (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Typography variant="h3" color="text.primary">
              {t('messages.warning.noAssignments')}
            </Typography>
          </Stack>
        )}
        {assignments &&
          !assignmentsLoading &&
          assignments?.map((assignment) => <AssignmentNavItem key={assignment.id} assignment={assignment} />)}
      </Stack>
    </ELementWrapper>
  );
};

export default memo(SubmissionsNavigation);
