import { Button } from '@mui/material';
import { Form, withFormik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import store from 'store';
import { assignmentThunks } from 'store/ducks/assignment';
import { FormColumnWrapper, FormWrapper } from 'views/common/styledComponents';
import FormikTextField from 'views/form/FormikTextField';

const AssignmentGradeCriteriaForm = ({ gradeCriteria }) => {
  const { t } = useTranslation();

  return (
    <Form>
      <FormWrapper>
        <FormColumnWrapper sx={{ minWidth: 350, backgroundColor: 'transparent', m: 2, pt: 3 }}>
          <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.enterName')} />
          <FormikTextField
            name="weight"
            label={t('base.labels.weight')}
            placeholder={t('base.placeholders.enterWeight')}
          />
          <FormikTextField
            name="description"
            label={t('base.labels.description')}
            placeholder={t('base.placeholders.enterDescription')}
          />
          <Button type="submit" variant="contained" sx={{ maxWidth: 150, alignSelf: 'center', mt: 3 }}>
            {gradeCriteria ? t('base.buttons.change') : t('base.buttons.add')}
          </Button>
        </FormColumnWrapper>
      </FormWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ gradeCriteria = {} }) => ({
      ...gradeCriteria,
      id: gradeCriteria?.id,
      name: gradeCriteria?.name || '',
      weight: gradeCriteria?.weight || '',
      description: gradeCriteria?.description || '',
    }),

    handleSubmit: (values, { props, setSubmitting }) => {
      const assignmentGradeCriteria = { grade: values, assignmentId: props.assignmentId };
      props.gradeCriteria
        ? store
            .dispatch(assignmentThunks.updateAssignmentGradeCriteria(assignmentGradeCriteria))
            .then((res) => {
              !res.error &&
                (toast.success(props.t('messages.success.toast.criteriaUpdate')),
                setSubmitting(false),
                props.closeModal());
            })
            .finally(setSubmitting(false))
        : store
            .dispatch(assignmentThunks.createAssignmentGradeCriteria(assignmentGradeCriteria))
            .then((res) => {
              !res.error && toast.success(props.t('messages.success.toast.criteriaAdd')),
                setSubmitting(false),
                props.closeModal();
            })
            .finally(setSubmitting(false));
    },
    enableReinitialize: true,
  })(AssignmentGradeCriteriaForm)
);
