import { Divider, Popover, Stack, Typography } from '@mui/material';
import { ReactComponent as JoinIcon } from '../../../../theme/icons/join-icon.svg';
import { GradientButton } from 'views/common/styledComponents';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const EventPopup = ({ event, onClose, isOpen, anchorEl }) => {
  /** ---  Custom popover position in order to place it within the calendar size --- */
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const { t } = useTranslation();

  useEffect(() => {
    const calculatePopoverPosition = () => {
      if (!anchorEl) return;

      const parentRect = anchorEl.getBoundingClientRect();
      const popoverContent = document.getElementById('popover-content');

      if (popoverContent) {
        const popoverRect = popoverContent.getBoundingClientRect();
        const popoverWidth = popoverRect.width;

        if (parentRect.right + popoverWidth <= window.innerWidth) {
          setPopoverPosition({ top: parentRect.bottom, left: parentRect.left });
        } else {
          setPopoverPosition({ top: parentRect.bottom, left: window.innerWidth - popoverWidth });
        }
      }
    };

    if (isOpen) {
      calculatePopoverPosition();
    }
  }, [isOpen, anchorEl]);

  const startDate = DateTime.fromJSDate(event.start);
  const endDate = DateTime.fromJSDate(event.end);

  const sessionDate = `${startDate
    .setLocale(i18n.language === 'UA' ? 'UK' : i18n.language)
    .toFormat('EEEE, dd LLLL yyyy')}`;
  const sessionTime = `${startDate.toFormat('HH:mm')} - ${endDate.toFormat('HH:mm')}`;

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      style={{ top: popoverPosition.top, left: popoverPosition.left }}
    >
      <Stack id="popover-content" sx={{ p: 2 }} spacing={2} width="100%" maxWidth="320px" minWidth="320px">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" fontWeight={600} color={'text.dark'} sx={{ whiteSpace: 'nowrap' }}>
            {event.group}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="body2" color={'text.main'}>
            {event.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" color={'text.main'}>
            {sessionDate}
          </Typography>
          <Typography variant="body2" color={'text.dark'}>
            {sessionTime}
          </Typography>
        </Stack>
        {event.link && (
          <GradientButton
            startIcon={<JoinIcon />}
            href={event.link}
            target="_blank"
            sx={{ color: 'extra.white', width: '100%', fontSize: 14 }}
          >
            {t('base.buttons.joinMeet')}
          </GradientButton>
        )}
      </Stack>
    </Popover>
  );
};

export default EventPopup;
