import { useEffect } from 'react';
import HeaderList from 'views/common/HeaderList';
import SubmissionsNavigation from './components/SubmissionsNavigation';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PROGRAMS_FETCH_BY_ROLES, PROGRAMS_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { userSelectors } from 'store/ducks/user';
import { Stack, useMediaQuery } from '@mui/material';
import { mentorSelectors, mentorThunks } from 'store/ducks/mentor';
import store from 'store';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { PageWrapper } from 'views/common/styledComponents';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const GroupSubmissionsPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { groupId } = useParams();
  const groups = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const assignments = useSelector(mentorSelectors.getGroupAssignments());
  const assignmentsLoading = useSelector(mentorSelectors.groupAssignmentsLoading());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(mentorThunks.fetchGroupAssignments(groupId));
  }, [groupId]);

  useEffect(() => {
    groups.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole, groups]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.submission.groupSubmissions')} />
        </ErrorBoundary>
      )}
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        height="100%"
        sx={{ overflowY: 'hidden' }}
        mt={isDesktop ? 0 : 2}
      >
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <SubmissionsNavigation groups={groups} assignments={assignments} assignmentsLoading={assignmentsLoading} />
        </ErrorBoundary>
      </Stack>
    </PageWrapper>
  );
};

export default GroupSubmissionsPage;
