import { Box, Button, LinearProgress } from '@mui/material';
import useModal from 'hooks/useModal';
import { memo, useState } from 'react';
import Modal from 'views/common/modal/Modal';
import AssignmentGradeCriteriaForm from './AssignmentGradeCriteriaForm';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const AssignmentGradeCriteria = ({ list, totalElements, isLoading, assignmentId, hideFooter }) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [gradeCriteria, setGradeCriteria] = useState(null);

  const { t } = useTranslation();

  const handleClick = (id) => {
    const criteria = list.find((option) => option.id === id);
    criteria && setGradeCriteria(criteria);
    openModal();
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            variant="text"
            sx={{ px: 0, fontSize: 14, color: 'text.main', minWidth: '100%', justifyContent: 'left' }}
            onClick={() => handleClick(id)}
          >
            {id}
          </Button>
        );
      },
    },
    { field: 'name', headerName: t('types.assignment.gradeCriteria.name'), filterable: false, flex: 0.3 },
    { field: 'weight', headerName: t('types.assignment.gradeCriteria.weight'), filterable: false, flex: 0.2 },
    { field: 'description', headerName: t('types.assignment.gradeCriteria.desc'), filterable: false, flex: 0.5 },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          my: 2,
        }}
      >
        <DataGrid
          rows={list}
          rowCount={totalElements}
          columns={columns}
          loading={isLoading}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
          hideFooter={hideFooter}
        />
      </Box>

      <Modal title={t('types.assignment.editCriteria')} questionIcon open={modalOpen} onClose={closeModal}>
        <AssignmentGradeCriteriaForm
          closeModal={closeModal}
          gradeCriteria={gradeCriteria}
          assignmentId={assignmentId}
          t={t}
        />
      </Modal>
    </>
  );
};

export default memo(AssignmentGradeCriteria);
