import { memo } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import UpcomingLessons from './UpcomingLessons';
import Error from 'views/common/Error';
import SmallCalendar from './SmallCalendar';

const CalendarBox = ({
  nearestLessons,
  calendarData,
  calendarDataLoading,
  nearestLessonsLoading,
  handleMonthChange,
}) => {
  const isTablet = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));

  const { t } = useTranslation();

  return (
    <Stack
      direction={isTablet ? 'row' : 'column'}
      gap={isTablet && 2}
      flex={0.3}
      sx={(theme) => ({
        height: isTablet ? '50%' : '100%',
        [theme.breakpoints.down('lg')]: { width: '100%' },
      })}
    >
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <SmallCalendar
          calendarData={calendarData}
          calendarDataLoading={calendarDataLoading}
          handleMonthChange={handleMonthChange}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <UpcomingLessons nearestLessons={nearestLessons} nearestLessonsLoading={nearestLessonsLoading} />
      </ErrorBoundary>
    </Stack>
  );
};

export default memo(CalendarBox);
