import { Box, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import { ProgressBar } from 'views/pages/dashboard/components';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { memo, useState } from 'react';
import { useProgramsViewContext } from 'services/context/programsListViewContext';
import { MOBILE_VIEW } from 'utils/constants/common';

const ProgramCard = ({ program, group, onClick }) => {
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const [isHovered, setIsHovered] = useState(false);
  const { programsView } = useProgramsViewContext();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid item sm={12} md={programsView === 'row' ? 12 : 6} width="100%">
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10)',
          position: 'relative',
          p: 1,
          cursor: 'pointer',
          transition: 'all 0.3s',
          '&:hover': { backgroundColor: 'primary.lighter' },
          ...(programsView === 'row' && {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 4,
          }),
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {program?.image || program?.program?.image ? (
          <CardMedia
            component="img"
            image={program.image || program.program.image}
            alt={`Program ${program.name} img`}
            sx={{
              borderRadius: 3,
              width: '100%',
              height: isMobile ? '120px' : '140px',
              transition: 'all 0.3s',
              filter: isHovered ? 'brightness(75%)' : 'brightness(100%)',
              ...(programsView === 'row' && { flex: 1 }),
            }}
          />
        ) : (
          <CardContent
            sx={{
              height: '140px',
              border: '1px solid',
              borderColor: 'text.light',
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'all 0.3s',
              backgroundColor: isHovered ? 'rgba(255, 255, 255, 0.60)' : 'transparent',
              ...(programsView === 'row' && { flex: 1 }),
            }}
          >
            <ImageOutlinedIcon sx={{ color: 'text.light' }} />
            <Typography variant="body2" color="text.light">
              {' '}
              No image
            </Typography>
          </CardContent>
        )}

        <CardContent
          sx={{
            textAlign: 'center',
            px: 1,
            pt: 1,
            pb: 0,
            '&:last-child': { pb: 0 },
            ...(programsView === 'row' && { flex: 1 }),
          }}
        >
          <Typography variant="h6" color="text.main" fontSize={isMobile ? 14 : 18}>
            {group ? group.name : program.name}
          </Typography>
        </CardContent>

        {/* --- PROGRESS BAR. DISPLAYS ONLY FOR STUDENTS AND TRAINER / MENTOR --- */}
        {currentRole === 'Student' && (
          <Box
            sx={{ ...(programsView === 'row' && { position: 'absolute', bottom: 0, left: 0, width: '100%', px: 3 }) }}
          >
            <ProgressBar
              location="programs"
              activeColor={'success.main'}
              progress={program?.completion}
              height={8}
              marginY={0}
              border="none"
              borderColor="success.light"
              backgroundColor="success.light"
            />
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default memo(ProgramCard);
