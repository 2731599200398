const getGroups = () => (state) => state.trainer.groups.data;
const groupsLoading = () => (state) => state.trainer.groups.isLoading;
const getGroupsError = () => (state) => state.trainer.groups.error;

const getGroupProgram = () => (state) => state.trainer.groupProgram.data;
const groupProgramLoading = () => (state) => state.trainer.groupProgram.isLoading;
const getGroupProgramError = () => (state) => state.trainer.groupProgram.error;

const getGroupGrades = () => (state) => state.trainer.groupGrades.data;
const groupGradesLoading = () => (state) => state.trainer.groupGrades.isLoading;
const getGroupGradesError = () => (state) => state.trainer.groupGrades.error;

const getCalendar = () => (state) => state.trainer.calendar.data;
const calendarLoading = () => (state) => state.trainer.calendar.isLoading;
const getCalendarError = () => (state) => state.trainer.calendar.error;

const getCalendarNearest = () => (state) => state.trainer.calendarNearest.data;
const calendarNearestLoading = () => (state) => state.trainer.calendarNearest.isLoading;
const getCalendarNearestError = () => (state) => state.trainer.calendarNearest.error;


export default {
  getGroups,
  groupsLoading,
  getGroupsError,
  getGroupProgram,
  groupProgramLoading,
  getGroupProgramError,
  getGroupGrades,
  groupGradesLoading,
  getGroupGradesError,
  getCalendar,
  calendarLoading,
  getCalendarError,
  getCalendarNearest,
  calendarNearestLoading,
  getCalendarNearestError,
};
