import { memo, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Fade, useTheme } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ADD_MATERIAL_MENU_OPTIONS } from 'utils/constants/program';

import { useNavigate, useParams } from 'react-router-dom';

const AddMaterialMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { id, moduleId, lessonId, priorityName } = useParams();
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSelectMenuItem = (event, materialType) => {
    event.stopPropagation();

    handleMenuClose();
    if (materialType !== 'ASSIGNMENT') {
      navigate(
        `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/add/${materialType.toLowerCase()}`
      );
    } else navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments/add`);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = ADD_MATERIAL_MENU_OPTIONS(theme).map((menuItem) => (
    <MenuItem
      key={menuItem.title}
      sx={{ color: 'primary.main', px: 3 }}
      onClick={(event) => handleSelectMenuItem(event, menuItem.name)}
    >
      {menuItem.icon} {menuItem.title}
    </MenuItem>
  ));

  return (
    <Box>
      {menuItems.length > 0 && (
        <>
          <IconButton
            id="createMaterial"
            sx={{ color: 'primary.main' }}
            aria-controls={open ? 'addMaterialMenu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuOpen}
          >
            <AddCircleOutlineIcon />
          </IconButton>

          <Menu
            id="addMaterialMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
            MenuListProps={{
              'aria-labelledby': 'createMaterial',
            }}
          >
            {menuItems}
          </Menu>
        </>
      )}
    </Box>
  );
};
export default memo(AddMaterialMenu);
