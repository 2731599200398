import { Button, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../../theme/icons/deleteIcon.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const FileUploader = ({
  file,
  handleChooseFile,
  onDeleteFile,
  fileSize,
  filesAccept,
  isLoading,
  title,
  isUploaded,
}) => {
  //isUploaded - trigger to display the icon 'done' in case if student has already uploaded a file.
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const theme = useTheme();

  return (
    <>
      <Stack width="100%">
        {title && (
          <Typography variant="body2" pl={2} pb={1} color="text.main">
            {title}
          </Typography>
        )}

        <Stack direction="row" gap={theme.spacing(2)} width="100%">
          <TextField
            variant="outlined"
            value={file?.name || ''}
            placeholder={t('base.placeholders.chooseFile')}
            sx={{ flex: 1 }}
            InputProps={{
              readOnly: true,
              endAdornment: <>{isUploaded && <CheckCircleOutlineIcon sx={{ color: 'success.main' }} />}</>,
            }}
            disabled={isLoading}
          />
          {file && (
            <IconButton
              sx={{
                borderRadius: '10px',
                transition: 'all 0.5s',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
              }}
              onClick={onDeleteFile}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <label htmlFor="upload-image">
            <Button
              sx={{ minWidth: 0, fontSize: isMobile ? 14 : 16, minHeight: isMobile ? 40 : 44 }}
              variant="contained"
              component="span"
              disabled={isLoading}
            >
              {t('base.buttons.browse')}
            </Button>
            <input
              id="upload-image"
              hidden
              accept={filesAccept}
              type="file"
              onChange={handleChooseFile}
              size={fileSize}
            />
          </label>
        </Stack>
      </Stack>
    </>
  );
};

export default FileUploader;
