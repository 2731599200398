import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, useMediaQuery } from '@mui/material';
import { memo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkSubmission from './LinkSubmission';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { PROGRAM_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { useParams } from 'react-router-dom';
import { studentSelectors } from 'store/ducks/student';
import store from 'store';
import { uploadActions } from 'store/ducks/upload';
import filters from 'filters';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const AssignmentSubmission = ({ assignment }) => {
  const { assignmentId } = useParams();
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const studentGrade = useSelector(studentSelectors.getGrade());

  const { t } = useTranslation();

  const [file, setFile] = useState(null);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleChooseFile = (event) => {
    store.dispatch(uploadActions.clearItem());
    setFile(event.target.files[0]);
  };

  const onDeleteFile = () => {
    store.dispatch(uploadActions.clearItem());
    setFile(null);
  };

  return (
    <>
      <Stack
        sx={{
          mt: 1,
        }}
        gap={1}
      >
        <Accordion sx={{ padding: isDesktop ? '8px 16px' : '8px 8px' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="link-content" id="link-header">
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Typography color="text.main" fontSize={isMobile ? 12 : 14} fontWeight={600}>
               {t('types.assignment.submitYourAssignment')}
              </Typography>
              <Typography color="text.main" fontSize={isMobile ? 12 : 14}>
                {t('types.assignment.deadline')}: {filters.date(assignment?.deadline, 'dd.MM.yyyy')}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <LinkSubmission
              groupId={program?.group?.id}
              assignmentId={+assignmentId}
              submission={studentGrade}
              file={file}
              handleChooseFile={handleChooseFile}
              onDeleteFile={onDeleteFile}
              t={t}
            />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </>
  );
};

export default memo(AssignmentSubmission);
