import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoGroup = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" height="100%" gap={2}>
      <Typography variant="h3">{t('messages.warning.noGroup.title')}</Typography>
      <Typography variant="h5">{t('messages.warning.noGroup.message')}</Typography>
    </Stack>
  );
};

export default NoGroup;
