import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'store/utils/loadDataInitialState';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';

const uploadSlice = createSlice({
  name: 'uploadSlice',
  initialState: {
    fileId: loadDataInitialState('', false),
    image: loadDataInitialState(''),
  },
  reducers: {
    clearItem(state) {
      state.fileId = { ...state.fileId, data: '' };
    },
    clearImage(state) { 
      state.image = ''
    }
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.uploadFile, 'fileId');
    loadDataExtraReducer(builder, thunks.uploadImage, 'image');
  },
});

export const { clearItem, clearImage } = uploadSlice.actions;
export default uploadSlice.reducer;
