import i18n from 'i18n/i18n';
import { DateTime } from 'luxon';

const CustomMonthHeader = ({ date }) => {

  const isUa = i18n.language === 'UA';
  const incomingDate = new Date(date);
  const localeDate = DateTime.fromJSDate(incomingDate).setLocale(isUa ? 'UK' : i18n.language);

  const fullNameOfDay = localeDate.toFormat('EEEE');
  const formattedNameOfDay = fullNameOfDay.at(0).toUpperCase() + fullNameOfDay.slice(1).toLowerCase();

  return (
    <span role="columnheader" aria-sort="none">
      {formattedNameOfDay}
    </span>
  );
};

export default CustomMonthHeader;
