import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoProgram = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" alignItems="center" justifyContent="center" height="100%" gap={2}>
      <Typography variant="h3">{t('messages.warning.noProgram.title')}</Typography>
      <Typography variant="h5">{t('messages.warning.noProgram.message')}</Typography>
    </Stack>
  );
};

export default NoProgram;
