import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceGrade } from './slice';

/** --- Fetch groups to which mentor is assigned --- */
const fetchGroups = createAsyncThunk('mentor/fetchGroups', async () => {
  return api.get(`/mentors/groups`);
});

/** --- Fetch group program  --- */
const fetchGroupProgram = createAsyncThunk('mentor/fetchGroupProgram', async (groupId) => {
  return api.get(`/mentors/groups/${groupId}/program`);
});

/** --- Fetch group assignments  --- */
const fetchGroupAssignments = createAsyncThunk('mentor/fetchGroupAssignments', async (groupId) => {
  return api.get(`/mentors/groups/${groupId}/assignments`);
});

/** --- Fetch assignment submissions  --- */
const fetchAssignmentSubmissions = createAsyncThunk('mentor/fetchAssignmentSubmissions', async (data) => {
  return api.get(`/mentors/groups/${data.groupId}/assignments/${data.assignmentId}/grades`);
});

/** --- Fetch grade  --- */
const fetchGrade = createAsyncThunk('mentor/fetchGrade', async (gradeId) => {
  return api.get(`/mentors/grades/${gradeId}`);
});

/** --- Update grade  --- */
const updateGrade = createAsyncThunk('mentor/updateGrade', async (data, { dispatch }) => {
  return api.put(`/mentors/grades/${data.id}`, data).then((res) => {
    dispatch(replaceGrade({ data: res }));
    return res;
  });
});


export default {
  fetchGroups,
  fetchGroupProgram,
  fetchGroupAssignments,
  fetchAssignmentSubmissions,
  fetchGrade,
  updateGrade,
};
