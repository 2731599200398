import { adminSelectors, adminThunks } from 'store/ducks/admin';
import { studentSelectors, studentThunks } from 'store/ducks/student';
import { trainerSelectors, trainerThunks } from 'store/ducks/trainer';

export const CALENDAR_SELECTORS_BY_ROLES = {
  Student: studentSelectors.getCalendar(),
  Trainer: trainerSelectors.getCalendar(),
  Admin: adminSelectors.getCalendar(),
};

export const CALENDAR_IS_LOADING_BY_ROLES = {
  Student: studentSelectors.calendarLoading(),
  Trainer: trainerSelectors.calendarLoading(),
  Admin: adminSelectors.calendarLoading(),
};

export const CALENDAR_FETCH_BY_ROLES = {
  Student: (query) => studentThunks.fetchCalendar(query),
  Trainer: (query) => trainerThunks.fetchCalendar(query),
  Admin: (query) => adminThunks.fetchCalendar(query),
};
