import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceArticle } from './slice';

const fetchArticles = createAsyncThunk('article/fetchArticles', async (query) => {
  return api.get(`/articles${query}`);
});

const fetchArticle = createAsyncThunk('article/fetchArticle', async (id) => {
  return api.get(`/articles/${id}`);
});

const createArticle = createAsyncThunk('article/createArticle', (data) => {
  return api.post('/articles', data);
});

const updateArticle = createAsyncThunk('article/updateArticle', (data, { dispatch }) => {
  return api.put(`/articles/${data.id}`, data).then((res) => {
    dispatch(replaceArticle({ data: res }));
    return res;
  });
});

export default {
  fetchArticles,
  fetchArticle,
  createArticle,
  updateArticle,
};
