import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LessonNavItem from './LessonNavItem';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';

const ModuleNavItem = ({ program, module }) => {
  const navigate = useNavigate();

  const { id, moduleId, groupId } = useParams();
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const isSelectedModule = selectedModule?.id === module.id;

  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const handleModuleOpen = (module) => {
    if (!isSelectedModule) {
      groupId
        ? navigate(`/groups/${groupId}/programs/${id}/module/${module.id}`)
        : navigate(`/programs/${id}/module/${module.id}`);
    } else {
      /** If current role Mentor or Trainer we use groups for routing and check
       * if url has groupId we navigate in routing with groups otherwise just programs */
      groupId ? navigate(`/groups/${groupId}/programs/${id}`) : navigate(`/programs/${id}`);
    }
  };

  return (
    <Box
      mt={1}
      mx={2}
      border={isMobile ? 'none' : isSelectedModule && '1px solid'}
      borderRadius={2}
      borderColor={isSelectedModule && 'primary.lighter'}
    >
      {/* --- If we have Module we render the module name and list of lessons inside ---  */}
      {module?.name && (
        <>
          <ListItemButton
            onClick={() => handleModuleOpen(module)}
            sx={{
              color: 'primary.main',
              border: '1px solid',
              borderColor: 'transparent',
              pr: 1,
              '&:hover': isDesktop ? { border: '1px solid', borderColor: 'primary.lighter' } : 'none',
            }}
          >
            <ListItemText
              primary={module?.name}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: isMobile ? 14 : 16,
              }}
              sx={{ my: 0 }}
            />
            <KeyboardArrowUpIcon
              sx={{
                color: 'text.main',
                fontSize: 18,
                fontWeight: 400,
                transform: isSelectedModule ? 'rotate(180deg)' : 'rotate(90deg)',
                transition: '0.2s',
              }}
            />
          </ListItemButton>
        </>
      )}
      {isSelectedModule && <Divider sx={{ mx: 2 }} />}
      {isSelectedModule &&
        selectedModule?.lessons?.map((lesson) => (
          <LessonNavItem key={lesson.id} program={program} selectedModule={selectedModule} lesson={lesson} />
        ))}

      {/* --- If we don't have a Module we render just list of lessons inside ---  */}
      {!module?.name &&
        module?.lessons?.map((lesson) => (
          <LessonNavItem key={lesson.id} program={program} selectedModule={selectedModule} lesson={lesson} />
        ))}
    </Box>
  );
};

export default memo(ModuleNavItem);
