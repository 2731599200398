import { memo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import filters from 'filters';
import { useTranslation } from 'react-i18next';

const ListItemView = ({ item }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/programs/${id}/module/${item?.module}/lesson/${item?.lesson}/assignments/${item.id}`);
  };

  const { t } = useTranslation();

  const hasGrade = item.grade !== undefined && item.grade !== null;
  const lastComment = item.comments?.[item.comments?.length - 1];
  const lastSubmission = item.submissions?.[item.submissions?.length - 1];
  const hasNewComment = !hasGrade && new Date(lastComment?.createdAt) > new Date(lastSubmission);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        sx={{
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'primary.lighter',
          boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10);',
        }}
      >
        <Stack>
          <Typography color="text.main">
            {item.number}. {item.name}
          </Typography>
          <Typography variant="capture" color="text.main">
            {t('types.grade.deadline')}: {filters.date(item?.deadline, 'dd.MM.yyyy')}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" mr={6}>
            {item.submissions && (
              <>
                {hasGrade && (
                  <>
                    <Typography color="success.main" fontWeight={700} mr={1}>
                      {item.grade}
                    </Typography>
                    <Typography color="text.main" fontWeight={700}>
                      {t('types.grade.gradePoints')}
                    </Typography>
                  </>
                )}
                {hasNewComment && (
                  <Typography variant="capture" color="error" fontWeight={600}>
                    {t('types.grade.mentorComment')}
                  </Typography>
                )}

                {!hasGrade && !hasNewComment && (
                  <Typography variant="capture" color="info.main" fontWeight={600}>
                    {t('types.grade.pendingReply')}
                  </Typography>
                )}
              </>
            )}
          </Stack>
          <Button variant="outlined" onClick={handleClick}>
            {t('base.buttons.view')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(ListItemView);
