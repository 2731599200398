import useModal from 'hooks/useModal';
import ArticleForm from './components/ArticleForm';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const CreateArticlePage = () => {
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();

  const { t } = useTranslation();

  return (
    <>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <ArticleForm programId={id} modalOpen={modalOpen} openModal={openModal} closeModal={closeModal} t={t} />
      </ErrorBoundary>
    </>
  );
};

export default CreateArticlePage;
