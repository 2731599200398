import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const adminSlice = createSlice({
  name: 'adminSlice',
  initialState: {
    groups: loadDataInitialState([]),
    groupGrades: loadDataInitialState([]),
    calendar: loadDataInitialState([]),
    programs: loadDataInitialState([]),
    program: loadDataInitialState(null),
  },
  reducers: {
    addMaterial(state, action) {
      const { selectedModuleId, selectedLessonId, values } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          lesson.materials.push(values);
        }
      }
    },

    replaceMaterial(state, action) {
      const { selectedModuleId, selectedLessonId, materialId, value } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          const material = lesson.materials.find((material) => materialId === material.id);

          if (material) Object.assign(material, value);
        }
      }
    },
    deleteMaterial(state, action) {
      const { selectedModuleId, selectedLessonId, materialId } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          const materialIndex = lesson.materials.findIndex((material) => materialId === material.id);

          if (materialIndex !== -1) lesson.materials.splice(materialIndex, 1);
        }
      }
    },

    addAssignment(state, action) {
      const { selectedModuleId, selectedLessonId, values } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          lesson.assignments.push(values);
        }
      }
    },
    replaceAssignment(state, action) {
      const { selectedModuleId, selectedLessonId, assignmentId, value } = action.payload;
      const module = state.program.data.modules.find((m) => m.id === selectedModuleId);
      if (module) {
        const lesson = module.lessons.find((l) => l.id === selectedLessonId);

        if (lesson) {
          const assignment = lesson.assignments.find((assignment) => assignmentId === assignment.id);

          if (assignment) Object.assign(assignment, value);
        }
      }
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchGroups, 'groups');
    loadDataExtraReducer(builder, thunks.fetchGroupGrades, 'groupGrades');
    loadDataExtraReducer(builder, thunks.fetchCalendar, 'calendar');
    loadDataExtraReducer(builder, thunks.fetchPrograms, 'programs');
    loadDataExtraReducer(builder, thunks.fetchProgram, 'program');
  },
});

export const { addMaterial, replaceMaterial, deleteMaterial, addAssignment, replaceAssignment } = adminSlice.actions;
export default adminSlice.reducer;
