import { adminSelectors, adminThunks } from 'store/ducks/admin';
import { trainerSelectors, trainerThunks } from 'store/ducks/trainer';

export const GROUP_GRADES_SELECTORS_BY_ROLES = {
  Trainer: trainerSelectors.getGroupGrades(),
  Admin: adminSelectors.getGroupGrades(),
};

export const GROUP_GRADES_IS_LOADING_BY_ROLES = {
  Trainer: trainerSelectors.groupGradesLoading(),
  Admin: adminSelectors.groupGradesLoading(),
};

export const GROUP_GRADES_FETCH_BY_ROLES = {
  Trainer: (id) => trainerThunks.fetchGroupGrades(id),
  Admin: (id) => adminThunks.fetchGroupGrades(id),
};

export const GROUPS_SELECTORS_BY_ROLES = {
  Trainer: trainerSelectors.getGroups(),
  Admin: adminSelectors.getGroups(),
};

export const GROUPS_IS_LOADING_BY_ROLES = {
  Trainer: trainerSelectors.groupsLoading(),
  Admin: adminSelectors.groupsLoading(),
};

export const GROUPS_FETCH_BY_ROLES = {
  Trainer: trainerThunks.fetchGroups(),
  Admin: adminThunks.fetchGroups(),
};
