import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import defaultLogo from '../../theme/icons/logoWhiteDanEducation.svg';

import {
  Avatar,
  Stack,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  IconButton,
  Box,
  styled,
  useTheme,
} from '@mui/material';

import { MenuClosedIcon, MenuOpenIcon } from 'theme/icons';
import { userSelectors } from 'store/ducks/user';
import { getLastNameAndNameLetter, hasPermission } from 'utils/helpers';
import RoleSelector from './topBar/RoleSelector';
import { SIDEBAR_MENU } from 'utils/constants/sidebar';

const DRAWER_WIDTH = 224;

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(10),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const user = useSelector(userSelectors.getCurrentUser());
  const [open, setOpen] = useState(true);
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const theme = useTheme();

  const location = useLocation();
  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Stack direction="row" alignItems="center" justifyContent={open ? 'space-between' : 'center'}>
        {open && <img style={{ width: 'calc(100% - 60px)' }} src={theme.logo || defaultLogo} />}
        <IconButton onClick={handleDrawerOpen} sx={{ color: 'extra.white' }}>
          {open ? <MenuOpenIcon /> : <MenuClosedIcon />}
        </IconButton>
      </Stack>
      {/* --- AVATAR --- */}
      <Stack
        flexDirection={open ? 'row' : 'column'}
        gap={open ? 2 : 0.5}
        alignItems="center"
        my={5}
        ml={open ? 2.5 : 0}
      >
        <Avatar sx={{ width: 56, height: 56 }} alt={user.name} src={user.avatar} />
        <Box>
          <Typography component={'span'} fontSize={18} color="extra.white" display={open ? 'flex' : 'none'}>
            {getLastNameAndNameLetter(user)}
          </Typography>
          {user.roles.length === 1 && (
            <Typography variant="capture" color="extra.white" display={!open ? 'none' : 'flex'}>
              {currentRole}
            </Typography>
          )}
          {user.roles.length > 1 && (
            <RoleSelector user={user} color="extra.white" hideCurrentRole={!open ? true : false} />
          )}
        </Box>
      </Stack>
      <Stack display="flex" direction="column" alignItems="center" justifyContent="flex-start" gap={1} height="100%">
        {SIDEBAR_MENU.map((menuItem) => {
          const menuItemColor = menuItem.to === rootPath ? 'primary.main' : 'extra.white';
          const menuItemBgColor = menuItem.to === rootPath ? 'extra.white' : 'transparent';
          const hoverItemBgColor = menuItem.to === rootPath ? 'rgba(224, 224, 224, 0.9)' : 'rgba(0, 0, 0, 0.1)';

          return (
            <Fragment key={menuItem.key}>
              {hasPermission(menuItem.permission) && (
                <ListItem disableGutters sx={{ p: 0, '&:last-child': { mt: 'auto', mb: 3 } }}>
                  <ListItemButton
                    sx={{
                      py: 1,
                      backgroundColor: menuItemBgColor,
                      borderRadius: 2,
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: hoverItemBgColor,
                      },
                    }}
                    component={Link}
                    to={menuItem.to}
                    disableGutters
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: 'center',
                        color: menuItemColor,
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={t(`mainMenu.${menuItem.key}`)}
                        sx={{
                          color: menuItemColor,
                          '&>.MuiTypography-root': {
                            fontSize: 18,
                          },
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              )}
            </Fragment>
          );
        })}
      </Stack>
    </Drawer>
  );
};

export default memo(Sidebar);
