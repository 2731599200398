import { Button, Stack } from '@mui/material';
import { memo } from 'react';

const LinkViewCard = ({ material }) => {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Button href={material.link} target="_blank" sx={{ width: '80%' }}>
        {material.name}
      </Button>
    </Stack>
  );
};

export default memo(LinkViewCard);
