import { Card } from '@mui/material';
import { memo } from 'react';
import ReactPlayer from 'react-player';

const VideoViewCard = ({ link }) => {
  return (
    <Card
      sx={{
        minHeight: '50vh',
        width: '100%',
        borderRadius: 2,
      }}
    >
      <ReactPlayer url={link} controls height="100%" width="100%" />
    </Card>
  );
};

export default memo(VideoViewCard);
