import { memo, useEffect } from 'react';
import HeaderList from 'views/common/HeaderList';
import SubmissionsNavigation from './components/SubmissionsNavigation';
import { useSelector } from 'react-redux';
import { PROGRAMS_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { mentorSelectors, mentorThunks } from 'store/ducks/mentor';
import { userSelectors } from 'store/ducks/user';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import { Stack, useMediaQuery } from '@mui/material';
import store from 'store';
import { useParams } from 'react-router-dom';
import GradesTable from './components/GradesTable';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const AssignmentGradesPage = () => {
  const { groupId, assignmentId } = useParams();

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const groups = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);

  const assignments = useSelector(mentorSelectors.getGroupAssignments());
  const assignmentsLoading = useSelector(mentorSelectors.groupAssignmentsLoading());

  const grades = useSelector(mentorSelectors.getAssignmentSubmissions());
  const gradesLoading = useSelector(mentorSelectors.assignmentSubmissionsLoading());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  useEffect(() => {
    const data = {
      groupId: +groupId,
      assignmentId: +assignmentId,
    };

    store.dispatch(mentorThunks.fetchAssignmentSubmissions(data));
  }, [assignmentId, groupId]);

  useEffect(() => {
    groups?.length === 0 && store.dispatch(mentorThunks.fetchGroups());
  }, [groups]);

  useEffect(() => {
    assignments?.length === 0 && store.dispatch(mentorThunks.fetchGroupAssignments(groupId));
  }, [assignments, groupId]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.submission.groupSubmissions')} />
        </ErrorBoundary>
      )}

      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile isToShowProgramMenuBtn toggleProgramMobileMenu={toggleProgramMobileMenu} />
        </ErrorBoundary>
      )}
      {assignmentsLoading && <Loader />}
      {!assignmentsLoading && (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'hidden' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <SubmissionsNavigation groups={groups} assignments={assignments} />
            </ErrorBoundary>
          )}
          {!isProgramMenuOpen && (
            <ELementWrapper width="100%" height="100%" flex={3} padding={isDesktop ? 2 : 1}>
              <Stack
                sx={{ height: '100%', width: '100%' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                  <GradesTable list={grades} totalElements={grades?.length} loading={gradesLoading} />
                </ErrorBoundary>
              </Stack>
            </ELementWrapper>
          )}
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(AssignmentGradesPage);
