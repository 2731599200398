import { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';

const ErrorPage = ({
  title = 'Something went wrong',
  message = 'It appears something went wrong on our end. We are working to resolve the problem. Please try again later.',
}) => {
  const reloadPage = () => {
    window.location.assign('/');
  };

  const {t} = useTranslation()

  return (
    <Box>
      <Box pt={25} pl={22} sx={{ maxWidth: 800 }}>
        <Typography>{title}</Typography>
        <Box mt={3}>
          <Typography>{message}</Typography>
        </Box>
        <Box mt={4}>
          <Button startIcon={<ChevronLeftIcon />} onClick={reloadPage} variant="contained">
            {t('base.buttons.returnHome')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ErrorPage);
