import { createTheme, lighten, darken } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material';

export const theme = (colors, logo, logoShort) =>
  createTheme({
    palette: {
      primary: {
        main: colors.primaryBaseLms,
        lightest: lighten(colors.primaryBaseLms, 0.95),
        lighter: lighten(colors.primaryBaseLms, 0.85),
        light: lighten(colors.primaryBaseLms, 0.45),
        contrastText: colors.white,
      },
      secondary: {
        main: colors.secondaryBaseLms,
        light: lighten(colors.secondaryBaseLms, 0.7),
        contrastText: colors.white,
      },
      info: {
        main: colors.infoBase,
      },
      warning: {
        main: colors.warningBase,
      },
      success: {
        main: colors.successBase,
        light: lighten(colors.successBase, 0.7),
      },
      extra: {
        changed: colors.changed,
        white: colors.white,
        grey: colors.grey,
        gradient: `linear-gradient(100.95deg, ${colors.primaryBaseLms} 15.41%, ${colors.secondaryBaseLms} 100%)`,
      },
      background: {
        default: lighten(colors.primaryBaseLms, 0.8),
        paper: colors.white,
      },
      text: {
        main: colors.textBase,
        light: lighten(colors.textBase, 0.5),
        dark: darken(colors.textBase, 0.6),
      },
      error: {
        main: colors.errorBase,
        light: lighten(colors.errorBase, 0.6),
        dark: darken(colors.errorBase, 0.15),
      },
      divider: lighten(colors.primaryBaseLms, 0.85),

      dashboardChart: {
        start: colors.primaryBaseLms,
        finish: colors.secondaryBaseLms,
      },
    },
    typography: {
      allVariants: {
        textTransform: 'none',
      },
      fontFamily: ['Manrope', 'sans-serif'].join(','),

      h1: {
        fontSize: 30,
        fontWeight: 700,
      },
      h2: {
        fontSize: 28,
        fontWeight: 700,
      },
      h3: {
        fontSize: 26,
        fontWeight: 700,
      },
      h4: {
        fontSize: 22,
        fontWeight: 700,
      },
      h5: {
        fontSize: 20,
        fontWeight: 700,
      },
      h6: {
        fontSize: 18,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 18,
        fontWeight: 500,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },

      capture: {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 8,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar': {
            width: '6px',
          },
          '*::-webkit-scrollbar:horizontal': {
            height: '6px',
          },
          '*::-webkit-scrollbar-track': {
            background: 'transparent',
            borderRadius: 20,
          },
          '*::-webkit-scrollbar-thumb': {
            background: lighten(colors.primaryBaseLms, 0.45),
            borderRadius: 20,
            transition: 'background 0.5s ease',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: lighten(colors.primaryBaseLms, 0.2),
            borderRadius: 20,
            transition: 'background 0.5s ease',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontSize: 16,
            fontWeight: 500,
            boxShadow: 'none',
            minHeight: '44px',
            minWidth: '150px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '&.MuiDrawer-paper': {
              background: `linear-gradient(100.95deg, ${colors.primaryBaseLms} 15.41%, ${colors.secondaryBaseLms} 100%)`,
              borderRadius: '0 16px 16px 0',
              border: 'none',
              padding: '16px 8px 16px 8px',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 10,
            fontWeight: 400,
            position: 'absolute',
            top: 40,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: lighten(colors.textBase, 0.5),
            '&.MuiInputLabel-root': {
              top: 4,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            backgroundColor: colors.white,
            borderRadius: 4,
            margin: '4px 8px',
            color: darken(colors.textBase, 0.6),
          },
          root: {
            '& .MuiOutlinedInput-root': {
              padding: '4px 8px',
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: lighten(colors.primaryBaseLms, 0.85),
          },
          root: {
            backgroundColor: colors.white,
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: lighten(colors.primaryBaseLms, 0.45),
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${colors.primaryBaseLms}`,
            },
            '& input::placeholder': {
              color: lighten(colors.primaryBaseLms, 0.45),
            },
            '& input': {
              padding: '10px 0 10px 8px',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: colors.textBase,
            '&::before': {
              borderBottom: `1px solid ${lighten(colors.primaryBaseLms, 0.85)}`,
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: `1px solid ${lighten(colors.primaryBaseLms, 0.45)}`,
            },
            '&.Mui-focused:after': {
              borderBottom: `1px solid ${colors.primaryBaseLms}`,
            },
            '& input::placeholder': {
              color: lighten(colors.primaryBaseLms, 0.45),
            },
          },
        },
      },

      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: colors.primaryBaseLms,
            maxHeight: 320,
          },
          '&.MuiButtonBase-root': {
            color: colors.primaryBaseLms,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: colors.primaryBaseLms,
            fontWeight: 600,
            fontSize: 14,
            '&.Mui-selected': {
              borderRadius: 8,
            },
            '&:not(.Mui-selected)': {
              border: 'none',
            },
          },
          today: {
            color: colors.white,
            backgroundColor: lighten(colors.secondaryBaseLms, 0.7),
            borderRadius: 8,
            '&:hover': { backgroundColor: lighten(colors.secondaryBaseLms, 0.45) },
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: darken(colors.textBase, 0.6),
            fontWeight: 600,

            fontSize: 14,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: colors.primaryBaseLms,
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: 16,
          },

          switchViewIcon: {
            color: colors.primaryBaseLms,
          },
        },
      },

      MuiListItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            color: colors.textBase,
            borderRadius: 8,
            '&.Mui-selected': {
              backgroundColor: lighten(colors.primaryBaseLms, 0.85),
            },
            '&:hover': {
              background: 'none',
              color: colors.primaryBaseLms,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: lighten(colors.primaryBaseLms, 0.85),
            borderRadius: 16,
            padding: '8px 16px',
            '&:before': { opacity: 0 },
            '&:first-of-type': { borderRadius: 16 },
            '&:last-of-type': { borderRadius: 16 },
            '&.Mui-expanded': { margin: 0 },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiAccordionSummary-root': {
              padding: 0,
              minHeight: 1,
              '&.Mui-expanded:': { minHeight: 1 },
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 1,
            },
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: '4px 0',
            '&.Mui-expanded': { margin: '8px 0' },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 0 8px 0',
          },
        },
      },
      MuiAvatar: {
        defaultProps: {
          variant: 'rounded',
        },
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.primaryBaseLms, 0.45),
            border: 'none',
          },
        },
      },
      MuiAvatarGroup: {
        defaultProps: {
          variant: 'rounded',
        },
        styleOverrides: {
          root: {
            border: 'none',
          },
          avatar: {
            width: 24,
            height: 24,
            fontSize: 12,
            border: '0',
            borderRight: `2px solid ${colors.white}`,
            marginLeft: -8,
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': { backgroundColor: 'transparent' },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.primaryBaseLms, 0.95),
            color: lighten(colors.primaryBaseLms, 0.45),
            fontSize: 16,
            fontWeight: 700,
            maxWidth: '100%',
            '&.Mui-selected': {
              backgroundColor: colors.white,
              border: '1px solid',
              borderColor: lighten(colors.primaryBaseLms, 0.85),
            },
            '&:hover': {
              color: colors.primaryBaseLms,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 16,
              borderBottomLeftRadius: 16,
            },
            '&:last-of-type': {
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: 'transparent',
          },
          flexContainer: {
            gap: 16,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.primaryBaseLms, 0.95),
            color: lighten(colors.primaryBaseLms, 0.45),
            minHeight: '44px',
            minWidth: '124px',
            fontSize: 18,
            paddingTop: 6,
            paddingBottom: 6,
            border: 'none',
            fontWeight: 600,
            '&.Mui-selected': {
              backgroundColor: colors.white,
              color: colors.primaryBaseLms,
            },
            '&.Mui-selected:hover, &:hover': {
              backgroundColor: lighten(colors.primaryBaseLms, 0.85),
              color: colors.primaryBaseCrm,
            },
            [createTheme().breakpoints.down('sm')]: {
              fontSize: 16,
              minWidth: 'fit-content',
              overflow: 'scroll',
              flex: 1,
              '&.Mui-selected': {
                backgroundColor: colors.white,
                color: colors.primaryBaseLms,
              },
              '&.Mui-selected:hover, &:hover': {
                backgroundColor: colors.white,
                color: colors.primaryBaseCrm,
              },
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: colors.textBase,
            border: 'none',
          },
          columnHeader: {
            '&:focus-within': {
              outline: 'none',
            },
          },
          columnHeaders: {
            backgroundColor: lighten(colors.primaryBaseLms, 0.85),
            borderRadius: 8,
            borderBottom: 'none',
            color: darken(colors.textBase, 0.6),
            fontWeight: 600,
          },
          row: {
            borderRadius: 8,
            alignItems: 'center',
            '&.Mui-odd': {
              backgroundColor: lighten(colors.primaryBaseLms, 0.95),
            },
          },
          cell: {
            borderBottom: 'none',
            lineHeight: 2,
          },
          footerContainer: {
            borderTop: 'none',
            justifyContent: 'center',
            backgroundColor: lighten(colors.primaryBaseLms, 0.85),
          },
          checkboxInput: {
            color: lighten(colors.primaryBaseLms, 0.85),
          },
          virtualScroller: {
            minHeight: 24,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: 8,
            color: colors.textBase,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            marginRight: 0,
          },
        },
      },
    },
    logo,
    logoShort,
  });
