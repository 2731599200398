import { memo } from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';

const Loader = ({ fullscreen }) => {
  return fullscreen ? (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <CircularProgress color="primary" />
    </Backdrop>
  ) : (
    <Box p={2} display="flex" justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  );
};

export default memo(Loader);
