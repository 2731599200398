import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { MOBILE_VIEW, PROGRESS_BAR_BG_COLOR, PROGRESS_BAR_COLOR } from 'utils/constants/common';

const ProgramProgressBar = ({ program, programProgress }) => {
  const theme = useTheme();
  const color = PROGRESS_BAR_COLOR(theme)[programProgress] || theme.palette.primary.main;
  const bgColor = PROGRESS_BAR_BG_COLOR(theme)[programProgress] || theme.palette.primary.lighter;
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const isMobile = useMediaQuery(MOBILE_VIEW)

  return (
    <Box
      sx={{
        height: 34,
        width: '100%',
        borderRadius: 1,
        border: `1px solid ${color}`,
        color: color,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: `${programProgress}%`,
          backgroundColor: bgColor,
          borderRadius: 1,
          position: 'absolute',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <Typography
          sx={{
            color: color,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: isMobile ? 14 : 16,
          }}
        >{`${program?.name}`}</Typography>
        {currentRole === 'Student' && (
          <Typography
            sx={{ color: color, fontWeight: 'bold', fontSize: isMobile ? 14 : 16 }}
          >{`${programProgress}%`}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(ProgramProgressBar);
