import { Form, withFormik } from 'formik';
import { memo, useEffect, useState } from 'react';

import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikTextField from 'views/form/FormikTextField';
import { FormColumnWrapper } from 'views/common/styledComponents';
import { TabPanel, a11yProps } from 'utils/tabsFunc';
import FormikQuillEditor from 'views/form/FormikQuillEditor';
import { Button, Divider, IconButton, Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import store from 'store';

import { toast } from 'react-toastify';

import api from 'services/api';
import filters from 'filters';
import FormikArrayCriteria from './FormikArrayCriteria';
import { mentorActions, mentorThunks } from 'store/ducks/mentor';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import CommentsHistory from './CommentsHistory';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const GradeForm = ({ grade, values, isSubmitting, setFieldValue }) => {
  const [tabsValue, setTabsValue] = useState(0);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const hasCriteria = values?.criteria.length > 0 || grade?.assignment?.criteria?.length > 0;

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    if (values?.criteria.length === 0 && grade?.assignment?.criteria.length !== 0) {
      setFieldValue('criteria', grade?.assignment?.criteria);
    }
  }, [values?.criteria, grade?.assignment?.criteria, setFieldValue]);

  const handleDownloadFile = () => {
    api.get(`files/drive?id=${values?.file}`, {}, { responseType: 'blob', raw: true }).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const contentDisposition = response.headers?.['content-disposition'];
      link.download = contentDisposition.substring(
        contentDisposition.indexOf('"') + 1,
        contentDisposition.lastIndexOf('"')
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Form
      id="gradeForm"
      style={{
        width: '100%',
        height: 'calc(100% - 40px)',
      }}
    >
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data" sx={{ minHeight: isMobile && 0 }}>
        <Tab
          sx={{
            width: hasCriteria ? 'calc(50% - 16px)' : '100%',
            fontSize: isMobile ? 14 : 16,
            '&.MuiButtonBase-root': { p: isMobile ? '8px' : '8px 16px', minHeight: isMobile ? 0 : 48 },
          }}
          label={t('base.labels.submissionData')}
          {...a11yProps(0)}
        />
        {hasCriteria && <Tab sx={{ width: '50%' }} label={t('base.labels.gradeCriteria')} {...a11yProps(1)} />}
      </Tabs>

      <TabPanel value={tabsValue} index={0} sx={{ height: 'calc(100% - 48px)' }}>
        <Stack height="100%" justifyContent="space-between">
          <FormColumnWrapper
            sx={{
              width: '100%',
              flex: 3,
              height: '100%',
              py: isMobile ? 1 : 2,
              px: isMobile ? 0 : 2,
              my: 1,
              gap: 0,
              overflowY: 'hidden',
            }}
          >
            <Stack direction="row" alignItems="center" gap={0.5}>
              <FormikTextField
                name="createdAt"
                variant="standard"
                label={t('base.labels.submissionDate')}
                sx={{
                  width: '60%',
                }}
                disabled
              />
              <Divider orientation="vertical" />
              <FormikArrayTextField
                name="links"
                values={values.links}
                variant="standard"
                label={t('base.labels.link')}
                copyInputAdornment
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
              <Divider orientation="vertical" />
              <FormikTextField
                name="file"
                label={t('base.labels.file')}
                sx={{ width: '100%' }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <>
                      {values?.file && (
                        <IconButton onClick={handleDownloadFile} sx={{ color: 'primary.main', p: 0.25 }}>
                          <DownloadForOfflineOutlinedIcon />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
                disabled
              />

              <Divider orientation="vertical" />
              <FormikTextField name="grade" label={t('base.labels.grade')} variant="standard" sx={{ width: '30%' }} />
            </Stack>
            <FormikQuillEditor name="comment" placeholder={t('base.placeholders.comment')} />
          </FormColumnWrapper>

          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{
              alignSelf: 'center',
              mb: isMobile ? 1 : 2,
              minHeight: isMobile ? 40 : 48,
              fontSize: isMobile ? 14 : 16,
              width: isMobile && '100%',
            }}
          >
        {t("base.buttons.save")}
          </Button>
          {grade?.comments.length !== 0 && <CommentsHistory comments={grade?.comments} />}
        </Stack>
      </TabPanel>
      {hasCriteria && (
        <TabPanel
          value={tabsValue}
          index={1}
          sx={{
            height: '90%',
            py: 1,
            overflowY: 'auto',
          }}
        >
          <FormColumnWrapper
            sx={{
              width: '100%',
              p: 1,
            }}
          >
            <FormikArrayCriteria name="criteria" values={values?.criteria || []} />
          </FormColumnWrapper>
        </TabPanel>
      )}
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ grade = {} }) => ({
      ...grade,
      id: grade?.id,
      createdAt: filters.date(grade?.createdAt, 'dd.MM.yyyy hh:mm') || '',
      links: grade?.links || [],
      grade: grade?.grade || '',
      criteria: grade?.criteria || [],
      comment: grade?.comment || '',
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      const newValues = {
        ...values,
        criteria: values.criteria.map(({ grade, weight, name, description }) => {
          return {
            name: name,
            weight: weight,
            description: description,
            grade: grade ? grade : '',
          };
        }),
      };
      store.dispatch(mentorThunks.updateGrade(newValues)).then((res) => {
        !res.error && (toast.success(props.t('messages.success.toast.assignmentGraded')), setSubmitting(false)),
          res.meta.requestStatus === 'fulfilled' &&
            props.uncheckedCount > 0 &&
            props.isUnchecked &&
            store.dispatch(
              mentorActions.decreaseCountUnchecked({
                selectedAssignment: props.assignment,
                unchecked: props.uncheckedCount - 1,
              })
            );
      });
    },
    enableReinitialize: true,
  })(GradeForm)
);
