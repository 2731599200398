import TextIncreaseOutlinedIcon from '@mui/icons-material/TextIncreaseOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import RoofingOutlinedIcon from '@mui/icons-material/RoofingOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import { PERMISSIONS } from './common';
import { SettingsOutlined } from '@mui/icons-material';

export const SIDEBAR_MENU = [
  { key: 'dashboard', to: '/dashboard', icon: <RoofingOutlinedIcon />, permission: PERMISSIONS.dashboard },
  { key: 'programs', to: '/programs', icon: <MenuBookOutlinedIcon />, permission: PERMISSIONS.programs },
  { key: 'groups', to: '/groups', icon: <GroupsIcon />, permission: PERMISSIONS.groups },
  { key: 'articles', to: '/articles', icon: <ArticleOutlined />, permission: PERMISSIONS.articles },
  { key: 'grades', to: '/grades', icon: <TextIncreaseOutlinedIcon />, permission: PERMISSIONS.grades },
  { key: 'submissions', to: '/assignments', icon: <TextIncreaseOutlinedIcon />, permission: PERMISSIONS.submissions },
  { key: 'gradeBook', to: '/grade-book', icon: <TextIncreaseOutlinedIcon />, permission: PERMISSIONS.gradeBook },
  { key: 'calendar', to: '/calendar', icon: <CalendarMonthOutlinedIcon />, permission: PERMISSIONS.calendar },
  { key: 'settings', to: '/settings', icon: <SettingsOutlined />, permission: PERMISSIONS.settings },
  //TODO: hidden until feature is implemented
  // { title: 'Chat', to: '/chat', icon: <ChatOutlinedIcon />, permission: PERMISSIONS.chat },
];
