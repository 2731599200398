import FormikTextField from './FormikTextField';
import { FieldArray } from 'formik';
import { memo } from 'react';
import AddInputAdornment from './AddInputAdornment';
import RemoveInputAdornment from './RemoveInputAdornment';
import CopyInputAdornment from './CopyInputAdornment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FormikArrayTextField = ({
  name,
  values,
  label,
  placeholder,
  InputProps = {},
  copyInputAdornment,
  isUploaded,
  variant,
  disabled,
}) => {
  // isUploaded - condition for display the icon 'done' in case if the the student has already uploaded assignment
  const { t } = useTranslation();
  const { readOnly } = InputProps;
  return (
    <FieldArray
      name={name}
      width="100%"
      render={(arrayHelpers) => (
        <>
          {values && values.length > 0 ? (
            values.map((option, index) => (
              <Stack key={index} direction="row" gap={1} width="100%" alignItems="center">
                <FormikTextField
                  name={`${name}.${index}`}
                  label={label}
                  disabled={disabled}
                  sx={{ width: '100%' }}
                  placeholder={placeholder}
                  variant={variant}
                  InputProps={{
                    ...InputProps,
                    endAdornment: (
                      <>
                        {option && isUploaded && <CheckCircleOutlineIcon sx={{ color: 'secondary.main' }} />}
                        {copyInputAdornment && (
                          <CopyInputAdornment value={option} tooltipTitle={t('base.tooltips.copyLink')} />
                        )}
                        {!readOnly && <AddInputAdornment add={() => arrayHelpers.push('')} name={name} />}
                        {!readOnly && values.length > 1 && (
                          <RemoveInputAdornment remove={() => arrayHelpers.remove(index)} name={name} />
                        )}
                      </>
                    ),
                  }}
                />
              </Stack>
            ))
          ) : (
            <FormikTextField
              label={label}
              sx={{ width: '100%' }}
              name={`${name}`}
              variant={variant}
              placeholder={placeholder}
              onFocus={() => arrayHelpers.push('')}
              disabled={disabled}
            />
          )}
        </>
      )}
    />
  );
};

export default memo(FormikArrayTextField);
