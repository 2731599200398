/** ---- View of grades for STUDENT role --- */

import { memo, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import HeaderList from 'views/common/HeaderList';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import store from 'store';
import Loader from 'views/common/Loader';
import { studentSelectors, studentThunks } from 'store/ducks/student';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import GradesDesktopView from './components/GradesDesktopView';
import GradesMobileView from './components/GradesMobileView';
import { PageWrapper } from 'views/common/styledComponents';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const GradesPage = () => {
  const { id } = useParams();

  const currentRole = useSelector(userSelectors.getCurrentRole());

  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const programsLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]) && !!programs;

  const selectedProgram = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]);

  const allGrades = useSelector(studentSelectors.getAllGradesForProgram());
  const allGradesLoading = useSelector(studentSelectors.allGradesForProgramLoading());

  /** --- Calculate student's GPA --- */
  const gradedSubmissions = allGrades.filter(
    (submission) => submission.grade !== undefined && submission.grade !== null
  );
  const totalGrade = gradedSubmissions.reduce((acc, submission) => acc + submission.grade, 0);
  const averageGrade = totalGrade ? (totalGrade / gradedSubmissions.length).toFixed() : null;

  const [assignments, setAssignments] = useState();

  /** ---- Fetch all programs --- */
  useEffect(() => {
    programs.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole, programs]);

  /** ---- Fetch student's submissions ---- */
  useEffect(() => {
    id && store.dispatch(studentThunks.fetchAllGradesForProgram(+id));
  }, [id]);

  /** ---- Fetch selected program details ---- */
  useEffect(() => {
    id && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));
  }, [currentRole, id]);

  /** ---- Collect all assignments related to the program in order ot create a list of all assignments --- */
  useEffect(() => {
    const getAllAssignments = (course, allGrades) => {
      const allAssignments = [];

      for (const module of course.modules) {
        for (const lesson of module.lessons) {
          for (const assignment of lesson.assignments) {
            /** ---- Check if student submitted any solution to the given assignment --- */
            const grade = allGrades.find((submission) => {
              return submission.assignment.id === assignment.id;
            });

            grade
              ? allAssignments.push({
                  ...assignment,
                  module: module.id,
                  lesson: lesson.id,
                  grade: grade?.grade,
                  comments: grade?.comments,
                  submissions: grade?.submissions,
                })
              : allAssignments.push({ ...assignment, module: module.id, lesson: lesson.id });
          }
        }
      }

      return allAssignments;
    };

    selectedProgram && setAssignments(getAllAssignments(selectedProgram, allGrades));
  }, [allGrades, selectedProgram]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.grade.namePlural')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn={programs?.length > 1 ? true : false}
            selectedMaterialPriorityLabel={
              isProgramMenuOpen
                ? t('types.grade.selectProgram')
                : !programLoading &&
                  selectedProgram?.name &&
                  `${selectedProgram?.name} ${t('types.grade.namePlural').toLowerCase()}`
            }
            toggleProgramMobileMenu={toggleProgramMobileMenu}
          />
        </ErrorBoundary>
      )}
      {programsLoading && <Loader />}

      {!programsLoading &&
        (isDesktop ? (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <GradesDesktopView
              programs={programs}
              allGradesLoading={allGradesLoading}
              programLoading={programLoading}
              selectedProgram={selectedProgram}
              averageGrade={averageGrade}
              assignments={assignments}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <GradesMobileView
              programs={programs}
              allGradesLoading={allGradesLoading}
              programLoading={programLoading}
              selectedProgram={selectedProgram}
              averageGrade={averageGrade}
              assignments={assignments}
            />
          </ErrorBoundary>
        ))}
    </PageWrapper>
  );
};

export default memo(GradesPage);
