import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

/** --- Fetch groups --- */
const fetchGroups = createAsyncThunk('admin/fetchGroups', async () => {
  return api.get(`/admins/groups`);
});

/** --- Fetch group grades --- */
const fetchGroupGrades = createAsyncThunk('admin/fetchGroupGrades', async (groupId) => {
  return api.get(`/admins/groups/${groupId}`);
});

/** --- Fetch calendar  --- */
const fetchCalendar = createAsyncThunk('admin/fetchCalendar', async (query) => {
  return api.get(`/admins/calendar${query}`);
});


/** --- Fetch programs ---  */
const fetchPrograms = createAsyncThunk('admin/fetchPrograms', async () => {
  return api.get(`/admins/programs`);
});

const fetchProgram = createAsyncThunk('admin/fetchProgram', async (programId) => {
  return api.get(`/admins/programs/${programId}`);
});

export default {
  fetchGroups,
  fetchGroupGrades,
  fetchCalendar,
  fetchProgram,
  fetchPrograms,
};
