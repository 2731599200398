import { memo } from 'react';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import filters from 'filters';
import CommentsHistory from 'views/pages/submissions/components/CommentsHistory';
import { TABLET_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const ListItemView = ({ item }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/programs/${id}/module/${item?.module}/lesson/${item?.lesson}/assignments/${item.id}`);
  };

    const { t } = useTranslation();

  const hasGrade = item.grade !== undefined && item.grade !== null;
  const lastComment = item.comments?.[item.comments?.length - 1];
  const lastSubmission = item.submissions?.[item.submissions?.length - 1];
  const hasNewComment = !hasGrade && new Date(lastComment?.createdAt) > new Date(lastSubmission);
  const hasSubmission = item?.submissions?.length > 0;

  const isTablet = useMediaQuery(TABLET_VIEW);

  return (
    <Stack>
      <Stack
        direction="column"
        py={1}
        px={2}
        gap={2}
        sx={{
          borderRadius: 2,
          boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10);',
          backgroundColor: 'extra.white',
        }}
      >
        <Typography color="text.main" fontSize={isTablet && 18}>
          {item.number}. {item.name}
        </Typography>
        <Button variant="outlined" onClick={handleClick} sx={{ fontSize: 14, minHeight: 34 }}>
          {t('base.buttons.view')}
        </Button>
        {!hasSubmission && (
          <Typography variant={isTablet ? 'body2' : 'capture'} color="text.light">
            {t('types.grade.deadline')}: {filters.date(item?.deadline, 'dd.MM.yyyy')}
          </Typography>
        )}
        {hasSubmission && (
          <>
            {hasGrade && (
              <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                  <Typography variant={isTablet ? 'body2' : 'capture'} color="text.light">
                    {t('types.grade.checked')}: {filters.date(item?.comments?.at(-1)?.createdAt, 'dd.MM.yyyy')}
                  </Typography>

                  <Stack direction="row">
                    <Typography color="success.main" fontWeight={700} mr={1} variant={isTablet ? 'body1' : 'body2'}>
                      {item.grade}
                    </Typography>
                    <Typography color="text.main" fontWeight={700} variant={isTablet ? 'body1' : 'body2'}>
                      {t('types.grade.gradePoints')}
                    </Typography>
                  </Stack>
                </Stack>
                <CommentsHistory comments={item?.comments} />
              </>
            )}
            {hasNewComment && (
              <Typography variant={isTablet ? 'body2' : 'capture'} color="error" fontWeight={600}>
                {t('types.grade.mentorComment')}
              </Typography>
            )}

            {!hasGrade && !hasNewComment && (
              <Typography variant={isTablet ? 'body2' : 'capture'} color="info.main" fontWeight={600}>
                {t('types.grade.pendingReply')}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ListItemView);
