const getGroups = () => (state) => state.admin.groups.data;
const groupsLoading = () => (state) => state.admin.groups.isLoading;
const getGroupsError = () => (state) => state.admin.groups.error;

const getGroupGrades = () => (state) => state.admin.groupGrades.data;
const groupGradesLoading = () => (state) => state.admin.groupGrades.isLoading;
const getGroupGradesError = () => (state) => state.admin.groupGrades.error;

const getCalendar = () => (state) => state.admin.calendar.data;
const calendarLoading = () => (state) => state.admin.calendar.isLoading;
const getCalendarError = () => (state) => state.admin.calendar.error;

const getPrograms = () => (state) => state.admin.programs.data;
const programsLoading = () => (state) => state.admin.programs.isLoading;
const getProgramsError = () => (state) => state.admin.programs.error;

const getProgram = () => (state) => state.admin.program.data;
const programLoading = () => (state) => state.admin.program.isLoading;
const getProgramError = () => (state) => state.admin.program.error;

export default {
  getGroups,
  groupsLoading,
  getGroupsError,
  getGroupGrades,
  groupGradesLoading,
  getGroupGradesError,
  getCalendar,
  calendarLoading,
  getCalendarError,
  getPrograms,
  programsLoading,
  getProgramsError,
  getProgram,
  programLoading,
  getProgramError,
};
