import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import store from 'store';
import { articleSelectors, articleThunks } from 'store/ducks/article';
import useModal from 'hooks/useModal';

import ArticleForm from './components/ArticleForm';
import Loader from 'views/common/Loader';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const EditArticlePage = () => {
  const { id, articleId } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();

  const article = useSelector(articleSelectors.getArticle());
  const isLoading = useSelector(articleSelectors.articleLoading()) && !article;

  const { t } = useTranslation();

  useEffect(() => {
    articleId && store.dispatch(articleThunks.fetchArticle(articleId));
  }, [articleId]);

  return isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <ArticleForm
        programId={id}
        modalOpen={modalOpen}
        openModal={openModal}
        closeModal={closeModal}
        article={article}
        t={t}
      />
    </ErrorBoundary>
  );
};

export default memo(EditArticlePage);
