import { IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../theme/icons/searchIcon.svg';
import { memo, useCallback, useEffect, useState } from 'react';
import { TABLET_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const SearchBar = ({ setSortedPrograms, setSearchParams, isServerSearch, programs }) => {
  const [phrase, setPhrase] = useState('');
  const theme = useTheme();

  const isTablet = useMediaQuery(TABLET_VIEW);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (event) => {
      setPhrase(event.target.value);
    },
    [setPhrase]
  );

  /** --- Fix the losing of the last letter in search input --- */
  useEffect(() => {
    handleChange;
  }, [handleChange]);

  const handleServerSearch = () => {
    const params = { q: phrase };
    setSearchParams(params);
  };

  const handleClientSearch = () => {
    if (phrase) {
      const result = programs.filter((program) => program.name.toLowerCase().includes(phrase));
      setSortedPrograms(result);
    } else {
      setSortedPrograms(programs);
    }
  };

  const showResults = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      if (isServerSearch) {
        handleServerSearch();
      } else {
        handleClientSearch();
      }
    }
  };

  return (
    <TextField
      variant="outlined"
      type="search"
      name="q"
      autoComplete="off"
      sx={(theme) => ({
        minWidth: { sm: 288 },
        maxWidth: isTablet ? '100%' : 480,
        [theme.breakpoints.down('lg')]: { width: '100%', m: 2 },
      })}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'} sx={{ ml: 0 }}>
            <IconButton onClick={showResults}>
              <SearchIcon stroke={theme.palette.primary.main} fill="none" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={t('base.placeholders.search')}
      onChange={handleChange}
      onKeyDown={showResults}
      value={phrase}
    />
  );
};

export default memo(SearchBar);
