import { memo } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const ArticleCard = ({ article, onClick }) => {
  return (
    <Grid item sm={12} md={6} width="100%">
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: '0px 0px 4px 0px rgba(77, 84, 201, 0.10)',
          cursor: 'pointer',
          position: 'relative',
          border: '1px solid',
          overflow: 'hidden',
          borderColor: 'primary.lighter',
          transition: 'all 0.5s',
          '&:hover': {
            backgroundColor: 'primary.lighter',
            p: 0,
          },
        }}
        onClick={onClick}
      >
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Typography variant="subtitle2" color="text.main">
            {article?.name}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default memo(ArticleCard);
