import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactQuill from 'react-quill';
import filters from 'filters';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const CommentsHistory = ({ comments }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();

  const sortedByDate = comments ? [...comments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];

  return (
    <Accordion sx={{ maxHeight: '320px', padding: isDesktop ? '8px 16px' : '8px 8px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="text.main" fontSize={isMobile ? 12 : 14} fontWeight={600}>
          {t('types.assignment.commentsHistory')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          maxHeight: '260px',
          overflowY: 'auto',
          backgroundColor: 'extra.white',
          borderRadius: 2,
        }}
      >
        {sortedByDate?.map((comment) => (
          <Stack
            key={comment.id}
            alignSelf="flex-start"
            sx={{
              borderRadius: 2,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="center" width="100%" mt={1}>
              <Divider width={isMobile ? '30%' : '40%'} />
              <Chip
                variant="outlined"
                label={filters.date(comment?.createdAt, 'dd.MM.yyyy HH:mm')}
                sx={{ fontSize: isMobile ? 10 : 12 }}
              />
              <Divider width={isMobile ? '30%' : '40%'} />
            </Stack>
            <ReactQuill theme="snow" value={comment.comment} modules={{ toolbar: false }} readOnly={true} />
          </Stack>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CommentsHistory);
