import { Stack } from '@mui/material';
import GradesNavigation from './GradesNavigation';
import { ELementWrapper, ElementTitleBlackColor } from 'views/common/styledComponents';
import Loader from 'views/common/Loader';
import GradesList from './GradesList';
import { memo } from 'react';
import NoAssignments from './NoAssignments';
import { useTranslation } from 'react-i18next';

const GradesDesktopView = ({
  programs,
  allGradesLoading,
  programLoading,
  selectedProgram,
  averageGrade,
  assignments,
}) => {
  const hasFewPrograms = programs?.length > 1;
  const { t } = useTranslation();

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      height="100%"
      sx={{ overflowY: 'auto' }}
    >
      {/* ---- Display menu only if student is assigned to more than one program --- */}

      {hasFewPrograms && <GradesNavigation programs={programs} />}

      <ELementWrapper
        width="100%"
        height="100%"
        sx={{ overflowY: 'auto' }}
        justifyContent="flex-start"
        flex={hasFewPrograms ? 0.7 : 1}
      >
        {(allGradesLoading || programLoading) && <Loader />}
        {(!allGradesLoading || !programLoading) && (
          <>
            <Stack mx={3} my={2} direction="row" alignItems="center" justifyContent="space-between">
              <ElementTitleBlackColor>
                {selectedProgram?.name} {t('types.grade.namePlural').toLowerCase()}
              </ElementTitleBlackColor>
              {averageGrade && (
                <Stack direction="row" gap={1}>
                  <ElementTitleBlackColor>{t('types.grade.average')}:</ElementTitleBlackColor>
                  <ElementTitleBlackColor sx={{ color: 'success.main' }}>{averageGrade}</ElementTitleBlackColor>
                </Stack>
              )}
            </Stack>
            {assignments?.length === 0 ? (
              <NoAssignments />
            ) : (
              <GradesList grades={assignments ? assignments : []} program={selectedProgram} />
            )}
          </>
        )}
      </ELementWrapper>
    </Stack>
  );
};

export default memo(GradesDesktopView);
