import { memo } from 'react';
import Modal from './Modal';
import ModalActions from './ModalActions';
import { Typography } from '@mui/material';

const ConfirmModal = ({
  open,
  saveDisabled,
  onClose,
  confirm,
  title,
  formId,
  text,
  children,
  questionIcon,
  confirmTitle = 'Delete',
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      questionIcon={questionIcon}
      actions={
        <ModalActions
          onClose={onClose}
          saveDisabled={saveDisabled}
          submitForm={confirm}
          submitTitle={confirmTitle}
          formId={formId}
        />
      }
    >
      {text && <Typography component="div">{text}</Typography>}
      {children}
    </Modal>
  );
};

export default memo(ConfirmModal);
