import { Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const NoAssignments = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography variant="h3" color="text.primary">
        {t('messages.warning.noAssignments')}
      </Typography>
    </Stack>
  );
};

export default memo(NoAssignments);
