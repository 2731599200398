import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import AuthCheck from './AuthCheck';
import RoleCheck from './RoleCheck';
import Login from 'views/pages/auth/Login';
import AuthContainer from 'views/containers/AuthContainer';
import ForgotPassword from '../views/pages/auth/ForgotPassword';
import ResetPassword from '../views/pages/auth/ResetPassword';
import Page404 from '../views/pages/error/Page404';
import PageLayout from 'views/layouts/PageLayout';
import ProgramsPage from 'views/pages/programs/ProgramsPage';
import GradesPage from 'views/pages/grades/GradesPage';
// import Chat from 'views/pages/chat/ChatPage';
import CalendarPage from 'views/pages/calendar/CalendarPage';
import ProgramPage from 'views/pages/programs/ProgramPage';
import ArticlesPage from 'views/pages/articles/ArticlesPage';
import CreateArticlePage from 'views/pages/articles/CreateArticlePage';
import EditArticlePage from 'views/pages/articles/EditArticlePage';
import ProfilePage from 'views/pages/settings/profile/ProfilePage';
import ProtectedRoute from './ProtectedRoute';
import GroupsPage from 'views/pages/groups/GroupsPage';
import DashboardPage from '../views/pages/dashboard/DashboardPage';
import MaterialPage from 'views/pages/programs/MaterialPage';
import MaterialsPage from 'views/pages/programs/MaterialsPage';
import AssignmentsPage from 'views/pages/programs/AssignmentsPage';
import AssignmentPage from 'views/pages/programs/AssignmentPage';
import { PERMISSIONS } from 'utils/constants/common';
import AddEditMaterialPage from 'views/pages/programs/AddEditMaterialPage';
import AddEditAssignmentPage from 'views/pages/programs/AddEditAssignmentPage';
import SubmissionsPage from 'views/pages/submissions/SubmissionsPage';
import GroupSubmissionsPage from 'views/pages/submissions/GroupSubmissionsPage';
import AssignmentSubmissionsPage from 'views/pages/submissions/AssignmentGradesPage';
import GradePage from 'views/pages/submissions/GradePage';
import AccessDenied from 'views/pages/error/AccessDenied';
import SessionRecordPage from 'views/pages/programs/SessionRecordPage';
import GradeBookGroupsListPage from 'views/pages/gradeBook/GradeBookGroupsListPage';
import GradeBookPage from 'views/pages/gradeBook/GradeBookPage';
import SessionRecordsPage from 'views/pages/programs/SessionRecordsPage';
import ProgramsListOfGradesPage from 'views/pages/grades/ProgramsListOfGradesPage';
import SettingsLayout from 'views/layouts/SettingsLayout';

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      key="AuthCheck"
      path="/"
      element={
        <AuthCheck>
          <AuthContainer />
        </AuthCheck>
      }
    >
      <Route path="/" element={<PageLayout />} exact>
        <Route index element={<RoleCheck />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute permission={PERMISSIONS.dashboard}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        {/* --------------- FOR TRAINER AND MENTOR ROLES ----------------------- */}
        {/* ----------- GROUPS -------- */}
        <Route
          path="groups"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <GroupsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:groupId"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <GroupsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:groupId/programs/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <ProgramPage />
            </ProtectedRoute>
          }
        >
          <Route path="module/:moduleId" element={<ProgramPage />}>
            <Route path="lesson/:lessonId" element={<ProgramPage />} />
          </Route>
        </Route>
        {/** --- MATERIALS ---  */}
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <MaterialsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName/material/:materialId"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <MaterialPage />
            </ProtectedRoute>
          }
        />
        {/** --- ASSIGNMENTS ---  */}
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/assignments/"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <AssignmentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/assignments/:assignmentId"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <AssignmentPage />
            </ProtectedRoute>
          }
        />
        {/** --- RECORD ---  */}
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/records"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <SessionRecordsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:groupId/programs/:id/module/:moduleId/lesson/:lessonId/records/:recordId"
          element={
            <ProtectedRoute permission={PERMISSIONS.groups}>
              <SessionRecordPage />
            </ProtectedRoute>
          }
        />
        {/* -------------------------------------------------------------- */}
        {/* --------------- FOR ADMIN AND STUDENT ROLES ------------------ */}
        {/* ---- PROGRAMS ----------  */}
        <Route
          path="programs"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <ProgramsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <ProgramPage />
            </ProtectedRoute>
          }
        >
          <Route path="module/:moduleId" element={<ProgramPage />}>
            <Route path="lesson/:lessonId" element={<ProgramPage />} />
          </Route>
        </Route>
        {/** --- MATERIALS ---  */}
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <MaterialsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName/add/:materialType"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AddEditMaterialPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName/material/:materialId"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <MaterialPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/priority/:priorityName/material/:materialId/edit"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AddEditMaterialPage />
            </ProtectedRoute>
          }
        />
        {/** --- ASSIGNMENTS ---  */}
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/assignments/"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AssignmentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/assignments/add"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AddEditAssignmentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/assignments/:assignmentId"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AssignmentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/assignments/:assignmentId/edit"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <AddEditAssignmentPage />
            </ProtectedRoute>
          }
        />
        {/** --- RECORDs ---  */}
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/records"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <SessionRecordsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id/module/:moduleId/lesson/:lessonId/records/:recordId"
          element={
            <ProtectedRoute permission={PERMISSIONS.programs}>
              <SessionRecordPage />
            </ProtectedRoute>
          }
        />
        {/* --------------------------------------------------  */}
        {/** ---- ARTICLES ----  */}
        <Route
          path="articles"
          element={
            <ProtectedRoute permission={PERMISSIONS.articles}>
              <ArticlesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="articles/create-article"
          element={
            <ProtectedRoute permission={PERMISSIONS.articles}>
              <CreateArticlePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="articles/:articleId"
          element={
            <ProtectedRoute permission={PERMISSIONS.articles}>
              <EditArticlePage />
            </ProtectedRoute>
          }
        />
        {/* --------------------------------------------------  */}
        <Route
          path="grades"
          element={
            <ProtectedRoute permission={PERMISSIONS.grades}>
              <ProgramsListOfGradesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="grades/programs/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.grades}>
              <GradesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="assignments"
          element={
            <ProtectedRoute permission={PERMISSIONS.submissions}>
              <SubmissionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="assignments/groups/:groupId"
          element={
            <ProtectedRoute permission={PERMISSIONS.submissions}>
              <GroupSubmissionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="assignments/groups/:groupId/:assignmentId"
          element={
            <ProtectedRoute permission={PERMISSIONS.submissions}>
              <AssignmentSubmissionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="assignments/groups/:groupId/:assignmentId/submissions/:submissionId"
          element={
            <ProtectedRoute permission={PERMISSIONS.submissions}>
              <GradePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="grade-book"
          element={
            <ProtectedRoute permission={PERMISSIONS.gradeBook}>
              <GradeBookGroupsListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="grade-book/:groupId"
          element={
            <ProtectedRoute permission={PERMISSIONS.gradeBook}>
              <GradeBookPage />
            </ProtectedRoute>
          }
        />
        <Route path="calendar" element={<CalendarPage />} />
        {/* 
        TODO: hidden until feature is implemented
        <Route path="chat" element={<Chat />} /> */}
        <Route path="settings" element={<SettingsLayout />} exact>
          <Route index element={<Navigate to="profile" />} replace />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route key="notFound" path="*" element={<Page404 />} />,
      </Route>
    </Route>,
    <Route key="login" path="/login" element={<Login />} exact />,
    <Route key="denied" path="/denied" element={<AccessDenied />} />,
    <Route key="forgot" path="/forgot" element={<ForgotPassword />} exact />,
    <Route key="reset" path="/reset/:token" element={<ResetPassword />} exact />,
  ])
);

export default router;
