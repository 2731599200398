import { Button, Chip, LinearProgress, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import filters from 'filters';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MOBILE_VIEW } from 'utils/constants/common';
import { getLastNameAndNameLetter } from 'utils/helpers';

const GradesTable = ({ list, totalElements, loading }) => {
  const navigate = useNavigate();
  const { groupId, assignmentId } = useParams();

  const { t } = useTranslation();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleClick = (id) => {
    navigate(`/assignments/groups/${groupId}/${assignmentId}/submissions/${id}`);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.2,
      renderCell: ({ row }) => {
        return (
          <Button
            variant="text"
            sx={{ px: 0, fontSize: 14, color: 'text.main', minWidth: '100%', justifyContent: 'left', gap: 2 }}
            onClick={() => handleClick(row.id)}
          >
            {row.id}
          </Button>
        );
      },
    },

    {
      field: 'student',
      headerName: t('types.submission.student'),
      filterable: false,
      flex: 0.3,
      renderCell: ({ row: { student } }) => {
        return getLastNameAndNameLetter(student);
      },
    },
    {
      field: 'submissions',
      headerName: t('types.submission.submissionDate'),
      filterable: false,
      flex: isMobile ? 0.4 : 0.3,
      renderCell: ({ row }) => {
        const hasSubmissions = row.submissions.length > 0;
        const hasComments = row.comments.length > 0;
        const submissionDate = hasSubmissions ? row?.submissions?.at(-1) : row?.createdAt;

        const isNew =
          hasSubmissions &&
          (!hasComments || new Date(row.submissions.at(-1)) > new Date(row.comments.at(-1).createdAt));

        return (
          <>
            {filters.date(submissionDate, isMobile ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm')}
            {isNew && (
              <Chip
                label="new"
                color="secondary"
                sx={{
                  height: 'fit-content',
                  ml: 1,
                  '&& .MuiChip-label': {
                    px: 1,
                  },
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: 'grade',
      headerName: t('types.submission.grade'),
      filterable: false,
      flex: isMobile ? 0.2 : 0.1,
    },
  ];

  return (
    <DataGrid
      rows={list}
      rowCount={totalElements}
      columns={columns}
      loading={loading}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      hideFooter
    />
  );
};

export default memo(GradesTable);
