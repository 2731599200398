import { memo, useEffect } from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const FormikTextField = ({
  name,
  disableError,
  variant = 'outlined',
  multiline,
  defValue,
  InputProps = {},
  ...rest
}) => {
  const [field, state, helpers] = useField(name);

  const { readOnly } = InputProps;

  useEffect(() => {
    if (defValue) {
      helpers.setValue(defValue);
    }
  }, [defValue, helpers]);


  return (
    <TextField
      {...field}
      {...rest}
      variant={variant}
      multiline={multiline}
      maxRows={1}
      InputProps={{
        ...InputProps,
        readOnly: readOnly,
      }}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      error={!disableError && !!state.error && state.touched}
      helperText={!disableError && !!state.error && state.touched && state.error}
    />
  );
};

export default memo(FormikTextField);
