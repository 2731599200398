const getArticles = () => (state) => state.article.list.data;
const articlesLoading = () => (state) => state.article.list.isLoading;
const getArticlesError = () => (state) => state.article.list.error;

const getArticle = () => (state) => state.article.item.data;
const articleLoading = () => (state) => state.article.item.isLoading;
const getArticleError = () => (state) => state.article.item.error;

export default {
  getArticles,
  articlesLoading,
  getArticlesError,
  getArticle,
  articleLoading,
  getArticleError,
};
