import { memo, useEffect } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { PageWrapper } from 'views/common/styledComponents';

import store from 'store';
import { studentThunks } from 'store/ducks/student';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';

import DesktopProfileView from './components/DesktopProfileView';
import MobileProfileView from './components/MobileProfileView';
import { MOBILE_VIEW } from 'utils/constants/common';

const ProfilePage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const theme = useTheme();
  const isStudent = currentRole === 'Student';

  useEffect(() => {
    isStudent && store.dispatch(studentThunks.fetchInvoices());
  }, [isStudent]);

  return (
    <PageWrapper
      sx={{
        height: 'calc(100dvh - 79px)',
        [theme.breakpoints.down('lg')]: {
          gap: 1,
        },
      }}
    >
      {isMobile ? <MobileProfileView /> : <DesktopProfileView />}
    </PageWrapper>
  );
};

export default memo(ProfilePage);
