import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ProgramMenuIcon } from '../../theme/icons/program-menu-icon.svg';
import SearchBar from './SearchBar';
import { Link, useNavigate } from 'react-router-dom';
import { AddCircleOutlineOutlined, ChevronLeftOutlined } from '@mui/icons-material';

const HeaderPageMobile = ({
  toggleProgramMobileMenu,
  selectedMaterialPriorityLabel,
  isDisplaySearchBar,
  isToShowProgramMenuBtn,
  setSortedPrograms,
  setSearchParams,
  isServerSearch,
  programs,
  to,
}) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      {isToShowProgramMenuBtn && (
        <Stack m={2} direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <IconButton LinkComponent={Link} onClick={goBack} sx={{ p: 0, color: 'primary.main' }}>
            <ChevronLeftOutlined />
          </IconButton>
          <Typography sx={{ textAlign: 'center', flex: 1, color: 'text.main' }}>
            {selectedMaterialPriorityLabel}
          </Typography>
          {isDisplaySearchBar && (
            <SearchBar
              setSortedPrograms={setSortedPrograms}
              setSearchParams={setSearchParams}
              isServerSearch={isServerSearch}
              programs={programs}
            />
          )}
          {to && (
            <IconButton LinkComponent={Link} variant="contained" to={to} sx={{ color: 'primary.main' }}>
              <AddCircleOutlineOutlined />
            </IconButton>
          )}
          <IconButton onClick={toggleProgramMobileMenu} sx={{ p: 0 }}>
            <ProgramMenuIcon />
          </IconButton>
        </Stack>
      )}
      {isDisplaySearchBar && !isToShowProgramMenuBtn && (
        <SearchBar
          setSortedPrograms={setSortedPrograms}
          setSearchParams={setSearchParams}
          isServerSearch={isServerSearch}
          programs={programs}
        />
      )}
    </Stack>
  );
};

export default HeaderPageMobile;
