const getLessons = () => (state) => state.lesson.lessons.data;
const lessonsLoading = () => (state) => state.lesson.lessons.isLoading;
const getLessonsError = () => (state) => state.lesson.lessons.error;


export default {
  getLessons,
  lessonsLoading,
  getLessonsError,
};
