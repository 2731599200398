import { Button, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const NoArticles = ({ searchPhrase }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const goToCreateArticle = () => {
    navigate(`create-article`);
  };

  return (
    <Stack alignItems="center" gap={3} justifyContent="center" height="100%">
      <Stack alignItems="center">
        <Typography variant="h1" color="text.main">
          {t('messages.warning.noArticles.titleStart')} {searchPhrase && t('messages.warning.noArticles.titleEnd')}
        </Typography>
        <Typography variant="h6" color="text.main">
          {t('messages.warning.noArticles.subtitle')}
        </Typography>
      </Stack>
      <Button variant="contained" LinkComponent={Link} onClick={() => goToCreateArticle()}>
        {t('base.buttons.create')}
      </Button>
    </Stack>
  );
};

export default memo(NoArticles);
