import { Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { memo } from 'react';
import ConfirmModal from 'views/common/modal/ConfirmModal';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const HeaderPage = ({ title, data, formId, modalOpen, closeModal, openModal, isSubmitting, resetForm }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const goBack = () => navigate(-1);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: isDesktop ? 2 : 0,
        my: isDesktop ? 0 : 1,
        mx: !isDesktop && 2,
        backgroundColor: isDesktop ? 'extra.white' : 'transparent',
        borderRadius: 2,
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {!isDesktop ? (
          <IconButton
            LinkComponent={Link}
            onClick={goBack}
            sx={{
              color: theme.palette.primary.main,
              p: 0,
            }}
          >
            <ChevronLeftOutlined />
          </IconButton>
        ) : (
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
            {t('base.buttons.back')}
          </Button>
        )}

        <Typography variant={isMobile ? 'body1' : 'h4'} color={theme.palette.text.main}>
          {title}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        {/* Check if we create new instance or edit current one */}
        {data && (
          <Button
            variant="contained"
            onClick={() => openModal()}
            sx={{ fontSize: isMobile ? 14 : 16, minWidth: isMobile ? 120 : 150, minHeight: isMobile ? 40 : 44 }}
          >
            {t('base.buttons.apply')}
          </Button>
        )}
        {!data && (
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            form={formId}
            sx={{ fontSize: isMobile ? 14 : 16, minWidth: isMobile ? 120 : 150, minHeight: isMobile ? 40 : 44 }}
          >
            {t('base.buttons.add')}
          </Button>
        )}

        <ConfirmModal
          title="Save changes?"
          open={modalOpen}
          onClose={closeModal}
          formId={formId}
          saveDisabled={isSubmitting}
          confirmTitle={t('base.buttons.save')}
          questionIcon
        />

        {isDesktop && <Button onClick={() => resetForm()}>{t('base.buttons.refresh')}</Button>}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderPage);
