import { memo, useEffect, useState } from 'react';
import { DateCalendar, DayCalendarSkeleton, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { Badge } from '@mui/material';
import { DateTime } from 'luxon';

import { ELementWrapper } from 'views/common/styledComponents';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import i18n from 'i18n/i18n';

const SmallCalendar = ({ calendarData, calendarDataLoading, handleMonthChange }) => {
  const [markedDates, setMarkedDates] = useState([]);

  useEffect(() => {
    if (calendarData) {
      const dates = calendarData.map((item) => DateTime.fromISO(item.date).toISODate());
      setMarkedDates(dates);
    }
  }, [calendarData]);

  const renderDay = ({ day, outsideCurrentMonth, selected, onSelect, onDoubleClick, ...other }) => {
    const isMarked = markedDates.includes(day.toISODate());

    return (
      <Badge key={day.toString()} overlap="circular" color="secondary" variant={isMarked ? 'dot' : 'standard'}>
        <PickersDay
          {...other}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          selected={selected}
          onSelect={onSelect}
          onDoubleClick={onDoubleClick}
        />
      </Badge>
    );
  };

  return (
    <ELementWrapper>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language === 'UA' ? 'UK' : i18n.language}>
        <DateCalendar
          views={['day']}
          renderLoading={() => <DayCalendarSkeleton />}
          loading={calendarDataLoading}
          slots={{
            day: renderDay,
          }}
          onMonthChange={handleMonthChange}
          readOnly
        />
      </LocalizationProvider>
    </ELementWrapper>
  );
};

export default memo(SmallCalendar);
