import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const assignmentSlice = createSlice({
  name: 'assignmentSlice',
  initialState: {
    item: loadDataInitialState(null),
  },
  reducers: {
    clearAssignment(state) {
      state.item = loadDataInitialState(null)
    }
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchAssignment, 'item');
    loadDataExtraReducer(builder, thunks.createAssignment, 'item');
    loadDataExtraReducer(builder, thunks.updateAssignment, 'item');
    loadDataExtraReducer(builder, thunks.createAssignmentGradeCriteria, 'item');
    loadDataExtraReducer(builder, thunks.updateAssignmentGradeCriteria, 'item');
    loadDataExtraReducer(builder, thunks.deleteAssignmentGradeCriteria, 'item');
  },
});

export const {clearAssignment} = assignmentSlice.actions;
export default assignmentSlice.reducer;
