import { memo } from 'react';
import { Grid } from '@mui/material';

import ProgramCard from './ProgramCard';

const ProgramsList = ({ programs, handleCardClick }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        {programs?.map((program) => (
          <ProgramCard key={program?.id} program={program} onClick={() => handleCardClick(program.id)} />
        ))}
      </Grid>
    </>
  );
};

export default memo(ProgramsList);
