import { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { default as MuiSelect } from '@mui/material/Select';

const Select = ({
  fullWidth,
  label,
  value,
  onChange,
  sendEvent = false,
  disabled = false,
  children,
  variant,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const select = (
    <MuiSelect
      value={value}
      label={label}
      onChange={sendEvent ? onChange : handleChange}
      disabled={disabled}
      variant={variant}
      {...props}
    >
      {children}
    </MuiSelect>
  );

  return label ? (
    <FormControl fullWidth={fullWidth} disabled={disabled} variant={variant}>
      <InputLabel>{label}</InputLabel>
      {select}
    </FormControl>
  ) : (
    select
  );
};

export default memo(Select);
