import './custom-month-event.css'

import { DateTime } from "luxon";

const CustomMonthEvent = ({ event }) => {
  const incomingDate = new Date(event.start);
  const timeOfEvent = DateTime.fromJSDate(incomingDate).toFormat('HH:mm');
  return (
      <div className="rbc-event-content" title="">
        <span className="rbc-event-time">{timeOfEvent}</span>
        <span className="rbc-event-title"> {event.title}</span>
      </div>
  );
};

export default CustomMonthEvent;
