import { memo } from 'react';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
  const { t } = useTranslation();
  return <ErrorPage title={t('accessDeniedPage.title')} message={t('accessDeniedPage.message')} />;
};

export default memo(AccessDenied);
