import { memo } from 'react';

import { Form, withFormik } from 'formik';
import FormikTextField from 'views/form/FormikTextField';
import FormikQuillEditor from 'views/form/FormikQuillEditor';
import { validationArticleForm } from 'utils/schema';

import store from 'store';
import { articleThunks } from 'store/ducks/article';

import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import HeaderPage from 'views/common/HeaderPage';
import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import { useTranslation } from 'react-i18next';
// import { turndownService } from 'utils/helpers';

const ArticleForm = ({ modalOpen, openModal, closeModal, isSubmitting, resetForm, article, submitForm }) => {
  const { t } = useTranslation();

  return (
    <Form id="articleForm">
      <PageWrapper>
        <HeaderPage
          title={article ? t('types.article.edit') : t('types.article.add')}
          data={article}
          isSubmitting={isSubmitting}
          resetForm={resetForm}
          formId="articleForm"
          modalOpen={modalOpen}
          openModal={openModal}
          closeModal={closeModal}
          confirm={submitForm}
        />

        <PageViewPaperVioletLight>
          <FormikTextField
            name="name"
            label={t('base.labels.name')}
            placeholder={t('base.placeholders.enterName')}
            sx={{
              width: '100%',
              mb: 2,
            }}
          />
          <Box height="85%">
            <FormikQuillEditor name="text" placeholder={t('base.placeholders.enterContent')} />
          </Box>
        </PageViewPaperVioletLight>
      </PageWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ article = {}, programId }) => ({
      ...article,
      id: article?.id,
      program: (programId && { id: programId }) || null,
      name: article?.name || '',
      text: article?.text || '',
    }),
    validationSchema: validationArticleForm,
    handleSubmit: (values, { props, setSubmitting }) => {
      
      // TODO: markdown
      // const markdown = turndownService.turndown(values.text);
      // const valuesWithMarkDown = { ...values, text: markdown };

      props.article
        ? store
            .dispatch(articleThunks.updateArticle(values))
            .then((res) => {
              !res.error &&
                (toast.success(props.t('messages.success.toast.articleUpdate')),
                setSubmitting(false),
                props.closeModal());
            })
            .finally(setSubmitting(false))
        : store
            .dispatch(articleThunks.createArticle(values))
            .then((res) => {
              !res.error && (setSubmitting(false), toast.success(props.t('messages.success.toast.articleAdd')));
            })
            .finally(setSubmitting(false));
    },
    enableReinitialize: true,
  })(ArticleForm)
);
