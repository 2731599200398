import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Fragment, memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import RoleSelector from './RoleSelector';
import { SIDEBAR_MENU } from 'utils/constants/sidebar';
import { hasPermission } from 'utils/helpers';
import { Link, useLocation } from 'react-router-dom';

import defaultLogoShort from '../../../theme/icons/logo-short.svg';
import { ReactComponent as MobileMenuOpenIcon } from '../../../theme/icons/icon-menu-mobile-open.svg';
import { ReactComponent as MobileMenuClosedIcon } from '../../../theme/icons/icon-menu-mobile-close.svg';
import { useTranslation } from 'react-i18next';

const TabletTopBar = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const theme = useTheme();

  const user = useSelector(userSelectors.getCurrentUser());
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const location = useLocation();
  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const { t } = useTranslation();

  const handleMenuItemClick = (title) => {
    setSelected(title);
    setOpen(false);
  };

  useEffect(() => {
    const selectedTitleArr = path[1].split('');
    const selectedTitle = selectedTitleArr?.at(0)?.toUpperCase() + selectedTitleArr?.slice(1).join('');
    setSelected(selectedTitle);
  }, [path, setSelected]);

  return (
    <Stack
      p={2}
      sx={{
        background: theme.palette.extra.gradient,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      {/* --- LOGO & MENU BTN ---  */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <img src={theme.logoShort || defaultLogoShort} />
        <Typography variant="subtitle2" color="extra.white">
          {open ? t('base.dictionary.menu') : selected}
        </Typography>

        <IconButton onClick={() => setOpen(!open)}>
          {open ? <MobileMenuOpenIcon /> : <MobileMenuClosedIcon />}
        </IconButton>
        {/* --- DRAWER ---  */}
        <Drawer
          anchor="top"
          open={open}
          onClose={() => setOpen(false)}
          sx={{ top: '56px' }}
          PaperProps={{
            style: {
              top: '56px',
              maxWidth: '100%',
              borderRadius: 0,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              background: theme.palette.extra.gradient,
              boxShadow: 'none',
            },
          }}
          slotProps={{
            backdrop: {
              style: { top: 56 },
            },
          }}
        >
          <Stack p={2} gap={2}>
            {/* --- AVATAR AND USER SETTINGS BUTTONS ---  */}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar sx={{ width: 44, height: 44 }} alt={user.name} src={user.avatar} />
                <Box>
                  <Typography variant="body1" color="extra.white" fontWeight={700}>
                    {user.lastName} {user.name}
                  </Typography>
                  {user.roles.length === 1 && (
                    <Typography variant="body2" color="extra.white">
                      {currentRole}
                    </Typography>
                  )}
                  {user.roles.length > 1 && <RoleSelector user={user} color="extra.white" />}
                </Box>
              </Stack>
            </Stack>
            <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />

            {/* --- MENU ---  */}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {SIDEBAR_MENU.map((menuItem) => {
                return (
                  <Fragment key={menuItem.key}>
                    {hasPermission(menuItem.permission) && (
                      <ListItem disableGutters sx={{ py: 0 }}>
                        <ListItemButton
                          sx={{
                            color: 'extra.white',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                          component={Link}
                          to={menuItem.to}
                          onClick={() => handleMenuItemClick(menuItem.key)}
                          disableGutters
                        >
                          <ListItemIcon
                            sx={{
                              color: menuItem.to === rootPath ? 'primary.main' : 'extra.white',
                              minWidth: 0,
                              backgroundColor: menuItem.to === rootPath ? 'extra.white' : 'transparent',
                              padding: 1,
                              borderRadius: 2,
                            }}
                          >
                            {menuItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`mainMenu.${menuItem.key}`)}
                            sx={{
                              color: 'extra.white',
                              '&>.MuiTypography-root': {
                                fontSize: 14,
                                fontWeight: menuItem.to === rootPath ? 700 : 400,
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </Fragment>
                );
              })}
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    </Stack>
  );
};

export default memo(TabletTopBar);
