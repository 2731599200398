import { memo } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ModalActions = ({ onClose, submitForm, formId, submitTitle = 'Save', saveDisabled = false }) => {
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection={mobileView ? 'column' : 'row'}
      flexWrap="wrap"
      width="100%"
      gap={2}
    >
      <Button sx={{ px: 8 }} fullWidth={mobileView} onClick={onClose}>
        {t('base.buttons.cancel')}
      </Button>

      <Button
        variant="contained"
        sx={{ px: 8 }}
        type={formId ? 'submit' : 'button'}
        form={formId}
        fullWidth={mobileView}
        onClick={(e) => {
          e.stopPropagation();
          submitForm && submitForm();
        }}
        disabled={saveDisabled}
      >
        {submitTitle}
      </Button>
    </Box>
  );
};

export default memo(ModalActions);
