import { memo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import SearchBar from './SearchBar';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ELementWrapper } from './styledComponents';
import { useTranslation } from 'react-i18next';

const HeaderList = ({
  title,
  to,
  buttonTitle,
  setSearchParams,
  setSortedPrograms,
  isDisplaySearchBar,
  isServerSearch,
  programs,
}) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const { t } = useTranslation();

  return (
    <>
      <ELementWrapper
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="extra.white"
        p={2}
        borderRadius={2}
        sx={{
          width: '100%',
        }}
      >
        <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
            {t('base.buttons.back')}
          </Button>
          <Typography variant="h4" color="text.main">
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" gap={3}>
          {isDisplaySearchBar && (
            <SearchBar
              setSortedPrograms={setSortedPrograms}
              setSearchParams={setSearchParams}
              isServerSearch={isServerSearch}
              programs={programs}
            />
          )}
          {to && (
            <Button LinkComponent={Link} variant="contained" to={to}>
              {buttonTitle}
            </Button>
          )}
        </Stack>
      </ELementWrapper>
    </>
  );
};
export default memo(HeaderList);
