const getEnums = () => (state) => state.dictionary.enums.data;
const enumsLoading = () => (state) => state.dictionary.enums.isLoading;

const getLanguages = () => (state) => state.dictionary.languages.data;
const languagesLoading = () => (state) => state.dictionary.languages.isLoading;

export default {
  getEnums,
  getLanguages,
  enumsLoading,
  languagesLoading,
};
