import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button, Menu, MenuItem, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PERMISSIONS } from 'utils/constants/common';
import { hasPermission } from 'utils/helpers';

const SETTINGS_MENU = [
  { title: 'Profile', to: 'profile', permission: PERMISSIONS.settings },
  // { title: "Dictionaries", to: "dictionaries" },
];

const SettingsMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  const currentLocation = path.split('/').at(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState('');
  const open = Boolean(anchorEl);

  const handleMenuSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (menuItem) => {
    setSelected(menuItem);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelected(currentLocation);
  }, [currentLocation]);

  const menuItems = SETTINGS_MENU.filter((menuItem) => hasPermission(menuItem.permission)).map((menuItem) => (
    <MenuItem
      key={menuItem?.title}
      component={Link}
      to={menuItem.to}
      selected={menuItem.to === selected}
      onClick={() => handleMenuItemClick(menuItem.to)}
    >
      <Stack direction="row" sx={{ gap: 2 }}>
        {menuItem?.title}
      </Stack>
    </MenuItem>
  ));

  return (
    <>
      <Button
        onClick={handleMenuSelect}
        variant="text"
        sx={{
          color: 'text.main',
          fontSize: 20,
          fontWeight: 700,
          '&:hover': { background: 'none', color: 'primary.main' },
        }}
        endIcon={
          open ? (
            <KeyboardArrowUpIcon fontSize="large" sx={{ color: 'primary.main' }} />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" sx={{ color: 'primary.main' }} />
          )
        }
      >
        {SETTINGS_MENU.find((menu) => menu.to === selected)?.title}
      </Button>

      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'settingsMenu-button',
          role: 'listbox',
        }}
        sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default memo(SettingsMenu);
