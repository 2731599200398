import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

/** --- Fetch groups which are assigned to trainer --- */
const fetchGroups = createAsyncThunk('trainer/fetchGroups', async () => {
  return api.get(`/trainers/groups`);
});

/** --- Fetch group program --- */
const fetchGroupProgram = createAsyncThunk('trainer/fetchGroupProgram', async (groupId) => {
  return api.get(`/trainers/groups/${groupId}/program`);
});

/** --- Fetch group grades --- */
const fetchGroupGrades = createAsyncThunk('trainer/fetchGroupGrades', async (groupId) => {
  return api.get(`/trainers/groups/${groupId}`);
});

/** --- getCalendarData--- */
const fetchCalendar = createAsyncThunk('trainer/fetchCalendar', async (query) => {
  return api.get(`/trainers/calendar${query}`);
});

/** --- getCalendarNearestData--- */
const fetchCalendarNearest = createAsyncThunk('trainer/fetchCalendarNearest', async () => {
  return api.get(`/trainers/calendar/nearest`);
});


export default {
  fetchGroups,
  fetchGroupProgram,
  fetchGroupGrades,
  fetchCalendar,
  fetchCalendarNearest
  };
