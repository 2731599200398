import { memo } from 'react';
import ArticleCard from './ArticleCard';
import { Box, Grid } from '@mui/material';

const ArticlesList = ({ articles, handleCardClick }) => {
  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <Grid container spacing={2}>
        {articles?.map((article) => (
          <ArticleCard key={article.id} article={article} onClick={(event) => handleCardClick(event, article.id)} />
        ))}
      </Grid>
    </Box>
  );
};

export default memo(ArticlesList);
