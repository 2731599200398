import { Breadcrumbs, Stack, Typography, useMediaQuery } from '@mui/material';
import { memo, useEffect } from 'react';
import HeaderList from 'views/common/HeaderList';
import SubmissionsNavigation from './components/SubmissionsNavigation';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { PROGRAMS_SELECTORS_BY_ROLES } from 'utils/constants/program';
import { mentorSelectors, mentorThunks } from 'store/ducks/mentor';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import { useParams } from 'react-router-dom';
import store from 'store';
import GradeForm from './components/GradeForm';
import { getLastNameAndFirstName } from 'utils/helpers';
import { DESKTOP_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import Loader from 'views/common/Loader';

const GradePage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { groupId, assignmentId, submissionId } = useParams();
  const groups = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);

  const { t } = useTranslation();

  const assignments = useSelector(mentorSelectors.getGroupAssignments());
  const assignmentsLoading = useSelector(mentorSelectors.groupAssignmentsLoading());

  const group = groups?.find((group) => group.id === +groupId);
  const assignment = assignments?.find((assignment) => assignment.id === +assignmentId);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const grade = useSelector(mentorSelectors.getGrade());
  const gradeLoading = useSelector(mentorSelectors.gradeLoading());

  const lastCommentDate =
    grade?.comments.length > 0 ? new Date(grade?.comments[grade?.comments.length - 1]?.createdAt) : null;
  const lastSubmissionDate =
    grade?.submissions.length > 0 ? new Date(grade?.submissions[grade?.submissions.length - 1]) : null;
  const isUnchecked =
    (grade?.comments.length === 0 && grade?.submissions.length !== 0) || lastCommentDate < lastSubmissionDate;

  const breadcrumbs = [
    <Typography key="1" color="text.primary" variant="body2" fontWeight={700}>
      {groupId ? group?.name : ''}
    </Typography>,
    <Typography key="2" color="text.primary" variant="body2">
      {assignment?.name}
    </Typography>,
    <Typography key="3" color="text.primary" variant="body2">
      {getLastNameAndFirstName(grade?.student)}
    </Typography>,
  ];

  useEffect(() => {
    store.dispatch(mentorThunks.fetchGrade(+submissionId));
  }, [submissionId]);

  useEffect(() => {
    assignments?.length === 0 && store.dispatch(mentorThunks.fetchGroupAssignments(groupId));
  }, [assignments.length, groupId]);

  useEffect(() => {
    groups?.length === 0 && store.dispatch(mentorThunks.fetchGroups());
  }, [groups]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.grade.name')} />
        </ErrorBoundary>
      )}

      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile isToShowProgramMenuBtn toggleProgramMobileMenu={toggleProgramMobileMenu} />
        </ErrorBoundary>
      )}
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        height="100%"
        sx={{ overflowY: 'hidden' }}
      >
        {(isDesktop || isProgramMenuOpen) && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <SubmissionsNavigation groups={groups} assignments={assignments} assignmentsLoading={assignmentsLoading} />
          </ErrorBoundary>
        )}
        {!isProgramMenuOpen && (
          <ELementWrapper width="100%" height="100%" flex={3} padding={2} gap={1}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              {gradeLoading ? (
                <Loader />
              ) : (
                <GradeForm
                  grade={grade}
                  groupId={+groupId}
                  isUnchecked={isUnchecked}
                  uncheckedCount={assignment?.unchecked}
                  assignment={assignment}
                  t={t}
                />
              )}
            </ErrorBoundary>
          </ELementWrapper>
        )}
      </Stack>
    </PageWrapper>
  );
};

export default memo(GradePage);
