import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './formik-quill-custom.css';
import { useField } from 'formik';
import api from 'services/api';
// import { useEffect, useState } from 'react';

// import { turndownService } from 'utils/helpers';
// import Showdown from 'showdown';
//TODO: markdown
// const converter = new Showdown.Converter({
//  simplifiedAutoLink: true,
//   tables: true,
//   strikethrough: true,
//   tasklists: true,
//   ghCodeBlocks: true,
//   omitExtraWLInCodeBlocks: true,
//   parseImgDimensions: true,
//   smoothLivePreview: true,
//   smartIndentationFix: true,
//   ghCompatibleHeaderId: true,
//   prefixHeaderId: 'user-content-',
//   requireSpaceBeforeHeadingText: true,
//   ghMentions: true,
//   ghMentionsLink: 'https://github.com/{u}',
//   encodeEmails: true,
//   openLinksInNewWindow: true,
// })

// const isHtml = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

const handleImage = function () {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();

    formData.append('file', file);

    // Save current cursor state
    const range = this.quill.getSelection(true);

    // Insert temporary loading placeholder image
    this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/spinner.gif`);

    const res = await api.post('/uploads/image', formData, { 'Content-Type': 'multipart/form-data' });

    // Remove placeholder image
    this.quill.deleteText(range.index, 1);

    // Insert uploaded image
    this.quill.insertEmbed(range.index, 'image', res);

    // Move cursor to right side of image (easier to continue typing)
    this.quill.setSelection(range.index + 1);
  };
};

const modules = {
  toolbar: {
    handlers: {
      image: handleImage,
    },
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote', 'code-block'],
      ['link', 'image'],
    ],
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'code-block',
  'color',
  'background',
  'align',
  'indent',
];

const FormikQuillEditor = ({ name, placeholder, ...rest }) => {
  const [field, , helpers] = useField(name);

  //TODO: markdown
  // const [editorHtml, setEditorHtml] = useState('');

  // useEffect(() => {
  //   if (!field.value) return;
  //   const initialHtml = isHtml(field.value) ? field.value : converter.makeHtml(field.value);
  //   setEditorHtml(initialHtml);
  // }, [field.value]);

  const handleChange = (value) => {
    // setEditorHtml(value);
    // setMarkdown(turndownService.turndown(value));
    helpers.setValue(value);
  };

  return (
    <ReactQuill
      theme="snow"
      value={field.value}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default FormikQuillEditor;
