
import { memo } from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../../../theme/icons/icon-arrow-left-small.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../theme/icons/icon-arrow-right-small.svg';
import { useTheme } from "@mui/material";


const CustomToolbar = ({ label, views, view, onView, onNavigate, messages }) => {
  const theme = useTheme();

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '16px',
  };

  const btnGroupStyle = {
    display: 'contents',
    flex: 1,
  };

  const btnNavigationStyle = {
    fontFamily: 'inherit',
    fontSize: '16px',
    backgroundColor: theme.palette.primary.main, //'#4d54c9'
    color: theme.palette.extra.white,
    padding: '8px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  const viewNamesGroupStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 2,
    marginRight: '8px',
    marginLeft: '8px',
  };

  const buttonStyle = {
    border: 'none',
    backgroundColor: theme.palette.primary.lightest,
    color: theme.palette.primary.light,
    fontFamily: 'inherit',
    fontSize: '16px',
    width: '100%',
    textTransform: 'capitalize',
    padding: '8px 24px',
    cursor: 'pointer',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: theme.palette.extra.white,
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.lighter,
    fontWeight: '700',
    whiteSpace: 'nowrap',
  };

  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  return (
    <div style={toolbarStyle}>
      <span style={btnGroupStyle}>
        <button type="button" style={btnNavigationStyle} onClick={goToBack}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </span>
      <div style={viewNamesGroupStyle}>
        {views.map((name) => {
          return (
            <button
              key={name}
              type="button"
              style={view === name ? activeButtonStyle : buttonStyle}
              onClick={() => onView(name)}
            >
              {view === name ? label : messages[name]}
            </button>
          );
        })}
      </div>
      <button type="button" style={btnNavigationStyle} onClick={goToNext}>
        <ArrowRightIcon width={18} height={18} />
      </button>
    </div>
  );
};

export default memo(CustomToolbar);
