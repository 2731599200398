import { Button, CircularProgress, Stack, useMediaQuery } from '@mui/material';
import { Form, withFormik } from 'formik';
import { memo } from 'react';
import { toast } from 'react-toastify';
import store from 'store';
import { studentThunks } from 'store/ducks/student';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FileUploader from '../materials/FileUploader';
import { uploadThunks } from 'store/ducks/upload';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const LinkSubmission = ({ submission, values, isSubmitting, file, handleChooseFile, onDeleteFile }) => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const { t } = useTranslation();
  return (
    <Form id="submissionLink">
      <Stack
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        sx={{
          minWidth: '100%',
          height: '100%',
        }}
      >
        <FormikArrayTextField
          name="links"
          values={values.links}
          placeholder={t('base.placeholders.pasteLink')}
          isUploaded={submission?.links.length !== 0}
          sx={{ minWith: '100%' }}
        />

        <FileUploader
          file={file}
          handleChooseFile={handleChooseFile}
          fileSize="15000000"
          filesAccept=".pdf, .ppt, .pptx, .txt, .xls, .xlsx, .doc, .docx, .zip, .mp4, .mpeg, .avi, .jpeg, .jpg, .png"
          isLoading={isSubmitting}
          onDeleteFile={onDeleteFile}
          isUploaded={values?.file}
        />

        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          sx={{
            fontSize: isMobile ? 14 : 16,
            width: isMobile && '100%',
          }}
        >
          {isSubmitting ? <CircularProgress size={16} sx={{ mr: 1 }} /> : t('base.buttons.submit')}
        </Button>
      </Stack>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ submission = {} }) => ({
      ...submission,
      links: submission?.links || [],
    }),

    handleSubmit: (values, { props, resetForm, setSubmitting }) => {
      if (props?.file) {
        return store.dispatch(uploadThunks.uploadFile(props.file)).then((response) => {
          response?.meta?.requestStatus === 'fulfilled' &&
            store
              .dispatch(
                studentThunks.submitAssignment({
                  groupId: props.groupId,
                  assignmentId: props.assignmentId,
                  body: { ...values, file: response?.payload },
                })
              )
              .then((res) => {
                !res.error && (toast.success(props.t('messages.success.toast.submitAssignment')), setSubmitting(false)),
                  store.dispatch(studentThunks.fetchGrade(props.assignmentId));
              })
              .finally(setSubmitting(false));
        });
      } else {
        store
          .dispatch(
            studentThunks.submitAssignment({
              groupId: props.groupId,
              assignmentId: props.assignmentId,
              body: { ...values, file: null },
            })
          )
          .then((res) => {
            !res.error && (toast.success(props.t('messages.success.toast.submitAssignment')), setSubmitting(false)),
              resetForm(),
              store.dispatch(studentThunks.fetchGrade(props.assignmentId));
          });
      }
    },
    enableReinitialize: true,
  })(LinkSubmission)
);
