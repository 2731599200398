import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'store/utils/loadDataInitialState';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';

const dictionarySlice = createSlice({
  name: 'dictionarySlice',
  initialState: {
    enums: loadDataInitialState({
      lessonMaterialsType: [],
      lessonMaterialPriorities: [],
      activeStatuses: [],
      assignmentRequirements: [],
      assignmentTypes: [],
    }),
    languages: loadDataInitialState([]),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchEnums, 'enums');
    loadDataExtraReducer(builder, thunks.fetchLanguages, 'languages');
  },
});

export const { addItem, replaceItem, deleteItem } = dictionarySlice.actions;
export default dictionarySlice.reducer;
