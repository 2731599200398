import { memo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW } from 'utils/constants/common';
import MaterialCard from '../materials/MaterialCard';

const AssignmentsList = ({ assignments, handleSelectAssignment }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  return (
    <Grid container spacing={isDesktop ? 2 : 1} sx={{ mt: isDesktop ? 1 : 0 }}>
      {assignments?.map((assignment) => (
        <MaterialCard
          key={assignment.id}
          material={assignment}
          isAssignment
          onClick={(e) => handleSelectAssignment(e, assignment)}
        />
      ))}
    </Grid>
  );
};

export default memo(AssignmentsList);
