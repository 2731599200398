import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, TextField, useMediaQuery } from '@mui/material';

import { userSelectors } from 'store/ducks/user';
import { memo } from 'react';
import { MOBILE_VIEW } from 'utils/constants/common';

const PersonalInformation = () => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const user = useSelector(userSelectors.getCurrentUser());
  const { name, lastName, secondName, phones } = user.contact;

  const { t } = useTranslation();

  return (
    <Stack gap={1} sx={{ py: isMobile ? 3 : 0 }}>
      <TextField
        label={t('base.labels.lastName')}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={lastName}
      />
      <TextField
        label={t('base.labels.name')}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={name}
      />
      <TextField
        label={t('base.labels.secondName')}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={secondName}
      />
      {phones?.map((phone) => (
        <TextField
          key={phone}
          label={t('base.labels.phone')}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          value={phone}
        />
      ))}
    </Stack>
  );
};

export default memo(PersonalInformation);
