import { Chip, IconButton, Tooltip, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ReactComponent as CopyIcon } from 'theme/icons/copyIcon.svg';
import { useCopyToClipboard } from 'utils/helpers';
import { memo } from 'react';

const CopyInputAdornment = ({ value, tooltipTitle }) => {
  const [copyToClipboard, copyResult] = useCopyToClipboard();
  const theme = useTheme();

  return (
    <>
      {copyResult === null && (
        <IconButton
          sx={{ p: 0.25 }}
          disabled={value === '' || value === undefined || value.length === 0}
          onClick={() => copyToClipboard(value)}
        >
          <Tooltip title={tooltipTitle}>
            <CopyIcon
              fill={
                value === '' || value === undefined || value.length === 0
                  ? theme.palette.extra.grey
                  : theme.palette.primary.main
              }
            />
          </Tooltip>
        </IconButton>
      )}
      {copyResult?.state === 'success' && <Chip variant="outlined" size="small" color="primary" icon={<DoneIcon />} />}
      {copyResult?.state === 'error' && (
        <Chip variant="outlined" label={copyResult?.message || 'Error'} color="error" icon={<ErrorOutlineIcon />} />
      )}
    </>
  );
};

export default memo(CopyInputAdornment);
