import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import VideoViewCard from './components/materials/VideoViewCard';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { makeShortString } from 'utils/helpers';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const SessionRecordPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { id, groupId, lessonId, moduleId, recordId } = useParams();

  const { t } = useTranslation();

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]) && !program;
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const session = selectedLesson?.sessions?.find((session) => session.id === +recordId);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](groupId ? groupId : id));
  }, [currentRole, groupId, id, program]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.sessionRecord.name')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('types.sessionRecord.name')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={`${makeShortString(selectedLesson?.name, 24)} ${t(
              'types.sessionRecord.record'
            ).toLowerCase()}`}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            padding={isDesktop ? 2 : 1}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar program={program} selectedModule={selectedModule} selectedLesson={selectedLesson} />
            </ErrorBoundary>
            <Stack mt={2}>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <VideoViewCard link={session?.record} />
              </ErrorBoundary>
            </Stack>
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(SessionRecordPage);
