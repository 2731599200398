const getGroups = () => (state) => state.mentor.groups.data;
const groupsLoading = () => (state) => state.mentor.groups.isLoading;
const getGroupsError = () => (state) => state.mentor.groups.error;

const getGroupProgram = () => (state) => state.mentor.groupProgram.data;
const groupProgramLoading = () => (state) => state.mentor.groupProgram.isLoading;
const getGroupProgramError = () => (state) => state.mentor.groupProgram.error;

const getGroupAssignments = () => (state) => state.mentor.groupAssignments.data;
const groupAssignmentsLoading = () => (state) => state.mentor.groupAssignments.isLoading;
const getGroupAssignmentsError = () => (state) => state.mentor.groupAssignments.error;

const getAssignmentSubmissions = () => (state) => state.mentor.assignmentSubmissions.data;
const assignmentSubmissionsLoading = () => (state) => state.mentor.assignmentSubmissions.isLoading;
const getAssignmentSubmissionsError = () => (state) => state.mentor.assignmentSubmissions.error;

const getGrade = () => (state) => state.mentor.grade.data;
const gradeLoading = () => (state) => state.mentor.grade.isLoading;
const getGradeError = () => (state) => state.mentor.grade.error;

export default {
  getGroups,
  groupsLoading,
  getGroupsError,
  getGroupProgram,
  groupProgramLoading,
  getGroupProgramError,
  getGroupAssignments,
  groupAssignmentsLoading,
  getGroupAssignmentsError,
  getAssignmentSubmissions,
  assignmentSubmissionsLoading,
  getAssignmentSubmissionsError,
  getGrade,
  gradeLoading,
  getGradeError,
};
