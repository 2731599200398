import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

/**  --- ACTIVITY ---- */
const fetchActivity = createAsyncThunk('student/fetchActivity', async () => {
  return api.get(`/students/activity`);
});

const updateActivity = createAsyncThunk('student/updateActivity', () => {
  return api.put(`/students/activity`);
});

const fetchActivityTotal = createAsyncThunk('student/fetchActivityTotal', async () => {
  return api.get(`/students/activity/total`);
});

/** --- PROGRAMS --- */

const fetchPrograms = createAsyncThunk('student/fetchPrograms', async () => {
  return api.get(`/students/programs`);
});

const fetchProgram = createAsyncThunk('student/fetchProgram', async (id) => {
  return api.get(`/students/programs/${id}`);
});

/** --- MATERIAL STATUS --- */
const updateMaterialStatus = createAsyncThunk('student/updateMaterialStatus', async (data) => {
  return api.put(`/students/materials/${data.id}/status`, data.status);
});

/** --- GRADE  --- */
const fetchGrade = createAsyncThunk('student/fetchGrade', async (assignmentId) => {
  return api.get(`/students/grades/${assignmentId}`);
});

/** --- SUBMIT ASSIGNMENT  --- */

const submitAssignment = createAsyncThunk('student/submitAssignment', (data) => {
  return api.post(`/students/groups/${data.groupId}/grades/${data.assignmentId}`, data.body);
});

/** --- getAllGradesForProgram --- */

const fetchAllGradesForProgram = createAsyncThunk('student/fetchAllGradesForProgram', async (programId) => {
  return api.get(`/students/grades/programs/${programId}`);
});

/** --- getCalendarData--- */
const fetchCalendar = createAsyncThunk('student/fetchCalendar', async (query) => {
  return api.get(`/students/calendar${query}`);
});

/** --- getCalendarNearestData--- */
const fetchCalendarNearest = createAsyncThunk('student/fetchCalendarNearest', async () => {
  return api.get(`/students/calendar/nearest`);
});

/** --- PROGRAMS --- */

const fetchInvoices = createAsyncThunk('student/fetchInvoices', async () => {
  return api.get(`/students/invoices`);
});

export default {
  fetchActivity,
  updateActivity,
  fetchActivityTotal,
  fetchPrograms,
  fetchProgram,
  updateMaterialStatus,
  fetchGrade,
  submitAssignment,
  fetchAllGradesForProgram,
  fetchCalendar,
  fetchCalendarNearest,
  fetchInvoices,
};
